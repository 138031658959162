import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import Filters from '../../components/Filters/Filters';
import Text from '../../components/Inputs/Text/Text';
import SelectOption from '../../components/Inputs/Select/Select';

import Endereco from '../Endereco/EnderecoFilter';
import DependenciaAdministrativa from '../DependenciaAdministrativa/DependenciaAdministrativaFilter';

import { optionsAvaliacao } from '../../variables/Enums/Secretaria';

import { removePagingAndSorting } from '../../helper/PaginationHelper';

export class PedidoFilter extends Component {
  constructor(props) {
    super(props);
    props.parentRef(this);
    this.state = {
      filters: {
        name: '',
        status: '',
      },
      count: 0,
    }
  }  

  // INPUTS
  handleChange = e => {
    this.setState({
      filters: {...this.state.filters,  [e.target.name]: e.target.value }
    });
  };

  // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES TIPO FORM
  setEndereco = form => this.Endereco = form;
  setDependenciaAdministrativa = form => this.DependenciaAdministrativa = form;

  // APLICAR PERSISTENCIA
  handleFilterPersistence = (filters) => {
    filters = removePagingAndSorting(filters);

    this.setState({
      filters: {
        name: filters.find(f =>  f.field === 'nmSecretariaEducacaoTemporaria') ? filters.find(f =>  f.field === 'nmSecretariaEducacaoTemporaria').value : "",
        status: filters.find(f =>  f.field === 'nmStatusAvaliacao') ? filters.find(f =>  f.field === 'nmStatusAvaliacao').value : "",
      },
      count: filters.reduce((total, f) => f.value !== '' ? total + 1 : total, 0),
    })

    this.DependenciaAdministrativa.setState({
      filters: {
        adminDep: filters.find(f =>  f.field === 'nmDependenciaAdministrativa') ? filters.find(f =>  f.field === 'nmDependenciaAdministrativa').value : "",
      }
    })

    this.Endereco.setState({
      filters: {
        UF: filters.find(f =>  f.field === 'cdUf') ? filters.find(f =>  f.field === 'cdUf').value : "",
        city: filters.find(f =>  f.field === 'cdMunicipioRegiao') ? filters.find(f =>  f.field === 'cdMunicipioRegiao').value : "",
      }
    })
  }

  // FILTROS
  handleFilterApply = () => {
    const f = [];
    const { filters } = this.state;
    const filtersEndereco = this.Endereco.state.filters;
    const filtersDepAdmin = this.DependenciaAdministrativa.state.filters;

    if (filters.name) f.push({ field: 'nmSecretariaEducacaoTemporaria', value: filters.name })
    if (filters.status) f.push({ field: 'nmStatusAvaliacao', value: filters.status })

    if (filtersDepAdmin.adminDep) f.push({ field: 'nmDependenciaAdministrativa', value: filtersDepAdmin.adminDep })

    if (filtersEndereco.UF) f.push({ field: 'cdUf', value: filtersEndereco.UF })
    if (filtersEndereco.city) f.push({ field: 'cdMunicipioRegiao', value: filtersEndereco.city })

    this.props.handleFilterChange(f);
  }

  // LIMPAR FILTROS
  handleFilterClear = () => {
    this.setState({
      filters: {
        name: '',
        status: '',
      },
      count: 0,
    })

    this.DependenciaAdministrativa.setState({
      filters: {
        adminDep: '',
      }
    })

    this.Endereco.setState({
      filters: {
        city: '',
        UF: '',
      }
    })

    this.props.handleFilterChange([], false);
  }

  // CONTAGEM DE FILTROS ATIVOS
  handleCountFilters = () => this.state.count;

  render () {
    return (
      <Filters
          handleFilter={this.handleFilterApply}
          handleFilterClear={this.handleFilterClear}
          countFilters={this.handleCountFilters}
      >
        <Grid item container spacing={3}>
            <Grid item sm={12} lg={4}>
                <Text
                    label='Nome da Secretaria'
                    value={this.state.filters.name}
                    name='name'
                    onChange={(e) => this.handleChange(e)}
                />
            </Grid>
            <Grid item sm={12} lg={2}>
                <SelectOption
                    label="Status"
                    name='status'
                    value={this.state.filters.status}
                    onChange={e => this.handleChange(e)}
                    options={optionsAvaliacao}
                />
            </Grid>
            <DependenciaAdministrativa 
                parentRef={this.setDependenciaAdministrativa} 
                withFederal={false}
            />          
          </Grid>
          <Grid item container spacing={3}>
            <Endereco 
                parentRef={this.setEndereco}
            />
        </Grid>
      </Filters>
    )
  }
}

export default PedidoFilter;