import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import ResponsavelService from '../../services/Responsavel';
import { errors } from '../../services/API';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Text from '../../components/Inputs/Text/Text';
import Toast from "../../components/Toast/Toast";
import Loading from '../../components/Loading/Loading';
import CheckBox from '../../components/Inputs/CheckBox/CheckBox';

import Telefone from '../../forms/Telefone/Telefone';
import { patternPhones } from '../../variables/Enums/Telefone';

import ButtonsForm from '../../forms/Buttons/ButtonsForm';

import AuthorizedFunction from '../../security/AuthorizedFunction';
import { 
    __ADMINISTRADOR, 
    __CENTRAL 
} from '../../security/RoleConfiguration';

import { 
    isBlank, 
    isBlankHelperText,
    isCPFInvalid,
    isCPFHelperText,
    isNameCompoundInvalid,
    isNameCompoundHelperText,
    isMailInvalid,
    isMailHelperText,
    isEmailsDiffHelperText,
    isPasswordsDiffHelperText
} from '../../helper/ValidationHelper';
import { cpfMask } from '../../helper/MaskHelper';

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            responsible: {
                id: !isNaN(this.props.match.params.id) ? this.props.match.params.id : '',
                name: '',
                email: '',
                emailRepeat: '',
                emailOriginal: '',
                cpf: '',
                username: '',
                cargo: '',
                password: '',
                passwordRepeat: '',
                passwordTemporario: false,
            },
            errors: {
                name: false,
                email: false,
                emailRepeat: false,
                cpf: false,
                username: false,
                cargo: false,
                password: false,
                passwordRepeat: false,
            },
            helpers: {
                name: null,
                email: null,
                emailRepeat: null,
                cpf: null,
                username: null,
                cargo: null,
                password: null,
                passwordRepeat: null,
            },
            loadingButtonSave: false,
            loading: this.props.match.params.id ? true : false,
            history: { 
                path: null, 
                state: null
            }
        };
    }

    // INPUTS
    handleChange = e => {
        this.setState({
            responsible: {
                ...this.state.responsible, 
                [e.target.name]: e.target.name === 'cpf' ? cpfMask(e.target.value) : e.target.name === 'passwordTemporario' ? e.target.checked : e.target.value,
            },
            errors: {...this.state.errors, [e.target.name]: false },
            helpers: {...this.state.helpers, [e.target.name]: null },
        });
    };
    
    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
    setToast = t => this.Toast = t;
    setTelefone = form => this.Telefone = form;

    // VALIDAÇÕES
    isValid = () => {
        if ( (isBlank(this.state.responsible.name) || isNameCompoundInvalid(this.state.responsible.name)) || 
                isBlank(this.state.responsible.cpf) || isCPFInvalid(this.state.responsible.cpf) || isBlank(this.state.responsible.cargo) || 
                isBlank(this.state.responsible.email) || isMailInvalid(this.state.responsible.email) || 
                ( this.state.responsible.email !== this.state.responsible.emailOriginal && (isBlank(this.state.responsible.emailRepeat) || isMailInvalid(this.state.responsible.emailRepeat)) ) ) {
            this.setState({ 
                errors: {
                    name: isBlank(this.state.responsible.name) || isNameCompoundInvalid(this.state.responsible.name) ? true : false,
                    email: isBlank(this.state.responsible.email) || isMailInvalid(this.state.responsible.email) ? true : false,
                    emailRepeat: this.state.responsible.email !== this.state.responsible.emailOriginal ? 
                                    isBlank(this.state.responsible.emailRepeat) || isMailInvalid(this.state.responsible.emailRepeat) ? true : false : false,
                    cpf: isBlank(this.state.responsible.cpf) || isCPFInvalid(this.state.responsible.cpf) ? true : false,
                    username: this.state.responsible.email !== this.state.responsible.emailOriginal ? 
                                isBlank(this.state.responsible.emailRepeat) || isMailInvalid(this.state.responsible.emailRepeat) ? true : 
                                    isBlank(this.state.responsible.email) || isMailInvalid(this.state.responsible.email) ? true : false : false,
                    cargo: isBlank(this.state.responsible.cargo) ? true : false,
                },
                helpers: {
                    name: isBlank(this.state.responsible.name) ? isBlankHelperText() : isNameCompoundInvalid(this.state.responsible.name) ? isNameCompoundHelperText() : null,
                    email: isBlank(this.state.responsible.email) ? isBlankHelperText() : isMailInvalid(this.state.responsible.email) ? isMailHelperText() : null,
                    emailRepeat: this.state.responsible.email !== this.state.responsible.emailOriginal ? 
                                    isBlank(this.state.responsible.emailRepeat) ? isBlankHelperText() : isMailInvalid(this.state.responsible.emailRepeat) ? isMailHelperText() : null : null,
                    cpf: isBlank(this.state.responsible.cpf) ? isBlankHelperText() : isCPFInvalid(this.state.responsible.cpf) ? isCPFHelperText() : null,
                    username: this.state.responsible.email !== this.state.responsible.emailOriginal ? 
                                isBlank(this.state.responsible.emailRepeat) || isMailInvalid(this.state.responsible.emailRepeat) ? isMailHelperText() : 
                                    isBlank(this.state.responsible.email) || isMailInvalid(this.state.responsible.email) ? isMailHelperText() : null : null,
                    cargo: isBlank(this.state.responsible.cargo) ? isBlankHelperText() : null,
                },
            });
            return false;
        } else if (this.state.responsible.email !== this.state.responsible.emailOriginal && this.state.responsible.email !== this.state.responsible.emailRepeat) {
            this.setState({ 
                errors: {
                    email: true,
                    emailRepeat: true,
                    username: true,
                },
                helpers: {
                    emailRepeat: isEmailsDiffHelperText(),
                    username: isEmailsDiffHelperText(),
                },
            });
            return false;

        } else if (this.state.responsible.password !== this.state.responsible.passwordRepeat) {
            this.setState({ 
                errors: {
                    password: true,
                    passwordRepeat: true,
                },
                helpers: {
                     passwordRepeat: isPasswordsDiffHelperText(),
                 },
            });
            return false;
        }
        return true;
    }

    // SALVAR
    handleSubmit = () => {
        this.setState({ loadingButtonSave: true });

        const formIsValid = this.isValid();
        const formTelefoneIsValid = this.Telefone.isValidInFormToSave();

        if ( !formIsValid || !formTelefoneIsValid )  {
            this.setState({ loadingButtonSave: false });
            return
        }

        const responsible = {
            nome: this.state.responsible.name,
            email: this.state.responsible.email,
            nmCpf: this.state.responsible.cpf,
            username: this.state.responsible.email,
            nmCargo: this.state.responsible.cargo,
            password: this.state.responsible.password ?? null,
            passwordTemporario: this.state.responsible.passwordTemporario,

            telefones: [...this.Telefone.state.phones],
        }

        ResponsavelService.edit(this.state.responsible.id, responsible)
            .then(res => {
                this.Toast.setState({
                    message: {
                        message: "Responsável pela Inscrição Atualizado com Sucesso.",
                        type: "success",
                        open: true
                    }
                });

                this.setState({ loadingButtonSave: false });

                setTimeout(() => this.handleBack(), 1000);
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
                this.setState({ loadingButtonSave: false });
            })
    }

    filterPersistence = (parseLocation) => {
        if (parseLocation.state && parseLocation.state.history) {
            this.setState({ 
                history: { 
                    path: parseLocation.state.history.path, 
                    state: parseLocation.state.history.state 
                } 
            });
        }
    }

    componentDidMount() {
        this.filterPersistence(this.props.location);

        if (this.state.responsible.id) {
            ResponsavelService.find(this.state.responsible.id)
                .then(res => {
                    this.setState({
                        responsible: {
                            ...this.state.responsible,
                            name: res.data.nome,
                            email: res.data.email,
                            emailRepeat: '',
                            emailOriginal: res.data.email,
                            cpf: res.data.nmCpf,
                            cargo: res.data.nmCargo,
                            username: res.data.username,
                            password: '',
                        },
                        loading: false,
                    });

                    this.Telefone.setState({
                        phones: [...patternPhones(res.data.telefones)],
                    })
                })
                .catch(err => {
                    this.Toast.setState({
                        message: {
                            message: "Não Foi Possível Buscar o Responsável pela Inscrição para Edição.",
                            type: 'error',
                            open: true
                        }
                    })

                    this.setState({ loading: false })
                })
        }
    }

    handleBack = () => {
        this.props.history.push({
            pathname: `${this.state.history.path ?? this.state.history.goBack}`,
            state: { 
                history: {
                    state: this.state.history.state
                }
            }
        });    
    }

    render () {
        const page = this.state.responsible.id ? 'Editar Responsável pela Inscrição' : 'Adicionar Responsável pela Inscrição';
        const links = [
            {
                path: null,
                name: 'Secretarias'
            }
        ];

        return (
            <>
                <Toast parentRef={this.setToast} />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Breadcrumbs links={links} active={page} />
                    </Grid>
                </Grid>

                <Title>{page}</Title>

                { this.state.loading ? 
                    <Loading />
                :
                    <>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item sm={12} lg={4}>
                                <Text
                                    required
                                    name="name"
                                    label="Nome do Responsável"
                                    value={this.state.responsible.name}
                                    error={this.state.errors.name}
                                    onChange={this.handleChange}
                                    helperText={this.state.helpers.name}
                                />
                            </Grid>
                            <Grid item sm={12} lg={3}>
                                <Text
                                    required
                                    name="email"
                                    label="Email do Responsável"
                                    value={this.state.responsible.email}
                                    error={this.state.errors.email}
                                    onChange={this.handleChange}
                                    helperText={this.state.helpers.email}
                                />
                            </Grid>
                            { this.state.responsible.email !== this.state.responsible.emailOriginal &&
                                <Grid item sm={12} lg={3}>
                                    <Text
                                        required
                                        name="emailRepeat"
                                        label="Repetir Novo Email do Responsável"
                                        value={this.state.responsible.emailRepeat}
                                        error={this.state.errors.emailRepeat}
                                        onChange={this.handleChange}
                                        helperText={this.state.helpers.emailRepeat}
                                    />
                                </Grid>
                            }
                        </Grid>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item sm={12} lg={2}>
                                <Text
                                    required
                                    maxLength='14'
                                    name="cpf"
                                    label="CPF do Responsável"
                                    value={this.state.responsible.cpf}
                                    error={this.state.errors.cpf}
                                    onChange={this.handleChange}
                                    helperText={this.state.helpers.cpf}
                                />
                            </Grid>
                            <Grid item sm={12} lg={3}>
                                <Text
                                    required
                                    name="cargo"
                                    label="Cargo (Função)"
                                    value={this.state.responsible.cargo}
                                    error={this.state.errors.cargo}
                                    onChange={this.handleChange}
                                    helperText={this.state.helpers.cargo}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item sm={12} lg={3}>
                                <Text
                                    name="username"
                                    label="Usuário"
                                    value={this.state.responsible.email}
                                    disabled={true}
                                    error={this.state.errors.username}
                                    helperText={this.state.helpers.username}
                                />
                            </Grid>
                            <Grid item sm={12} lg={2}>
                                <Text
                                    type="password"
                                    name="password"
                                    label="Nova Senha"
                                    value={this.state.responsible.password}
                                    onChange={this.handleChange}
                                    error={this.state.errors.password}
                                    helperText={this.state.helpers.password}
                                />
                            </Grid>
                            <Grid item sm={12} lg={2}>
                                <Text
                                    type="password"
                                    name="passwordRepeat"
                                    label="Repetir Nova Senha"
                                    value={this.state.responsible.passwordRepeat}
                                    onChange={this.handleChange}
                                    error={this.state.errors.passwordRepeat}
                                    helperText={this.state.helpers.passwordRepeat}
                                />
                            </Grid>
                            { (AuthorizedFunction([__ADMINISTRADOR]) || AuthorizedFunction([__CENTRAL])) &&
                                <Grid item sm={12} lg={4}>
                                    <CheckBox
                                        name="passwordTemporario"
                                        label="Usuário deverá trocar de senha no próximo login"
                                        checked={this.state.responsible.passwordTemporario}
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                            }
                        </Grid>
                        <Telefone parentRef={this.setTelefone} />
                        <ButtonsForm 
                            onClick={this.handleSubmit} 
                            onBack={this.state.history.path ? () => this.handleBack() : null} 
                            idFocus={`saveButton`} 
                            loading={this.state.loadingButtonSave} 
                        />
                    </>
                }
            </>
        )
    }
}

export default withRouter(Form);