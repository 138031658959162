import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import { optionsPhone } from '../../variables/Enums/Telefone';

import Text from '../../components/Inputs/Text/Text';
import SelectOption from '../../components/Inputs/Select/Select';
import Toast from '../../components/Toast/Toast';

import { ButtonSave, ButtonClear } from '../Buttons/ButtonsForm';

import {
    isBlank,
    isNotBlank,
    isBlankHelperText,
    isDDDInvalid, 
    isDDDHelperText,
    isPhoneInvalid,
    isPhoneHelperText
} from '../../helper/ValidationHelper';
import { phoneNumberMask, phoneDDDMask } from '../../helper/MaskHelper';

const TablePhones = (props) => {
    const  { phones, onDelete } = props;
    
    if (phones.length > 0) {
        return (
            <Grid item sm={12} lg={6}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>DDD</TableCell>
                            <TableCell>Número</TableCell>
                            <TableCell>Ramal</TableCell>
                            <TableCell>Tipo</TableCell>
                            <TableCell>Remover</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { phones.map((phone, key) => {
                            return (
                                <TableRow key={key} hover>
                                    <TableCell>{phone[0]}</TableCell>
                                    <TableCell>{phone[1]}</TableCell>
                                    <TableCell>{phone[2]}</TableCell>
                                    <TableCell>{phone[3]}</TableCell>
                                    <TableCell>
                                        <Tooltip title="Remover Telefone">
                                            <IconButton 
                                                color="secondary"
                                                aria-label="Remover Telefone" 
                                                onClick={() => onDelete(key)}
                                            >
                                                <DeleteOutlinedIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </Grid>
        )
    }

    return null;
}

export class Telefone extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this)
        this.state = {
            phones: [],
            phone: {
                ddd: '',
                number: '',
                ramal: '',
                type: '',
            },
            errors: {
                ddd: false,
                number: false,
                ramal: false,
                type: false,
            },
            helpers: {
                ddd: null,
                number: null,
                ramal: null,
                type: null,
            },
        };
        this.isValid = this.isValid.bind(this);
    }

    // INPUTS
    handleChange = e => {
        this.setState({
            phone: {...this.state.phone, 
                [e.target.name]: 
                    e.target.name === 'number' ? phoneNumberMask(e.target.value) : e.target.name === 'ddd' ? phoneDDDMask(e.target.value) : e.target.value 
            },
            errors: {...this.state.errors, [e.target.name]: false },
            helpers: {...this.state.helpers, [e.target.name]: null }
        })
    }

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
    setToast = t => this.Toast = t;

    // VALIDAÇÃO ADD
    isValid = e => {
        if ( isBlank(this.state.phone.ddd) || isDDDInvalid(this.state.phone.ddd) || isBlank(this.state.phone.number) || isPhoneInvalid(this.state.phone.number) || isBlank(this.state.phone.type) ) {
            this.setStateValidations();
            return false;
        }
        return true;
    }

    // VALIDAÇÃO SAVE
    isValidInFormToSave = e => {
        if ( ( isNotBlank(this.state.phone.ddd) || isNotBlank(this.state.phone.number) || isNotBlank(this.state.phone.ramal) || isNotBlank(this.state.phone.type) ) && 
                (isBlank(this.state.phone.ddd) || isDDDInvalid(this.state.phone.ddd) || isBlank(this.state.phone.number) || isPhoneInvalid(this.state.phone.number) || isBlank(this.state.phone.type))) {
            this.setStateValidations();
            return false;
        } else {
            if ( isBlank(this.state.phone.ddd) && isBlank(this.state.phone.number) && isBlank(this.state.phone.ramal) && isBlank(this.state.phone.type) ) {
                return true;
            } else {
                this.addPhoneInArray();
                return true;
            }
        }
    }

    setStateValidations = () => {
        this.setState({
            errors: {
                ddd: isBlank(this.state.phone.ddd) || isDDDInvalid(this.state.phone.ddd) ? true : false,
                number: isBlank(this.state.phone.number) || isPhoneInvalid(this.state.phone.number) ? true : false,
                type: isBlank(this.state.phone.type) ? true : false,
            },
            helpers: {
                ddd: isBlank(this.state.phone.ddd) ? isBlankHelperText() : isDDDInvalid(this.state.phone.ddd) ? isDDDHelperText() : null,
                number: isBlank(this.state.phone.number) ? isBlankHelperText() : isPhoneInvalid(this.state.phone.number) ? isPhoneHelperText() : null,
                type: isBlank(this.state.phone.type) ? isBlankHelperText() : null,
            },
        })
    }

    // ADICIONAR TELEFONE NA TABELA
    handleAddPhone = e => {
        if (!this.isValid(e)) return
        this.addPhoneInArray();
    }

    // ADICIONAR TELEFONE NO STATE
    addPhoneInArray = () => {
        const newPhone = [
            this.state.phone.ddd,
            this.state.phone.number,
            this.state.phone.ramal,
            this.state.phone.type
        ]

        this.setState({
            phones: [...this.state.phones, newPhone],
            phone: {
                ddd: '',
                number: '',
                ramal: '',
                type: '',
            }
        })
    }

    // LIMPAR TELEFONE
    handleClearPhone = () => {
        this.setState({
            phone: {
                ddd: '',
                number: '',
                ramal: '',
                type: '',
            },
            errors: {
                ddd: false,
                number: false,
                type: false,
            },
            helpers: {
                ddd: null,
                number: null,
                type: null,
            },
        })
    }

    // REMOVER TELEFONE DA TABELA
    handleDelPhone = index => {
        this.setState({ phones: [...this.state.phones.filter((phone, key) => key !== index)] });
    }

    render() {
        return (
            <>
                <Toast parentRef={this.setToast} />
                <Grid container spacing={3} direction="row" alignItems='center' justify='flex-start'>
                    <Grid item sm={12} lg={1}>
                        <Typography variant="body2" noWrap>
                            Telefones: 
                        </Typography>
                    </Grid>
                    <Grid item sm={3} lg={1}>
                        <Text
                            required
                            name="ddd"
                            label="DDD"
                            value={this.state.phone.ddd}
                            error={this.state.errors.ddd}
                            onChange={this.handleChange}
                            inputProps={{
                                maxLength: 2,
                            }}
                            helperText={this.state.helpers.ddd}
                        />
                    </Grid>
                    <Grid item sm={6} lg={2}>
                        <Text
                            required
                            name="number"
                            label="Número"
                            value={this.state.phone.number}
                            error={this.state.errors.number}
                            onChange={this.handleChange}
                            inputProps={{
                                maxLength: 10,
                            }}
                            helperText={this.state.helpers.number}
                        />
                    </Grid>
                    <Grid item sm={3} lg={1}>
                        <Text
                            name="ramal"
                            label="Ramal"
                            value={this.state.phone.ramal}
                            error={this.state.errors.ramal}
                            onChange={this.handleChange}
                            helperText={this.state.helpers.ramal}
                        />
                    </Grid>
                    <Grid item sm={10} lg={2}>
                        <SelectOption
                            required
                            name="type"
                            label="Tipo"
                            value={this.state.phone.type}
                            error={this.state.errors.type}
                            onChange={(e) => this.handleChange(e)}
                            options={optionsPhone}
                            hiddenblank="true"
                            helperText={this.state.helpers.type}
                        />
                    </Grid>
                    <Grid item sm={4} lg={4}>
                        <ButtonSave
                            name="Adicionar"
                            startIcon={<AddIcon />}
                            onClick={this.handleAddPhone}
                        />
                        <ButtonClear
                            onClick={this.handleClearPhone}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3} direction="row" alignItems='center' justify='flex-start'>
                    <TablePhones
                        phones={this.state.phones}
                        onDelete={this.handleDelPhone}
                    />                    
                </Grid>
            </>
        )
    }
}

export default Telefone;