import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import SubjectIcon from '@material-ui/icons/Subject';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import SecretariaService from '../../services/Secretaria';
import PedidoService from '../../services/Pedido';
import EscolaService from '../../services/Escola';
import { errors } from '../../services/API';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Table from '../../components/Table/Table';
import Toast from '../../components/Toast/Toast';
import { ButtonSecondary } from '../../components/Button/Button';

import AuthorizedFunction from '../../security/AuthorizedFunction';
import {
  __ESCOLA_EMAIL_VISUALIZAR,
  __SECRETARIA_EMAIL_VISUALIZAR,
  __SECRETARIA_PEDIDO_EMAIL_VISUALIZAR,
} from '../../security/RoleConfiguration';

export class Email extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mails: [],
      entity: {
          type: this.props.location.state.entity.type ?? '',
          id: !isNaN(this.props.match.params.id) ? this.props.match.params.id : '',
      },
      loading: true,
      history: { 
        path: null, 
        state: null
      }
    }
  }

  // FECHAR TOAST
  setToast = t => this.Toast = t;

  // LABELS
  labelDetalhes = id => !this.disabledDetalhes(id) ? "Ver Detalhes" : "Você não tem permissão para fazer isso";

  // DISABLES
  disabledDetalhes = id => this.handleViewMailSend(id);

  // VER DETALHES DO EMAIL
  handleViewClick = id => { 
    const link = this.state.entity.type === 'SECRETARIA' ? 'secretaria' : this.state.entity.type === 'PEDIDO' ? 'secretaria/pedido' : 'escola'; 
    this.props.history.push({
      pathname: `/admin/${link}/${this.state.entity.id}/emails/${id}`,
      state: {
        entity: {
          id: this.state.entity.id,
          type: this.state.entity.type
        }
      }
    })
  }

  // FORMATANDO STATUS
  formatStatusSend = status => {
    return status === 'SUCESSO' ? 'Sucesso' : status === 'FALHA' ? 'Falha' : status === 'AGUARDANDO' ? 'Aguardando' : 'Descartado';
  }

  // HABILITAR BOTÃO DE VER CORPO DO EMAIL ENVIADO
  handleViewMailSend = () => {
    return this.state.entity.type === 'SECRETARIA' ? !AuthorizedFunction([__SECRETARIA_EMAIL_VISUALIZAR]) :
            this.state.entity.type === 'PEDIDO' ? !AuthorizedFunction([__SECRETARIA_PEDIDO_EMAIL_VISUALIZAR]) :
              this.state.entity.type === 'ESCOLA' ? !AuthorizedFunction([__ESCOLA_EMAIL_VISUALIZAR]) : false;
  }

  loadData = () => {
    this.filterPersistence(this.props.location);

    if (this.state.entity.id) {
      const id = this.state.entity.id;

      let request = null;

      if (this.state.entity.type === 'SECRETARIA') {
        request = SecretariaService; 
      } else if (this.state.entity.type === 'PEDIDO') {
        request = PedidoService;
      } else if (this.state.entity.type === 'ESCOLA') {
        request = EscolaService;
      }
      
      request.listMails(id)
        .then(res => {
          if (res.data) {
            this.setState({ mails: [...res.data ], loading: false, });
          } else {
            this.setState({ mails: [], loading: false, });
          }
        })
        .catch(error => {
          const e = errors(error);
          this.Toast.setState({
              message: {
                  message: e.message,
                  type: e.type,
                  open: true
              }
          })           

          this.setState({ loading: false });
        })
    }
  }

  filterPersistence = (parseLocation) => {
    if (parseLocation.state && parseLocation.state.history) {
      this.setState({ 
        history: { 
          path: parseLocation.state.history.path, 
          state: parseLocation.state.history.state 
        } 
      });
    }
  }
    
  componentDidMount () { this.loadData(); }

  handleBack = () => {
    this.props.history.push({
      pathname: `${this.state.history.path}`,
      state: { 
        history: {
          state: this.state.history.state
        }
      }
    });    
  }

  render () {
    const pageName = 'Emails Enviados';
    const links = [
      {
        path: `/admin/${this.state.entity.type === 'SECRETARIA' ? 'secretaria' : this.state.entity.type === 'PEDIDO' ? 'secretaria/pedido' : 'escola'}`,
        name: this.state.entity.type === 'SECRETARIA' ? 'Secretarias' : this.state.entity.type === 'PEDIDO' ? 'Pedido de Secretarias' : 'Escolas',
      },
    ];

    const columns = [
      { label: '#', name: 'id', func: null, key: true },
      { label: 'Assunto', name: 'titulo', func: null, key: false },
      { label: 'Status', name: 'statusEnvio', func: this.formatStatusSend, key: false },
      { label: 'Tipo', name: 'processo', func: null, key: false },
    ];
    
    const actions = [
      { 
        name: this.labelDetalhes, 
        func: this.handleViewClick, 
        icon: <SubjectIcon />, 
        disabled: this.disabledDetalhes, 
      },
    ];

    return (
      <>
        <Toast parentRef={this.setToast} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Breadcrumbs links={links} active={pageName} />
          </Grid>
        </Grid>
        <Title>{pageName}</Title>

        <Grid container spacing={3}>
          <Grid item xs={12}>          
            <Table              
              columns={columns}
              tableData={this.state.mails}
              actions={actions}
              page={null}
              rowsPerPage={null}
              order={null}
              orderBy={null}
              loading={this.state.loading}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>          
            <ButtonSecondary
              startIcon={<ArrowBackIcon />}
              style={{ margin: 4 }}
              onClick={() => this.handleBack()}
              name={"Voltar"}
            />
          </Grid>
        </Grid>
      </>
    )
  }
}

export default withRouter(Email);