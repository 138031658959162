import React, { Component } from 'react';

import Dashboard from "../../components/Dashboard/Dashboard";
import Main from "../../components/Container/Container";

import { __SECRETARIA_DASHBOARD, __ESCOLA_DASHBOARD } from '../../security/RoleConfiguration';
import AuthorizedFunction from '../../security/AuthorizedFunction';

class LayoutPrivate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: AuthorizedFunction([__SECRETARIA_DASHBOARD, __ESCOLA_DASHBOARD]) ? true : false,
    }
  }

  handleDrawerOpen = () => this.setState({ open: true });
  handleDrawerClose = () => this.setState({ open: false }); 

  render () {
    return (
      <>
        <Dashboard 
          open={this.state.open}
          handleDrawerOpen={this.handleDrawerOpen} 
          handleDrawerClose={this.handleDrawerClose}
          handleLogout={this.props.handleLogout}
          darkMode={this.props.darkMode}
          setDarkMode={this.props.setDarkMode}
        />
        <Main open={this.state.open} >
          {this.props.children}
        </Main>
      </>
    )
  }
}

export default LayoutPrivate;