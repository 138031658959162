import Dashboard from '../../views/Dashboard/Dashboard';
import Contexto from '../../views/Contexto/Contexto';
import Secretaria from '../../views/Secretaria/Secretaria';
import Pedido from '../../views/Pedido/Pedido';
import SecretariaPorUF from '../../views/SecretariaPorUF/SecretariaPorUF';
import Escola from '../../views/Escola/Escola';
import Convite from '../../views/Convite/Convite';
import Arquivo from '../../views/Arquivo/Arquivo';
import RelatorioEscolas from '../../views/Relatorio/Escolas';
import RelatorioEscolasPorUf from '../../views/Relatorio/EscolasPorUf';
import RelatorioSecretarias from '../../views/Relatorio/Secretarias';
import RelatorioSecretariasEstaduais from '../../views/Relatorio/SecretariasEstaduais';
import RelatorioDependenciasAdministrativas from '../../views/Relatorio/DependenciasAdministrativas';
import RelatorioMunicipios from '../../views/Relatorio/Municipios.jsx';
import RelatorioUfs from '../../views/Relatorio/Ufs';

import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import UnarchiveOutlinedIcon from '@material-ui/icons/UnarchiveOutlined';
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';

import {
    __DASHBOARD_VISUALIZAR,
    __CONTEXTO_VISUALIZAR_VALOR,
    __SECRETARIA_VISUALIZAR,
    __SECRETARIA_PEDIDO_VISUALIZAR,
    __SECRETARIA_UF_VISUALIZAR,
    __ESCOLA_VISUALIZAR,
    __CONVITE_VISUALIZAR,
    __ARQUIVO_VISUALIZAR,
    __RELATORIO_ESCOLAS_VISUALIZAR,
    __RELATORIO_ESCOLAS_POR_UF_VISUALIZAR, 
    __RELATORIO_SECRETARIAS_VISUALIZAR,
    __RELATORIO_SECRETARIAS_ESTADUAIS_VISUALIZAR, 
    __RELATORIO_DEPENDENCIAS_ADMINISTRATIVAS_VISUALIZAR, 
    __RELATORIO_MUNICIPIOS_VISUALIZAR, 
    __RELATORIO_UFS_VISUALIZAR 
} from '../../security/RoleConfiguration';

export const path = '/admin';

export const mainPage = '/inicio';

export const dashboardMenuList = [
    {
        category: '',
        items: [
            {
                path: `${path}/inicio`,
                name: 'Início',
                icon: HomeOutlinedIcon,
                component: Dashboard,
                collapsed: false,
                submenus: [],
                roles: [ __DASHBOARD_VISUALIZAR ],
            },
        ]
    },
    {
        category: '',
        items: [
            {
                path: `${path}/contexto`,
                name: 'Contexto',
                icon: SettingsOutlinedIcon,
                component: Contexto,
                collapsed: false,
                submenus: [],
                roles: [ __CONTEXTO_VISUALIZAR_VALOR ],
            },
        ],
    },
    {
        category: '',
        items: [
            {
                path: `${path}/`,
                name: 'Secretarias',
                icon: HomeWorkOutlinedIcon,
                component: null,
                collapsed: true,
                submenus: [
                    {
                        path: `${path}/secretaria`,
                        name: 'Gerenciar',
                        icon: HomeWorkOutlinedIcon,
                        component: Secretaria,
                        roles: [ __SECRETARIA_VISUALIZAR ],
                    },
                    {
                        path: `${path}/secretaria/pedido`,
                        name: 'Pedidos',
                        icon: UnarchiveOutlinedIcon,
                        component: Pedido,
                        roles: [ __SECRETARIA_PEDIDO_VISUALIZAR ],
                    },
                    {
                        path: `${path}/secretariaPorUF`,
                        name: 'Secretarias Por UF',
                        icon: MapOutlinedIcon,
                        component: SecretariaPorUF,
                        roles: [ __SECRETARIA_UF_VISUALIZAR ],
                    },
                ],
                roles: [ __SECRETARIA_VISUALIZAR, __SECRETARIA_PEDIDO_VISUALIZAR, __SECRETARIA_UF_VISUALIZAR ],
            },
            {
                path: `${path}/escola`,
                name: 'Escolas',
                icon: SchoolOutlinedIcon,
                component: Escola,
                collapsed: false,
                submenus: [],
                roles: [ __ESCOLA_VISUALIZAR ],
            },
        ],
    },
    {
        category: '',
        items: [
            {
                path: `${path}/convite`,
                name: 'Convites',
                icon: MailOutlinedIcon,
                component: Convite,
                collapsed: false,
                submenus: [],
                roles: [ __CONVITE_VISUALIZAR ],
            },
            {
                path: `${path}/arquivo`,
                name: 'Arquivos',
                icon: CloudDownloadOutlinedIcon,
                component: Arquivo,
                collapsed: false,
                submenus: [],
                roles: [ __ARQUIVO_VISUALIZAR ],
            },
            {
                path: `${path}/`,
                name: 'Relatórios Gerais',
                icon: DescriptionOutlinedIcon,
                component: null,
                collapsed: true,
                submenus: [
                    {
                        path: `${path}/relatorio/secretariasEstaduais`,
                        name: 'Secretarias Estaduais',
                        icon: HomeWorkOutlinedIcon,
                        component: RelatorioSecretariasEstaduais,
                        roles: [ __RELATORIO_SECRETARIAS_ESTADUAIS_VISUALIZAR ],
                    },
                    {
                        path: `${path}/relatorio/secretarias`,
                        name: 'Todas as Secretarias',
                        icon: HomeWorkOutlinedIcon,
                        component: RelatorioSecretarias,
                        roles: [ __RELATORIO_SECRETARIAS_VISUALIZAR ],
                    },
                    {
                        path: `${path}/relatorio/escolasPorUf`,
                        name: 'Escolas Federais / UF',
                        icon: MapOutlinedIcon,
                        component: RelatorioEscolasPorUf, 
                        roles: [ __RELATORIO_ESCOLAS_POR_UF_VISUALIZAR ], 
                    },
                    {
                        path: `${path}/relatorio/escolas`,
                        name: 'Todas as Escolas',
                        icon: SchoolOutlinedIcon,
                        component: RelatorioEscolas,
                        roles: [ __RELATORIO_ESCOLAS_VISUALIZAR ],
                    },                   
                    {
                        path: `${path}/relatorio/dependeciasAdministrativas`,
                        name: 'Dep. Administrativa',
                        icon: LocalOfferOutlinedIcon,
                        component: RelatorioDependenciasAdministrativas,
                        roles: [ __RELATORIO_DEPENDENCIAS_ADMINISTRATIVAS_VISUALIZAR ],
                    },
                    {
                        path: `${path}/relatorio/municipios`,
                        name: 'Municípios',
                        icon: PlaceOutlinedIcon,
                        component: RelatorioMunicipios,
                        roles: [ __RELATORIO_MUNICIPIOS_VISUALIZAR ],
                    },
                    {
                        path: `${path}/relatorio/ufs`,
                        name: "UF's",
                        icon: MapOutlinedIcon,
                        component: RelatorioUfs,
                        roles: [ __RELATORIO_UFS_VISUALIZAR ],
                    },
                    
                ],
                roles: [ 
                    __RELATORIO_SECRETARIAS_VISUALIZAR,
                    __RELATORIO_SECRETARIAS_ESTADUAIS_VISUALIZAR, 
                    __RELATORIO_ESCOLAS_VISUALIZAR, 
                    __RELATORIO_ESCOLAS_POR_UF_VISUALIZAR, 
                    __RELATORIO_DEPENDENCIAS_ADMINISTRATIVAS_VISUALIZAR, 
                    __RELATORIO_MUNICIPIOS_VISUALIZAR, 
                    __RELATORIO_UFS_VISUALIZAR 
                ],
            },
        ],
    },
];

export default dashboardMenuList;