export const patternUF = options => {
    let array = [];

    options.forEach( option => {
        array.push({ value: option.cdUf, label: option.nmUf })        
    });

    return array;
}

export default patternUF;