import React from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles( theme => ({
    paper: {
        padding: theme.spacing(2),
    }
}));

export const Boxer = props  => {
    const classes = useStyles();
    return (
        <Paper elevation={1} className={classes.paper}>
            <Typography variant="h6">{props.category}</Typography>
            {props.children}
        </Paper>
    )
}

export const BoxerReports = props => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
              <Boxer {...props} />
            </Grid>
        </Grid>
    ) 
}

export default Boxer;