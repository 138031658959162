import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import brLocale from "date-fns/locale/pt-BR";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(2, 0),
        width: '100%',
    },
}));

export const DateTimePickerSimple = (props) => {
    const classes = useStyles();

    return (
        <FormControl className={classes.formControl}>
            <TextField
                type="datetime-local"
                InputLabelProps={{
                    shrink: true,
                }}
                {...props}
            />
        </FormControl>
    );
}

export const DateTimePicker = (props) => {
    const classes = useStyles();
    
    const localeMap = {
        br: brLocale,   
    }

    return (
        <FormControl className={classes.formControl}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap["br"]}>
                <KeyboardDateTimePicker
                    ampm={false}
                    disablePast={props.value ? false : true}
                    showTodayButton
                    cancelLabel="Cancelar"
                    okLabel="Salvar"
                    todayLabel="Agora"
                    format="dd/MM/yyyy HH:mm:ss"
                    KeyboardButtonProps={{ 'aria-label': 'Selecione a Data e Hora', 'color': 'primary' }}
                    {...props}
                    onChange={(e) => props.onChange(e)}
                    allowKeyboardControl
                    invalidDateMessage={props.value ? "Formato de Data Inválido" : ""}
                    invalidLabel={props.value ? "Data Inválida" : ""}
                    mask="__/__/____ __:__:__"
                />
            </MuiPickersUtilsProvider>
        </FormControl>
    );
}

export default DateTimePicker;