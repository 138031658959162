import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import FileDownload from 'js-file-download';

import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import RelatorioService from '../../services/Relatorio';
import { errors } from '../../services/API';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Toast from '../../components/Toast/Toast';
import { ButtonSecondary } from '../../components/Button/Button';
import Loading from '../../components/Loading/Loading';

import DadosSecretarias from '../../forms/Relatorio/DadosSecretarias';
import { ButtonExport } from '../../forms/Buttons/ButtonsExport';

import AuthorizedElement from '../../security/AuthorizedElement';
import { __RELATORIO_SECRETARIAS_EXPORTAR } from '../../security/RoleConfiguration';

import { getFilenameResponse } from '../../helper/FileHelper';

export class Secretarias extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    }
  }  

  // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
  setToast = t => this.Toast = t;
  setDadosSecretarias = d => this.DadosSecretarias = d;

  // EXPORTAÇÃO
  handleExportChange = () => {
    const f = [];
    f.push({ field: 'exportarPlanilha', value: true });

    this.Toast.setState({
      message: {
        message: "A Planilha está sendo gerada para Exportação.",
        type: 'success',
        open: true
      }
    })

    RelatorioService.exportReportAllSecretaries(f)
      .then(res => {
        FileDownload(res.data, getFilenameResponse(res));
      })
  }

  componentDidMount() {
    RelatorioService.reportAllSecretaries()
        .then(res => {
            this.setState({ loading: false });
    
            this.DadosSecretarias.setState({ 
                secretaries: [...res.data]
            });
        })
        .catch(error => {
            const e = errors(error);
            this.setState({ loading: false });
            
            this.Toast.setState({
                message: {
                    message: e.message,
                    type: e.type,
                    open: true
                }
            })
        })
}

  render () {
    const pageName = 'Todas as Secretarias';
    const links = [
        { 
            path: null, 
            name: 'Relatórios' 
        }
    ];

    return (
      <>
        <Toast parentRef={this.setToast} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Breadcrumbs links={links} active={pageName} />
          </Grid>
        </Grid>
        <Title>{pageName}</Title>
        
        <AuthorizedElement roles={[__RELATORIO_SECRETARIAS_EXPORTAR]}>
            <Grid item container spacing={3}>
              <ButtonExport 
                title="Exportar Planilha"
                onClick={this.handleExportChange}
              />
            </Grid>
        </AuthorizedElement>

        { this.state.loading ? 
          <Loading />
        :
          <>
            <DadosSecretarias parentRef={this.setDadosSecretarias} />
          </>
        }

        <Grid container spacing={3} alignItems='flex-end' style={{ marginTop: 16 }}>  
          <Grid item sm={12} lg={12}>
            <ButtonSecondary
              startIcon={<ArrowBackIcon />}
              style={{ margin: 4 }}
              onClick={this.props.history.goBack}
              name={"Voltar"}
            />
          </Grid>   
        </Grid>
      </>
    )
  }
}

export default withRouter(Secretarias);