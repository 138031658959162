import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import SecretariaPorUFService from '../../services/SecretariaPorUF';
import { errors } from '../../services/API';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Table from '../../components/Table/Table';
import Toast from '../../components/Toast/Toast';

import AuthorizedFunction from '../../security/AuthorizedFunction';
import { __SECRETARIA_VISUALIZAR } from '../../security/RoleConfiguration';

export class SecretariaPorUF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ufs: [],
      loading: true,
    }
  } 

  // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
  setToast = t => this.Toast = t;

  // LABELS
  labelSecretarias = id => !this.disabledSecretarias(id) ? "Listar Secretarias" : "Você não tem permissão para fazer isso";

  // DISABLES
  disabledSecretarias = id => !AuthorizedFunction([__SECRETARIA_VISUALIZAR]);

  // REDIRECIONAR PARA SECRETARIAS
  handleRedirectClick = (uf) => {
    let link = `/admin/secretaria`;
    link += `?adminDep=ESTADUAL`;
    link += `&uf=${uf}`;
    
    this.props.history.push(link);
  }

  loadData = () => {
    this.setState({ loading: true, });

    SecretariaPorUFService.list()
      .then(res => {
        if (res.data) {
          this.setState({ ufs: [...res.data], loading: false });
        } else {
          this.setState({ ufs: [], loading: false });
        }
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })
        
        this.setState({ loading: false });
      })
  }

  componentDidMount() { this.loadData(); }

  render () {
    const pageName = 'Secretarias Por UF';
    const links = [
      {
        path: null,
        name: 'Secretarias',
      }
    ];
  
    const columns = [
      { label: 'UF', name: 'nmUf', func: null, key: true },
      { label: 'Quantidade de Secretarias Estaduais', name: 'qtdSecretariaPorUf', func: null, key: false },
    ];
    
    const actions = [
      { 
        name: this.labelSecretarias, 
        func: this.handleRedirectClick, 
        icon: <OpenInNewIcon />, 
        disabled: this.disabledSecretarias,
      },
    ];

    return (
      <>
        <Toast parentRef={this.setToast} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Breadcrumbs links={links} active={pageName} />
          </Grid>
        </Grid>
        <Title>{pageName}</Title>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Table              
              columns={columns}
              tableData={this.state.ufs}
              actions={actions}
              page={null}
              rowsPerPage={null}
              order={null}
              orderBy={null}
              loading={this.state.loading}
            />
          </Grid>
        </Grid>
      </>
    )
  }
}

export default withRouter(SecretariaPorUF);