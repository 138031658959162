import API from './API';

const path = `variaveis`;

const ContextService = {
    // FILTROS
    filters: async filters => await API.filters(path, filters),

    // LISTAR TODOS
    list: async () => await API.list(path),

    // LISTAR UM
    find: async id => await API.find(path, id),

    // EDITAR
    edit: async (id, context) => await API.edit(path, id, context),

    // ADICIONAR
    add: async context => await API.add(path, context),

    // REMOVER
    remove: async id => await API.remove(path, id),
}

export default ContextService;