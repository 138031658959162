import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import AllInboxIcon from '@material-ui/icons/AllInbox';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Toast from '../../components/Toast/Toast';
import Table from '../../components/Table/Table';
import { 
  verifyPagingAndSorting,
  PAGE,
  SIZE,
  ASC,
  TOTAL_ELEMENTS,
  TOTAL_PAGES,
} from '../../components/Table/Utils';

import PedidoFilter from '../../forms/Pedido/PedidoFilter';

import PedidoService from '../../services/Pedido';
import { errors } from '../../services/API';

import { patternStatusPedido, patternUsuarioTratamento } from '../../variables/Enums/Pedido';
import { patternAdminDep } from '../../variables/Enums/DependenciaAdministrativa';

import AuthorizedFunction from '../../security/AuthorizedFunction';
import {
  __SECRETARIA_PEDIDO_EDITAR,
  __SECRETARIA_VISUALIZAR,
  __SECRETARIA_PEDIDO_EMAIL,
} from '../../security/RoleConfiguration';

import { verifyPersistence, removePagingAndSorting } from '../../helper/PaginationHelper';

export class Pedido extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: PAGE,
      size: SIZE,
      totalElements: TOTAL_ELEMENTS,
      totalPages: TOTAL_PAGES,
      order: ASC,
      orderBy: 'cdSecretariaEducacaoPedido',
      defaultOrderBy: 'cdSecretariaEducacaoPedido',
      filtered: false,
      filters: [],
      requests: [],
      loading: true,
    } 

    this.state = verifyPersistence(this.state, this.props.location);
  }

  // DECLARAÇÕES DE REFERENCIA DOS COMPONENTES
  setToast = t => this.Toast = t;
  setPedidoFilter = f => this.PedidoFilter = f;

  // LABELS
  labelEditar = id => !this.disabledEditar(id) ? "Editar" : "Você não tem permissão para fazer isso";
  labelEmailsEnviados = id => !this.disabledEmailsEnviados(id) ? "Ver Emails Enviados" : "Você não tem permissão para fazer isso";
  labelVisualizar = id => !this.disabledVisualizar(id) ? "Listar Secretarias Similares" : "Você não tem permissão para fazer isso";

  // DISABLES
  disabledEditar = id => !AuthorizedFunction([__SECRETARIA_PEDIDO_EDITAR]);
  disabledEmailsEnviados = id => !AuthorizedFunction([__SECRETARIA_PEDIDO_EMAIL])
  disabledVisualizar = id => !AuthorizedFunction([__SECRETARIA_VISUALIZAR]) ;

  // EDIÇÃO
  handleEditClick = id => { 
    this.props.history.push({ 
      pathname: `/admin/secretaria/pedido/editar/${id}`,
      state: { 
        history: {
          path: `/admin/secretaria/pedido`,
          state: this.state,
        }
      }
    });
  }

  // LISTAR SECRETARIAS SIMILARES
  handleSearchSecretariesClick = id => {
    const request = this.state.requests.find(r => r.cdSecretariaEducacaoPedido === id);

    let filterSecretaria = `/admin/secretaria`;
    filterSecretaria += `?uf=${request.cdUf}`;
    filterSecretaria += `&city=${request.cdMunicipioRegiao}`;

    if (request.nmDependenciaAdministrativa !== "ESTADUAL")
      filterSecretaria += `&adminDep=${request.nmDependenciaAdministrativa}`;

    this.props.history.push(filterSecretaria);
  }

  // VER LISTAGEM DE EMAILS ENVIADOS
  handleViewSends = id => {
    this.props.history.push({
      pathname: `/admin/secretaria/pedido/${id}/emails`,
      state: {
        entity: {
          type: 'PEDIDO', 
        },
        history: {
          path: `/admin/secretaria/pedido`,
          state: this.state,
        }
      }
    });
  }

  loadData = () => {
    let filters = verifyPagingAndSorting(this.state);

    this.setState({ 
      filters: [...filters], 
      filtered: true, 
      loading: true 
    });

    PedidoService.filters(filters)
      .then(res => {
        if (res.data.content) {
          this.setState({ 
            requests: [...res.data.content],
            page: res.data.number,
            size: res.data.size,
            totalElements: res.data.totalElements, 
            totalPages: res.data.totalPages,
            loading: false,
          });
        } else {
          this.setState({ 
            requests: [],
            page: PAGE,
            size: SIZE,
            totalElements: TOTAL_ELEMENTS,
            totalPages: TOTAL_PAGES,
            loading: false,
          });
        }
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })

        this.setState({ loading: false });
      })
  }

  // FILTROS
  filterData = (filters, isActive = true) => this.setState({ filtered: isActive, filters: [...removePagingAndSorting(filters)] }, () => this.loadData());

  // PÁGINA
  handlePage = page => this.setState({ page: page }, () => this.filterData(this.state.filters));

  // LINHAS POR PÁGINA
  handleRowsPerPage = (size, page) => this.setState({ size: size, page: page }, () => this.filterData(this.state.filters));

  // ORDENAÇÃO
  handleSort = (orderBy, order) => this.setState({ order: order, orderBy: orderBy }, () => this.filterData(this.state.filters));

  // TOTAL DE PAGINAS
  handleTotalPages = () => this.state.totalPages;

  // TOTAL DE ELEMENTOS
  handleTotalElements = () => this.state.totalElements;

  // VERIFICAR PERSISTENCIA NO FORMULARIO DE FILTROS
  handleFilterPersistence = () => this.PedidoFilter ? this.PedidoFilter.state ? this.PedidoFilter.handleFilterPersistence(this.state.filters) : null : null;

  componentDidMount() { this.loadData(); }

  render () {
    const page = 'Pedido de Secretarias';
    const links = [
        {
            path: null,
            name: 'Secretarias',
        }
    ];

    const columns = [
      { label: '#', name: 'cdSecretariaEducacaoPedido', func: null, key: true },
      { label: 'Dep. Adm', name: 'nmDependenciaAdministrativa', func: patternAdminDep, key: false },
      { label: 'Nome da Secretaria', name: 'nmSecretariaEducacaoTemporaria', func: null, key: false },
      { label: 'Status', name: 'nmStatusAvaliacao', func: patternStatusPedido, key: false },
      { label: 'UF', name: 'nmUf', func: null, key: false, ordering: false },
      { label: 'Município', name: 'nmMunicipio', func: null, key: false, ordering: false },
      { label: 'Usuário que Tratou', name: 'nmUsuario', func: patternUsuarioTratamento, key: false, ordering: false },
    ];

    const actions = [
      { 
        name: this.labelEditar, 
        func: this.handleEditClick, 
        icon: <EditOutlinedIcon />, 
        disabled: this.disabledEditar,
      },
      { 
        name: this.labelEmailsEnviados, 
        func: this.handleViewSends, 
        icon: <AllInboxIcon />, 
        disabled: this.disabledEmailsEnviados,
      },
      { 
        name: this.labelVisualizar, 
        func: this.handleSearchSecretariesClick, 
        icon: <OpenInNewIcon />, 
        disabled: this.disabledVisualizar,
      },
    ];

    this.handleFilterPersistence();

    return (
      <>
        <Toast parentRef={this.setToast} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Breadcrumbs links={links} active={page} />
          </Grid>
        </Grid>
        <Title>{page}</Title>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <PedidoFilter
              parentRef={this.setPedidoFilter}
              handleFilterChange={this.filterData}
            />
            <Table              
              columns={columns}
              tableData={this.state.requests}
              actions={actions}
              page={this.state.page}
              handlePage={this.handlePage}
              handleTotalPages={this.handleTotalPages}
              handleTotalElements={this.handleTotalElements}
              rowsPerPage={this.state.size}
              handleRowsPerPage={this.handleRowsPerPage}
              order={this.state.order}
              orderBy={this.state.orderBy}
              handleSort={this.handleSort}
              loading={this.state.loading}
            />
          </Grid>
        </Grid>
      </>
    )
  }
}

export default withRouter(Pedido);