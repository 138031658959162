export const PAGE = 0;
export const SIZE = 10;
export const ASC = 'asc';
export const TOTAL_ELEMENTS = 10;
export const TOTAL_PAGES = 5;

export const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
export const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export const verifyPagingAndSorting = ({ filters, defaultOrderBy = null, filtered = false, page = PAGE, size = SIZE, orderBy = defaultOrderBy, order = ASC }) => {
    if (filtered) {
        filters.push({ field: 'page', value: page });
        filters.push({ field: 'size', value: size });
        filters.push({ field: 'sort', value: `${orderBy},${order}` });
    } else {
        filters.push({ field: 'page', value: PAGE });
        filters.push({ field: 'size', value: SIZE });

        if (defaultOrderBy)
            filters.push({ field: 'sort', value: `${defaultOrderBy},${ASC}` });
    }   

    return filters;
}