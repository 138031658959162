import Form from '../../views/Inscricao/Form';
import { __INSCRICAO_ADICIONAR } from '../../security/RoleConfiguration';

export const path = '/admin/inscricao';

export const listRoutes = [
    {
        path: path + '/cadastrar',
        component: Form,
        roles: [ __INSCRICAO_ADICIONAR ],
    },
];


export default listRoutes;