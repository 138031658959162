import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';

import { BoxerReports } from '../../components/Boxer/Boxer';

import { patternAdminDep } from '../../variables/Enums/DependenciaAdministrativa';

export class DadosSecretaria extends Component {
    constructor (props) {
        super(props)
        props.parentRef(this);
        this.state = {
            secretary: {
                nmDependenciaAdministrativaSecretaria: '',
                nmSecretaria: '',
                ufSecretaria: '',
            },
        }
    }

    render () {
        return (
            <BoxerReports category="Dados da Secretaria">
                <Typography variant="body2">
                    Dependência Administrativa: {patternAdminDep(this.state.secretary.nmDependenciaAdministrativaSecretaria)}
                </Typography>
                <Typography variant="body2">
                    UF: {this.state.secretary.ufSecretaria}
                </Typography>
                <Typography variant="body2">
                    Nome da Secretaria: {this.state.secretary.nmSecretaria}
                </Typography>
                <Typography variant="body2">
                    &nbsp;
                </Typography>
            </BoxerReports>
        )
    }
}

export default DadosSecretaria;