import React, { useState } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import Zoom from '@material-ui/core/Zoom';

import Text from '../../components/Inputs/Text/Text';
import Loading from '../../components/Loading/Loading';

import { useStyles } from './Style';

const DataTable = props => {
  const classes = useStyles();
  const { columns, tableData, actions, loading, page, rowsPerPage} = props;

  const [ order, setOrder ] = useState(props.order);
  const [ orderBy, setOrderBy ] = useState(props.orderBy);
  const [ selected, setSelected ] = useState([]);

  const handleRequestSort = (event, property) => {
    const ordering = orderBy === property && order === 'asc' ? 'desc' : 'asc';
    props.handleSort(property, ordering);
    setOrder(ordering);
    setOrderBy(property);
  };

  const data = columnsFilter(columns, tableData);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map(d => d.id);
      setSelected(newSelecteds);
      props.handleChecks(newSelecteds);
      return;
    }
    setSelected([]);
    props.handleChecks([]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    props.handleChecks(newSelected);
  };

  return (
    <>
      <Table className={classes.spacingTable} size='small'>
        <TableHead>
          <TableRow>
            {columns.map((c, i) => (
              c.item === 'checkbox' ?
                props.handleChecks ?
                  <TableCell key={i}>
                    <Checkbox
                      defaultChecked
                      color="primary"
                      indeterminate={selected.length > 0 && selected.length < data.length}
                      checked={data.length > 0 && selected.length === data.length}
                      onChange={event => handleSelectAllClick(event)}
                      inputProps={{ 'aria-label': 'Selecionar Todos' }}
                    />
                  </TableCell>
                : null
              : 
              <TableCell 
                key={i}
                className={classes.bolder} 
                padding='normal'
                sortDirection={orderBy === c.name ? order : false}
              >
                { order !== null && orderBy !== null && c.ordering !== false ?
                  <Sort 
                    column={c}
                    order={order}
                    orderBy={orderBy}
                    requestSort={handleRequestSort}
                  />
                : 
                  c.label
                }
              </TableCell>
            ))}
            {actions.map(action => <ActionCellHead action={action} key={action.name} /> )}
          </TableRow>
        </TableHead>
        <TableBody>
          { loading ?
            <TableRow>
              <TableCell align="center" colSpan={columns.length + actions.length} rowSpan={2}>
                <Loading />
              </TableCell>
            </TableRow>
          : 
            data.length > 0 ?
              data.map((d, i) => {
                const isItemSelected = isSelected(d.id);
                const labelId = `enhanced-table-checkbox-${i}`;

                return (
                  <TableRow key={d.id} hover>
                    {columns.map((c, i) => (
                      c.change && c.item === "textBox" ? 
                        <TableCell key={i} style={ c.width ? { width: `${c.width}%` } : null }>
                          <Text 
                            required
                            name={c.name}
                            label={d[c.label]}
                            value={d[c.name] ? d[c.name] : 0}
                            onChange={e => c.change(e, d.id)}
                            onBlur={c.blur ? e => c.blur(e, d.id) : null}
                          /> 
                        </TableCell>
                      : c.item === 'checkbox' ?
                        props.handleChecks ?
                          <TableCell key={i} style={ c.width ? { width: `${c.width}%` } : null }>
                            <Checkbox
                              defaultChecked
                              color="primary"
                              checked={isItemSelected}
                              onChange={event => handleClick(event, d.id)}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </TableCell>
                        : null 
                      :
                        <TableCell key={i} style={ c.width ? { width: `${c.width}%` } : null }>
                          { c.func ? c.func(d[c.name]) : d[c.name] }
                        </TableCell>
                    ))}
                    {actions.map((action, key) => <ActionCellBody action={action} id={d.id} key={key} /> )}
                  </TableRow>
                )
              })
            :
              <TableRow style={{ height: 53 }}>
                <TableCell colSpan={columns.length + actions.length} align="center">
                  Não Foram Encontrados Registros para Exibição
                </TableCell>
              </TableRow>
          }
        </TableBody>
      </Table>
      {props.rowsPerPage !== null && props.page !== null &&
        <Pagination 
          page={page}
          changePage={props.handlePage}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={props.handleRowsPerPage}
          handleTotalElements={props.handleTotalElements}
          handleTotalPages={props.handleTotalPages}
          handleSelected={setSelected}
        />
      }
    </>
  )
}

export const DataTableLineTotals = ({ lineData, columns }) => {
  const classes = useStyles();

  const Cells = ({ data, columns }) => {

    const Cell = () => columns.map((c,i) => {
      const d = data.find((d) => d.column === i);
      return d ? <TableCell style={ d.width ? { width: `${d.width}%` } : null }>{d.value}</TableCell> : <TableCell></TableCell>
    })

    return <Cell />
  }

  return (    
    <>
      <Table className={classes.spacingTable} size='small'>
        <TableBody>
          <TableRow>
            <Cells data={lineData} columns={columns}/>
          </TableRow>
        </TableBody>
      </Table>
    </>
  )
}

const Pagination = props => {
  const [ page, setPage ] = useState(props.page);
  const [ rowsPerPage, setRowsPerPage ] = useState(props.rowsPerPage);

  const handleChangePage = (event, newPage) => {
    props.changePage(newPage);
    setPage(newPage);
    props.handleSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    props.changeRowsPerPage(rowsPerPage === -1 ? handleTotalElements() : rowsPerPage, 0);
    setRowsPerPage(rowsPerPage);
    setPage(0);
    props.handleSelected([]);
  };

  const handleTotalPages = () => props.handleTotalPages();
  const handleTotalElements = () => props.handleTotalElements();

  return (
    <TablePagination
      labelDisplayedRows={({from, to, count}) => `Página ${page + 1} de ${handleTotalPages()} / Mostrando ${(to === -1) ? `1` : to} de ${handleTotalElements()} registros`}
      labelRowsPerPage="Linhas Por Página"
      nextIconButtonText="Próxima Página"
      backIconButtonText="Página Anterior"
      rowsPerPageOptions={[3, 5, 10, 25, 50, 100, { value: '-1', label: 'Todos' }]}
      component="div"
      count={handleTotalElements() ?? 0}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  )
}

const ActionCellBody = ({ action, id }) => {
  if (!action.func) {
    return null
  }

  return (
    <TableCell align="right">
      <Tooltip title={action.name(id)} TransitionComponent={Zoom}> 
        <span style={action.disabled(id) ? { cursor: 'not-allowed' } : {}}>
          <IconButton 
              size="small" 
              component="div" 
              color={action.color ?? "primary"}
              aria-label={action.name(id)} 
              onClick={() => action.func(id)} 
              disabled={action.disabled(id)} 
              style={action.disabled(id) ? { pointerEvents: "none" } : {}}
            >
            {action.icon}
          </IconButton>
        </span>
      </Tooltip>
    </TableCell>
  )
}

const ActionCellHead = ({ action }) => {
  if (!action) {
    return null
  }

  return <TableCell align="right"></TableCell>
}

const Sort = ({ order, orderBy, column, requestSort }) => {
  const classes = useStyles();
  const createSortHandler = (property) => (event) => {
    requestSort(event, property);
  };

  return (
    <TableSortLabel
      active={orderBy === column.name}
      direction={orderBy === column.name ? order : 'asc'}
      onClick={createSortHandler(column.name)}
    >
      {column.label}
      {orderBy === column.name && (
        <span className={classes.visuallyHidden}>
          {order === 'desc' ? 'Ordenação Descendente' : 'Ordenação Ascendente'}
        </span>
      )}
    </TableSortLabel>
  )
}

const columnsFilter = ( columns, data ) => {
  let array = [];

  if (data != null) {
    data.forEach(d => {
      const object = {};

      columns.forEach(c => {
        if(c.key) object['id'] = d[c.name];

        object[c.name] = d[c.name];
      })

      array.push(object);
    })
  }

  return array;
}

export default DataTable;