import React, { useState } from 'react';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import SearchIcon from '@material-ui/icons/Search';
import Badge from '@material-ui/core/Badge';

import useEnterKeyListener from "../../helper/FocusHelper";

import { useStyles } from './Style';

export const Filters = props => {
    useEnterKeyListener({ querySelectorToExecuteClick: `#saveButton` })

    const classes = useStyles();
    const { children, handleFilterClear, handleFilter } = props;
    const [ openFilter, setOpenFilter ] = useState(false);
  
    const handleFilterOpen = () => {
      setOpenFilter(!openFilter);
    };
  
    return (
        <Paper elevation={1} className={classes.paper}>
            <Grid container spacing={3} className={classes.wrapper} alignItems="center">
                <Grid item sm={12} lg={9}>
                    <Typography className={classes.title} variant="inherit">
                        <Tooltip title="Filtrar">
                            <Badge anchorOrigin={{ vertical: 'top', horizontal: 'left', }} overlap="circle" color="primary" badgeContent={props.countFilters ? props.countFilters() : 0}>
                                <IconButton color="primary" aria-label="Filtrar" onClick={handleFilterOpen}>
                                    <FilterListIcon />
                                </IconButton>
                            </Badge>
                        </Tooltip>
                        Filtros
                    </Typography>
                </Grid>
                <Grid item sm={12} lg={12}>
                    <Collapse in={openFilter} timeout="auto">
                        <Grid container spacing={3} >
                            {children}
                            <Grid item sm={12} lg={3}>
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    id="saveButton"  
                                    startIcon={<SearchIcon />}
                                    className={classes.button}
                                    onClick={() => {
                                        handleFilter()
                                        handleFilterOpen()
                                    }}
                                >
                                    Filtrar
                                </Button>
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="default"
                                    startIcon={<ClearOutlinedIcon />}
                                    className={classes.button}  
                                    onClick={handleFilterClear}
                                >
                                    Limpar
                                </Button>
                            </Grid>
                        </Grid>
                    </Collapse>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default Filters;