import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import ArquivoService from '../../services/Arquivo';
import { errors } from '../../services/API';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Text from '../../components/Inputs/Text/Text';
import SelectOption from '../../components/Inputs/Select/Select';
import Arquivo from '../../components/Inputs/File/File';
import Toast from "../../components/Toast/Toast";

import ButtonsForm from '../../forms/Buttons/ButtonsForm';

import { optionsFile } from '../../variables/Enums/Arquivo.jsx';

import { 
    isBlank, 
    isBlankHelperText 
} from '../../helper/ValidationHelper';

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            file: {
                name: '',
                type: '',
                description: '',
            },
            errors: {
                name: false,
                type: false,
                description: false,
            },
            helpers: {
                name: null,
                type: null,
                description: null,
            },
            loadingButtonSave: false
        };
    }

    // INPUTS
    handleChange = e => {
        this.setState({
            file: {...this.state.file, [e.target.name]: e.target.value },
            errors: {...this.state.errors, [e.target.name]: false },
            helpers: {...this.state.helpers, [e.target.name]: null }
        });
    };

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
    setToast = t => this.Toast = t;
    setArquivo = f => this.Arquivo = f;

    // VALIDAÇÕES
    isValid = () => {
        if ( isBlank(this.state.file.name) || isBlank(this.state.file.type) || isBlank(this.state.file.description) ) {
            this.setState({ 
                errors: {
                    name: isBlank(this.state.file.name) ? true : false,
                    type: isBlank(this.state.file.type) ? true : false,
                    description: isBlank(this.state.file.description) ? true : false,
                },
                helpers: {
                    name: isBlank(this.state.file.name) ? isBlankHelperText() : null,
                    type: isBlank(this.state.file.type) ? isBlankHelperText() : null,
                    description: isBlank(this.state.file.description) ? isBlankHelperText() : null,
                },
            });
            return false;
        }
        return true;
    }

    // SALVAR
    handleSubmit = () => {
        this.setState({ loadingButtonSave: true });

        const formIsValid = this.isValid();
        const formArquivoIsValid = this.Arquivo.isValid();

        if ( !formIsValid || !formArquivoIsValid ) {
            this.setState({ loadingButtonSave: false });
            return
        }        

        const config = { headers: { 'content-type': 'multipart/form-data' } }

        const file = new FormData();
        file.append('nomeArquivo', this.state.file.name)
        file.append('tipoArquivo', this.state.file.type)
        file.append('descricao', this.state.file.description)
        file.append('arquivo', this.Arquivo.state.file)

        ArquivoService.add(file, config)
            .then(res => {
                this.Toast.setState({
                    message: {
                        message: "Arquivo Criado com Sucesso.",
                        type: "success",
                        open: true
                    }
                });

                setTimeout(() => this.props.history.push(`/admin/arquivo`), 1000);
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
                this.setState({ loadingButtonSave: false });
            })
    }

    componentDidMount() {
        ArquivoService.list()
            .then(res => {
                this.setState({ 
                    files: [...this.state.files, ...res.data.content]
                });
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            })
    }

    render () {
        const page = 'Adicionar Arquivo';
        const links = [
            {
                path: '/admin/arquivo',
                name: 'Arquivos'
            },
        ];

        // VERIFICAR AS OPÇÕES QUE VÃO ESTAR DISPONÍVEIS NO TIPO DE ARQUIVO
        const optionsFileFiltered = optionsFile.filter(opt => this.state.files.every(f => f.tipo !== opt.label));

        return (
            <>
                <Toast parentRef={this.setToast} />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Breadcrumbs links={links} active={page} />
                    </Grid>
                </Grid>

                <Title>{page}</Title>

                <Grid container spacing={3} alignItems='center'>
                    <Grid item sm={12} lg={3}>
                        <Text
                            required
                            name="name"
                            label="Nome do Arquivo"
                            value={this.state.file.name}
                            error={this.state.errors.name}
                            onChange={this.handleChange}
                            helperText={this.state.helpers.name}
                        />
                    </Grid>
                    <Grid item sm={12} lg={3}>
                        <SelectOption 
                            required
                            name="type"
                            label="Tipo"
                            options={optionsFileFiltered}
                            value={this.state.file.type}
                            error={this.state.errors.type}
                            onChange={this.handleChange}
                            hiddenblank="true"
                            helperText={this.state.helpers.type}
                        />
                    </Grid>
                    <Arquivo parentRef={this.setArquivo} />
                    <Grid item sm={12} lg={12}>
                        <Text
                            required
                            label="Descrição"
                            name="description"
                            value={this.state.file.description}
                            error={this.state.errors.description}
                            onChange={this.handleChange}
                            helperText={this.state.helpers.description}
                            fullWidth
                        />
                    </Grid>
                    <ButtonsForm onClick={this.handleSubmit} idFocus={`saveButton`} loading={this.state.loadingButtonSave} /> 
                </Grid>
            </>
        )
    }
}

export default withRouter(Form);