import { format } from 'date-fns'

export const TZ_AMERICA_SP = "America/Sao_Paulo";

export const toDate = date => date ? format(new Date(date), 'dd/MM/yyyy') : null;

export const toDateTime = date => date ? format(new Date(date), "dd/MM/yyyy HH:mm:ss") : null;

export const toDateTimeForCompare = date => date ? new Date(date) : null;

export const formatInTimeZone = date => format(new Date(date), 'yyyy-MM-dd HH:mm:ssXX', { timeZone: TZ_AMERICA_SP });