import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';

import AuthorizedFunction from '../../security/AuthorizedFunction';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { makeStyles } from '@material-ui/core/styles';

import {
    __DASHBOARD_VISUALIZAR,
    __SECRETARIA_DASHBOARD,
    __ESCOLA_DASHBOARD,
} from '../../security/RoleConfiguration';

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export const CollapseItems = (props) => {
    const classes = useStyles();

    return (
        <Collapse in={props.open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                {props.items.map((item, key) => 
                    AuthorizedFunction(item.roles) &&
                        <ListItem button 
                            className={classes.nested}
                            to={item.path}
                            component={Link} 
                            key={key}
                        >
                            <ListItemIcon>{<item.icon />}</ListItemIcon>
                            <ListItemText primary={item.name} />
                        </ListItem>
                )}
            </List>
        </Collapse>
    )
}

export const ListDrawer = (props) => {
    const [ open, setOpen ] = useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    const hideMenuInicio = (i) => (i.roles.some(r => r !== __DASHBOARD_VISUALIZAR) && AuthorizedFunction([__SECRETARIA_DASHBOARD, __ESCOLA_DASHBOARD])) || !AuthorizedFunction([__SECRETARIA_DASHBOARD]);

    return (
        <>
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    { props.category }
                </ListSubheader>}
            >
                { props.items.map((item, key) => 
                    hideMenuInicio(item) ?
                        AuthorizedFunction(item.roles) ?
                            item.collapsed ?
                                <Fragment key={key}>
                                    <ListItem button
                                        key={key}
                                        onClick={handleClick}
                                    >
                                        <ListItemIcon>{<item.icon />}</ListItemIcon>
                                        <ListItemText primary={item.name} />
                                        {open ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                    <CollapseItems 
                                        open={open}
                                        items={item.submenus} 
                                    />
                                </Fragment>
                            :
                                <ListItem button
                                    to={item.path}      
                                    component={Link} 
                                    key={key}
                                >
                                    <ListItemIcon>{<item.icon />}</ListItemIcon>
                                    <ListItemText primary={item.name} />
                                </ListItem>
                        : null 
                    : null
                )}
            </List>

            { (props.items.some((item) => hideMenuInicio(item) && AuthorizedFunction(item.roles))) && <Divider /> }
        </>
    );
}

export default ListDrawer;