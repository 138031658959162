import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import Filters from '../../components/Filters/Filters';
import Text from '../../components/Inputs/Text/Text';

import { mecCodeMask } from '../../helper/MaskHelper';
import { removePagingAndSorting } from '../../helper/PaginationHelper';

export class InscricaoFilter extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            filters: {
                nameSchool: '',
                inepSchool: '',
            },
            count: 0,
        }
    }  

    // INPUTS
    handleChange = e => {
        this.setState({
            filters: {
                ...this.state.filters,  
                [e.target.name]: e.target.name === 'inepSchool' ? mecCodeMask(e.target.value) : e.target.value 
            }
        });
    };

    // APLICAR PERSISTENCIA
    handleFilterPersistence = (filters) => {
        filters = removePagingAndSorting(filters);

        this.setState({
            filters: {
                nameSchool: filters.find(f =>  f.field === 'nmEscola') ? filters.find(f =>  f.field === 'nmEscola').value : "",
                inepSchool: filters.find(f =>  f.field === 'cdMecEscola') ? filters.find(f =>  f.field === 'cdMecEscola').value : "",
            },
            count: filters.reduce((total, f) => f.value !== '' ? total + 1 : total, 0),
        })
    }

    // FILTROS
    handleFilterApply = () => {
        const f = [];
        const { filters } = this.state;

        if (filters.nameSchool) f.push({ field: 'nmEscola', value: filters.nameSchool })
        if (filters.inepSchool) f.push({ field: 'cdMecEscola', value: filters.inepSchool })

        this.props.handleFilterChange(f);     
    }

    // LIMPAR FILTROS
    handleFilterClear = () => {
        this.setState({
            filters: {
                nameSchool: '',
                inepSchool: '',
            },
            count: 0,
        })

        this.props.handleFilterChange([], false);
    }

    // CONTAGEM DE FILTROS ATIVOS
    handleCountFilters = () => this.state.count;

    render () {
        return (
            <Filters
                handleFilter={this.handleFilterApply}
                handleFilterClear={this.handleFilterClear}
                countFilters={this.handleCountFilters}
            >
                <Grid item container spacing={3}>
                    <Grid item sm={12} lg={2}>
                        <Text
                            maxLength='8'
                            label="INEP"
                            name='inepSchool'
                            value={this.state.filters.inepSchool}
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Grid>
                    <Grid item sm={12} lg={4}>
                        <Text
                            label='Nome da Escola'
                            value={this.state.filters.nameSchool}
                            name='nameSchool'
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Grid>
                </Grid>
            </Filters>
        )
    }
}

export default InscricaoFilter;