export const locationResponsavelParse = (location, f = []) => {
    const parseState = location ? location.state : null;

    if (parseState && parseState.responsavel) {
        f = f.filter(f => f.field !== 'id');
        f.push({ field: 'id', value: parseState.responsavel });
    }

    return f;
}

export const verifyLocationResponsavelParse = location => {
    const parseState = location ? location.state : null;

    if (parseState && parseState.responsavel) return true;
    
    return false;
}