import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import SendIcon from '@material-ui/icons/Send';
import Chip from '@material-ui/core/Chip';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Toast from '../../components/Toast/Toast';
import Text from '../../components/Inputs/Text/Text';
import Loading from '../../components/Loading/Loading';
import { ButtonSecondary } from '../../components/Button/Button';

import MensagemService from '../../services/Mensagem';
import SecretariaService from '../../services/Secretaria';
import SchoolService from '../../services/Escola';
import ConviteService from '../../services/Convite';
import { errors } from '../../services/API';

import { ButtonSave } from '../../forms/Buttons/ButtonsForm';

import { patternSendToSecretary } from '../../variables/Enums/Convite';

import AuthorizedFunction from '../../security/AuthorizedFunction';
import {
  __CONVITE_EMAIL_EDITAR,
  __CONVITE_EMAIL_REMOVER,
  __CONVITE_ENVIAR
} from '../../security/RoleConfiguration';

import { 
  isBlank, 
  isBlankHelperText 
} from '../../helper/ValidationHelper';

export class Convite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invite: {
        to: [],
        id: null,
        message: '',
        subject: '',
        type: '',
      },
      errors: {
        message: false,
        subject: false,
      },
      helpers: {
        message: null,
        subject: null,
      },
      showAll: false,
      maxShow: 12,
      target: 1, // 1 - SECRETARIAS // 2 - ESCOLAS
      loading: true,
      loadingButtonSave: false,
      loadingButtonSender: false,
      history: { 
        path: null,
        state: null,
      },
    }
  }

  // DECLARAÇÕES DE REFERENCIA DOS COMPONENTES
  setToast = t => this.Toast = t;

  // INPUTS
  handleChange = e => {
    this.setState({
      invite: {...this.state.invite, [e.target.name]: e.target.value },
      errors: {...this.state.errors, [e.target.name]: false },
      helpers: {...this.state.helpers, [e.target.name]: null }
    });
  };

  handleChangeEditor = editor => {
    this.setState({
      ...this.state,
      invite: {
        ...this.state.invite,
        message: editor.getData()
      }
    })
  }

  // REMOVER EMAIL DA LISTAGEM PARA ENVIO
  handleDelete = mail => {
    this.setState({
      invite: {
        ...this.state.invite,
        to: this.state.invite.to.filter((m) => m.key !== mail.key),
      }
    });
  };

  // MOSTRAR / OCULTAR EXCESSO DE EMAILS AMOSTRA
  handleShowAll = () => {
    this.setState({
      ...this.state,
      showAll: !this.state.showAll,
    })
  }

  // VALIDAÇÕES DE SALVAR MENSAGEM
  isValidMessage = () => {
    if ( !this.state.invite.subject || !this.state.invite.message ) {
      this.setState({ 
          errors: {
              subject: isBlank(this.state.invite.subject) ? true : false,
              message: isBlank(this.state.invite.message) ? true : false,
          },
          helpers: {
            subject: isBlank(this.state.invite.subject) ? isBlankHelperText() : null,
            message: isBlank(this.state.invite.message) ? isBlankHelperText() : null,
          }
      });
      return false;
    }
    return true;
  }

  // SALVAR MENSAGEM PARA SECRETARIA / ESCOLAS
  handleSaveMessageInvite = () => {
    const formMessageIsValid = this.isValidMessage();

    if ( !formMessageIsValid )  return

    this.setState({ loadingButtonSave: true })

    const message = {
      nmAssunto: this.state.invite.subject, 
      nmMensagem: this.state.invite.message,
      nmTipo: this.state.invite.type,
    }

    MensagemService.edit(this.state.invite.id, message)
      .then(res => {
        this.Toast.setState({
          message: {
            message: "O Conteúdo do Email foi Salvo com Sucesso",
            type: "success",
            open: true
          }
        });

        this.setState({ loadingButtonSave: false })
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })

        this.setState({ loadingButtonSave: false })
      });
  }

  // ENVIAR CONVITE PARA TODAS AS SECRETARIAS / ESCOLAS
  handleSendInvitation = () => {
    const formMessageIsValid = this.isValidMessage();

    if ( !formMessageIsValid )  return

    this.setState({ loadingButtonSender: true })

    const parseState = this.props.location.state;
    const isSchool = parseState && parseState.target && parseState.target === 2;
    const pathInvite = !isSchool ? `/secretaria` : `/escola`;

    const invites = [
      ...this.state.invite.to.map(t => {
        return {
          para: t.mail,
          tipoEmail: "CONVITE",
          assunto: this.state.invite.subject,
          mensagem: this.state.invite.message.replace("{link}", `${process.env.REACT_APP_PUBLIC_URL}/responsavel`.concat(`${pathInvite}?hash=${t.hash}`)),
          secretariaEducacao: !isSchool ? t.key : null,
          mecEscola: isSchool ? t.key : null,
        }
      })
    ]

    ConviteService.senderBatch(invites)
      .then(res => {
        let request;

        if (this.state.target === 1) {
          request = SecretariaService;
        } else {
          request = SchoolService;
        }

        request.inviteConfirm([...this.state.invite.to.map(t => t.key)])
          .then( res => {
            this.Toast.setState({
              message: {
                message: "O Envio de Convite foi Configurado e em Instantes será Realizado Disparo",
                type: "success",
                open: true
              }
            });

            this.setState({ loadingButtonSender: false })
          })
          .catch(e => {
            this.Toast.setState({
              message: {
                message: "Ocorreu um Problema ao Configurar o Envio de Convites. Tente Novamente Mais Tarde",
                type: "error",
                open: true
              }
            })

            this.setState({ loadingButtonSender: false })
          })
      })
      .catch(e => {
        this.Toast.setState({
          message: {
            message: "Ocorreu um Problema ao Configurar o Envio de Convites. Tente Novamente Mais Tarde",
            type: "error",
            open: true
          }
        })

        this.setState({ loadingButtonSender: false })
      });
  }

  filterPersistence = (parseLocation) => {
    if (parseLocation.state && parseLocation.state.history) {
        this.setState({ 
            history: { 
                path: parseLocation.state.history.path, 
                state: parseLocation.state.history.state 
            } 
        });
    }
  }

  async componentDidMount() {
    this.filterPersistence(this.props.location);

    const parseState = this.props.location.state;
    let filtersMessage = [];
    let to = [];

    if (parseState) {
      filtersMessage.push({ field: 'nmTipo', value: parseState.target === 2 ? "CONVITE_ESCOLA" : "CONVITE_SECRETARIA" })
      filtersMessage.push({ field: 'size', value: 1 })

      if (parseState.target) this.setState({ target: parseState.target })
      if (parseState.invite.to) to = parseState.invite.to;
      
    } else {
      filtersMessage.push({ field: 'nmTipo', value: "CONVITE_SECRETARIA" })
      filtersMessage.push({ field: 'size', value: 1 })

      const filtersSecretaria = [];
      filtersSecretaria.push({ field: 'isConviteEnviado', value: false })
      filtersSecretaria.push({ field: 'pageable', value: false });

      this.setState({ target: 1 })

      await SecretariaService.filters(filtersSecretaria)
        .then( res => {
          to = [...patternSendToSecretary(res.data.content)]
        })
        .catch( error => {
          const e = errors(error);
          this.Toast.setState({
            message: {
              message: e.message,
              type: e.type,
              open: true
            }
          })
        })
    }

    await MensagemService.filters(filtersMessage)
      .then(res => {
        this.setState({ 
          invite: {
            to: to,
            id: res.data.content[0].cdMensagem,
            subject: res.data.content[0].nmAssunto,
            message: res.data.content[0].nmMensagem,
            type: res.data.content[0].nmTipo,
          },
          loading: false,
        });
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })

        this.state({ loading: false });
      });
  }

  handleBack = () => {
    this.props.history.push({
      pathname: `${this.state.history.path}`,
      state: { 
        history: {
          state: this.state.history.state
        }
      }
    });    
  }

  render () {
    const page = `Convites Para ${this.state.target === 1 ? `Secretarias` : `Escolas` }`;
    const bread = this.state.target === 1 ? `Secretarias` : `Escolas`;
    const links = [
      {
        path: null,
        name: 'Convites',
      }
    ];

    return (
      <>
        <Toast parentRef={this.setToast} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Breadcrumbs links={links} active={bread} />
          </Grid>
        </Grid>
        <Title>{page}</Title>
        
        { this.state.loading ?
          <Loading />
        :
          <Grid container spacing={3} alignItems='center'>
            <Grid item sm={12} lg={12}>
              <Paper elevation={1} style={{ padding: 24 }} >
                <Typography variant="h6">Para:</Typography>

                {this.state.invite.to.map((t, i) => 
                  (this.state.showAll || (!this.state.showAll && i < this.state.maxShow)) &&
                    <div key={i} style={{ margin: "4px", display: "inline-block" }}>
                      <Chip
                        label={t.mail}
                        onDelete={AuthorizedFunction([__CONVITE_EMAIL_REMOVER]) ? () => this.handleDelete(t) : null}
                      />
                    </div>
                )}

                {this.state.invite.to.length > this.state.maxShow &&
                  <div style={{ margin: "4px", display: "inline-block" }}>
                    <Chip
                      color={this.state.showAll ? `secondary` : `primary`}
                      icon={<MoreHorizIcon />}
                      onClick={() => this.handleShowAll()}
                      label={this.state.showAll ? `Ocultar ${this.state.invite.to.length - this.state.maxShow}` : `+ ${this.state.invite.to.length - this.state.maxShow}`  }
                    />
                  </div>}
              </Paper>
            </Grid>

            {AuthorizedFunction([__CONVITE_EMAIL_EDITAR]) ?
              <>
                <Grid item sm={12} lg={12}>
                  <Alert severity="warning">
                    <AlertTitle>Atenção</AlertTitle>
                    <Typography variant="body2" style={{ paddingTop: 12 }}>
                      Para adicionar o <b>Link de Inscrição de Responsável das {this.state.target === 1 ? `Secretarias` : `Escolas`}</b> escreva <span style={{ color: "red" }}><b>{`{link}`}</b></span> no local desejado do texto no campo <b>Mensagem</b>.
                      Caso esta sinalização <b>NÃO</b> esteja no corpo da mensagem, os emails serão enviados sem link.
                    </Typography>
                  </Alert>
                </Grid>
                <Grid item sm={12} lg={10}>
                  <Text
                    required
                    name="subject"
                    label="Assunto"
                    value={this.state.invite.subject}
                    error={this.state.errors.subject}
                    onChange={this.handleChange}
                    helperText={this.state.helpers.subject}
                  />
                  
                  <Typography variant="body2" display="inline" style={{ paddingTop: 8, fontSize: 12, color: 'rgba(0, 0, 0, 0.54)' }}>
                    Mensagem <sup>*</sup> &nbsp;
                  </Typography>

                  { this.state.errors.message ? 
                    <Typography variant="body2" display="inline" style={{ fontSize: 12, color: '#f34539' }}>{this.state.helpers.message}</Typography> 
                  : null }

                  <CKEditor
                    editor={ ClassicEditor }
                    data={this.state.invite.message}
                    config={{
                      toolbar: [],
                      language: 'pt-br',
                    }}
                    onChange={ (event, editor) => this.handleChangeEditor(editor)}
                  />                  
                </Grid>
                <Grid item sm={12} lg={2}>
                  <ButtonSave
                    name="Salvar Alterações de Mensagem"
                    onClick={this.handleSaveMessageInvite}
                    loading={this.state.loadingButtonSave}
                  />
                </Grid>
              </>
            :
              <>
                <Grid item sm={12} lg={12}>
                  <Paper elevation={1} style={{ padding: 24 }} >
                    <Typography variant="h6">Assunto:</Typography>
                    <Typography variant="body2">{this.state.invite.subject}</Typography>
                  </Paper>
                </Grid>
                <Grid item sm={12} lg={12}>
                  <Paper elevation={1} style={{ padding: 24 }} >
                    <Typography variant="h6">Mensagem:</Typography>              
                    <Typography variant="body2" dangerouslySetInnerHTML={{ __html: this.state.invite.message }}></Typography>
                  </Paper>
                </Grid>
              </>
            }

            <Grid item sm={12} lg={12} style={{ marginTop: "24px" }}>
              <Typography variant="body2">
                Ao clicar no botão abaixo, será disparado o Envio de Convite a <b>TODAS AS {this.state.target === 1 ? <> SECRETARIAS </> : <> ESCOLAS </>} </b> listadas acima.
              </Typography>

              <Grid item sm={12} lg={4} style={{ marginTop: "16px" }}>
                <ButtonSave
                  startIcon={<SendIcon />}
                  onClick={this.handleSendInvitation}
                  name="Enviar Convite de Inscrição"
                  saveDisabled={!AuthorizedFunction([__CONVITE_ENVIAR]) || this.state.invite.to.length === 0}
                  loading={this.state.loadingButtonSender}
                />
                { this.state.history.path &&
                  <ButtonSecondary
                    startIcon={<ArrowBackIcon />}
                    style={{ margin: 4 }}
                    onClick={() => this.handleBack()}
                    name={"Voltar"}
                  />
                }
              </Grid>
            </Grid>
          </Grid>
        }
      </>
    )
  }
}

export default withRouter(Convite);