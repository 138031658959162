import API, { API as Instance } from './API';

const path = `arquivos`;

const FileService = {
    // FILTROS
    filters: async filters => await API.filters(path, filters),

    // LISTAR TODOS
    list: async () => await API.list(path),
    
    // ADICIONAR
    add: async (arquivo, config) => await Instance.post(path, arquivo, config),

    // REMOVER
    remove: async id => await API.remove(path, id),

    // DOWNLOAD
    download: async id => await API.download(path, id),

    // CERTIFICADO
    certificado: async () => await API.download(`${path}/certificado`, ''),
}

export default FileService;