import React from 'react';

import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';

import ScrollTop from "../ScrollTop/ScrollTop";
import Navbar from "../Navbar/Navbar";
import Drawer from '../Drawer/Drawer';

export function Dashboard(props) {
  return (
    <>
      <CssBaseline />
      <Navbar
        open={props.open}
        click={props.handleDrawerOpen}
        nameApp={process.env.REACT_APP_NAME_GENERAL}
        handleLogout={props.handleLogout}
      />
      <Drawer 
        open={props.open}
        click={props.handleDrawerClose} 
        darkMode={props.darkMode}
        setDarkMode={props.setDarkMode}
      />
      <Toolbar id="top" />

      <ScrollTop {...props} />
    </>
  );
}

export default Dashboard;