import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { blue, green, red } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import BlockIcon from '@material-ui/icons/Block';
import LoopIcon from '@material-ui/icons/Loop';
import ErrorIcon from '@material-ui/icons/Error';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SendIcon from '@material-ui/icons/Send';

import ConviteService from '../../services/Convite';
import { errors } from '../../services/API';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Toast from "../../components/Toast/Toast";
import { ButtonSecondary } from '../../components/Button/Button';
import { ButtonSave } from '../../forms/Buttons/ButtonsForm';

import AuthorizedFunction from '../../security/AuthorizedFunction';
import { 
    __SECRETARIA_PEDIDO_EMAIL_REENVIAR,
    __SECRETARIA_EMAIL_REENVIAR,
    __ESCOLA_EMAIL_REENVIAR,
} from '../../security/RoleConfiguration';

import { patternTypeMail } from '../../variables/Enums/Email';

const useStyles = makeStyles((theme) => (
    {
        error: {
            backgroundColor: red[400],
            color: "#fff",
        },
        info: {
            backgroundColor: blue[400],
            color: "#fff",
        },
        success: {
            backgroundColor: green[400],
            color: "#fff",
        },
        icon: {
            color: "#fff",
        }
    }
));

const ChipStatus = ({ status }) => {
    const classes = useStyles();

    return (
        <Chip
            size="small"
            icon={status === 'SUCESSO' ? <DoneIcon className={classes.icon} /> : status === 'FALHA' ? <ErrorIcon className={classes.icon} /> : status === 'AGUARDANDO' ? <LoopIcon className={classes.icon} /> : <BlockIcon className={classes.icon} />}
            label={<b>{status === 'SUCESSO' ? 'Sucesso' : status === 'FALHA' ? 'Falha' : status === 'AGUARDANDO' ? 'Aguardando' : 'Descartado'}</b>}
            className={status === 'SUCESSO' ? classes.success : status === 'FALHA' ? classes.error : status === 'AGUARDANDO' ? classes.info : null}
        />
    )
}

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
          mail: {
              id: !isNaN(this.props.match.params.email) ? this.props.match.params.email : '',
              subject: null,
              message: null,
              status: null,
              type: null,
              to: null,
          },
          entity: {
            type: this.props.location.state.entity.type ?? '',
            id: this.props.location.state.entity.id ?? '',
          },
          loading: true,
          loadingButtonSender: false,
        }
      }
    
      // FECHAR TOAST
      setToast = t => this.Toast = t;

    // REENVIAR EMAIL
  handleSendMail = () => {

    this.setState({ loadingButtonSender: true })

    const mail = {
        para: this.state.mail.to,
        tipoEmail: this.state.mail.type,
        assunto: this.state.mail.subject,
        mensagem: this.state.mail.message,
        secretariaEducacao: this.state.entity.type === 'SECRETARIA' ? this.state.entity.id : null,
        secretariaTemporaria: this.state.entity.type === 'PEDIDO' ? this.state.entity.id : null,
        mecEscola:this.state.entity.type === 'ESCOLA' ? this.state.entity.id : null,
    }

    ConviteService.sender(mail)
        .then( res => {
            this.Toast.setState({
                message: {
                    message: "O Reenvio de Email foi Configurado e em Instantes será Realizado",
                    type: "success",
                    open: true
                }
            });

            this.setState({ loadingButtonSender: false })
        })
        .catch(e => {
            this.Toast.setState({
                message: {
                    message: "Ocorreu um Problema ao Configurar o Reenvio do Email. Tente Novamente Mais Tarde",
                    type: "error",
                    open: true
                }
            })

            this.setState({ loadingButtonSender: false })
        })
    }

    // HABILITAR BOTÃO DE REENVIO DO EMAIL
    handleResendMail = () => {
        return this.state.entity.type === 'SECRETARIA' ? !AuthorizedFunction([__SECRETARIA_EMAIL_REENVIAR]) :
            this.state.entity.type === 'PEDIDO' ? !AuthorizedFunction([__SECRETARIA_PEDIDO_EMAIL_REENVIAR]) :
              this.state.entity.type === 'ESCOLA' ? !AuthorizedFunction([__ESCOLA_EMAIL_REENVIAR]) : false;
    }
    
      componentDidMount() {
        if (this.state.mail.id) {
            const id = this.state.mail.id;
            
            ConviteService.find(id)
                .then(res => {
                    this.setState({ 
                        mail: {
                            id: res.data.id,
                            subject: res.data.titulo,
                            message: res.data.corpo,
                            status: res.data.statusEnvio,
                            type: res.data.processo,
                            to: res.data.para,
                        },
                        loading: false,
                    });
                })
                .catch(error => {
                    const e = errors(error);
                    this.Toast.setState({
                        message: {
                            message: e.message,
                            type: e.type,
                            open: true
                        }
                    })
    
                    this.setState({ loading: false });
                })
        }
      }

    render () {
        const page = 'Detalhes de Email Enviado';
        const links = [
            {
                path: `/admin/${this.state.entity.type === 'SECRETARIA' ? 'secretaria' : this.state.entity.type === 'PEDIDO' ? 'secretaria/pedido' : 'escola'}`,
                name: this.state.entity.type === 'SECRETARIA' ? 'Secretarias' : this.state.entity.type === 'PEDIDO' ? 'Pedido de Secretarias' : 'Escolas',
            },
            {
                path: {
                    pathname: `/admin/${this.state.entity.type === 'SECRETARIA' ? 'secretaria' : this.state.entity.type === 'PEDIDO' ? 'secretaria/pedido' : 'escola'}/${this.state.entity.id}/emails`,
                    state: {
                        entity: {
                            id: this.state.entity.id,
                            type: this.state.entity.type
                        }
                    }
                },
                name: 'Emails Enviados',
            }
        ];

        return (
            <>
                <Toast parentRef={this.setToast} />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Breadcrumbs links={links} active={page} />
                    </Grid>
                </Grid>

                <Title>{page}</Title>

                <Grid container spacing={3} alignItems='center'>
                    <Grid item sm={12} lg={12}>
                        <Paper elevation={1} style={{ padding: 24 }} >
                            <Grid style={{ paddingBottom: 24 }}>
                                <Typography variant="body2"><b>Para:</b> {this.state.mail.to}</Typography>
                            </Grid>

                            <Grid style={{ paddingBottom: 24 }}>
                                <Typography variant="body2">
                                    <b>Status de Envio:  </b><ChipStatus status={this.state.mail.status} />
                                </Typography>
                            </Grid>

                            <Grid>
                                <Typography variant="body2"><b>Tipo de Email:</b> {patternTypeMail(this.state.mail.type)}</Typography>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item sm={12} lg={12}>
                        <Paper elevation={1} style={{ padding: 24 }} >
                            <Typography variant="h6">Assunto:</Typography>
                            <Typography variant="body2">{this.state.mail.subject}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item sm={12} lg={12}>
                        <Paper elevation={1} style={{ padding: 24 }} >
                            <Typography variant="h6">Mensagem:</Typography>              
                            <Typography variant="body2">
                                <pre>{this.state.mail.message}</pre>
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>

                <Grid container spacing={3} alignItems='flex-end' style={{ marginTop: 16 }}>
                    <Grid item sm={12} lg={4}>
                        <ButtonSave
                            idFocus={`saveButton`}
                            startIcon={<SendIcon />}
                            onClick={this.handleSendMail}
                            name="Reenviar Este Email"
                            saveDisabled={this.handleResendMail()}
                            loading={this.state.loadingButtonSender}
                        />
                    </Grid>
                    <Grid item sm={12} lg={12}>
                        <ButtonSecondary
                            startIcon={<ArrowBackIcon />}
                            style={{ margin: 4 }}
                            onClick={this.props.history.goBack}
                            name={"Voltar"}
                        />
                    </Grid>   
                </Grid>
            </>
        )
    }
}

export default withRouter(Form);