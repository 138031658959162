import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import FileDownload from 'js-file-download';

import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import RelatorioService from '../../services/Relatorio';
import { errors } from '../../services/API';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Toast from '../../components/Toast/Toast';
import Filters from '../../components/Filters/Filters';
import { ButtonSecondary } from '../../components/Button/Button';
import Loading from '../../components/Loading/Loading';

import DadosEscolasPorUf from '../../forms/Relatorio/DadosEscolasPorUf';
import UfFilter from '../../forms/Endereco/UfFilter';
import { ButtonExport } from '../../forms/Buttons/ButtonsExport';

import AuthorizedElement from '../../security/AuthorizedElement';
import { __RELATORIO_ESCOLAS_POR_UF_EXPORTAR } from '../../security/RoleConfiguration';

import { getFilenameResponse } from '../../helper/FileHelper';

export class EscolasPorUf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    }
  }

  // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
  setToast = t => this.Toast = t;
  setDadosEscolasPorUf = d => this.DadosEscolasPorUf = d;
  setUfFilter = f => this.UfFilter = f;

  // DESATIVAR FILTROS
  DisableFilters = () => {
    this.setState({ exportarPDF: false, })
    this.UfFilter.setState({ filter: false, });
  }

  // FILTROS
  ApplyFilters = () => { 
    this.setState({ loading: true });

    const f = [];
    const { filters } = this.UfFilter.state;

    if (filters.UF) f.push({ field: 'nmUf', value: filters.UF })

    RelatorioService.filtersReportSchoolsByUF(f)
      .then(res => {
        this.setState({ loading: false });

        this.DadosEscolasPorUf.setState({ 
          schools: [...res.data]
        });
      })
      .catch(error => {
          this.setState({ loading: false });
          this.DisableFilters()
      })
  }

  // EXPORTAÇÃO
  handleExportChange = () => {
    const f = [];
    const { filters } = this.UfFilter.state;

    if (filters.UF) f.push({ field: 'nmUf', value: filters.UF })

    f.push({ field: 'exportarPDF', value: true });
    
    this.Toast.setState({
        message: {
          message: "O PDF está sendo gerado para Exportação.",
          type: 'success',
          open: true
        }
    })

    RelatorioService.exportReportSchoolsByUF(f)
      .then(res => {
        FileDownload(res.data, getFilenameResponse(res));
      })
  }

  componentDidMount() {
    RelatorioService.reportSchoolsByUF()
      .then(res => {
        this.setState({ loading: false });

        this.DadosEscolasPorUf.setState({ 
          schools: [...res.data]
        });
      })
      .catch(error => {
        const e = errors(error);
        this.setState({ loading: false });
        
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })
      })
  }

  // LIMPAR FILTROS
  handleFilterClear = () => {
    this.UfFilter.setState({
      filter: true,
      filters: {
        UF: '',
      }
    })
  }

  render () {
    const pageName = 'Escolas Federais Por UF';
    const links = [
        { 
            path: null, 
            name: 'Relatórios' 
        }
    ];

    return (
      <>
        <Toast parentRef={this.setToast} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Breadcrumbs links={links} active={pageName} />
          </Grid>
        </Grid>
        <Title>{pageName}</Title>

        <Filters
          handleFilter={this.ApplyFilters}
          handleFilterClear={this.handleFilterClear}
        >
          <Grid item container spacing={3}>
            <UfFilter 
              parentRef={this.setUfFilter}
            />
          </Grid>
        </Filters>
        <Grid container spacing={3}>
          <AuthorizedElement roles={[__RELATORIO_ESCOLAS_POR_UF_EXPORTAR]}>
            <ButtonExport
              title="Exportar PDF"
              onClick={this.handleExportChange}
            />
          </AuthorizedElement>
        </Grid>

        { this.state.loading ? 
          <Loading />
        :
          <DadosEscolasPorUf parentRef={this.setDadosEscolasPorUf} />
        }

        <Grid container spacing={3} alignItems='flex-end' style={{ marginTop: 16 }}>
          <Grid item sm={12} lg={12}>
            <ButtonSecondary
              startIcon={<ArrowBackIcon />}
              style={{ margin: 4 }}
              onClick={this.props.history.goBack}
              name={"Voltar"}
            />
          </Grid>   
        </Grid>
      </>
    )
  }
}

export default withRouter(EscolasPorUf);