import * as axios from 'axios';
import { keycloak, updateToken } from '../security/keycloak';

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;

axios.defaults.headers.common['Accept'] ='application/json';
axios.defaults.headers.common['Content-Type'] ='application/json';

export const API = axios;

export const configureInterceptors = () => API.interceptors.request.use(config => {
    const cb = () => {
        config.headers.Authorization = `Bearer ${keycloak.token}`;
        return Promise.resolve(config);
    };
    return updateToken(cb);
});

export const APIService = {
    // FILTROS
    filters: async (path, filters) => await API.get(`${path}?${filters.map(f => `${f.field}=${f.value}`).join('&')}`),

    // LISTAR TODOS
    list: async path => await API.get(`${path}`),

    // LISTAR UM
    find: async (path, id) => await API.get(`${path}/${id}`),

    // ADICIONAR
    add: async (path, data) => await API.post(`${path}`, data),

    // EDITAR
    edit: async (path, id, data) => await API.put(`${path}/${id}`, data),
    
    // REMOVER
    remove: async (path, id) => await API.delete(`${path}/${id}`),

    // DOWNLOAD
    download: async (path, id) => await API.get(`${path}/${id}`, { responseType: 'blob' }),

    // EXPORTAR
    export: async (path, filters) => await API.get(`${path}?${filters.map(f => `${f.field}=${f.value}`).join('&')}`, { responseType: 'blob' }),
}

const MappingRequests = {
    400: { message: 'Ocorreu um erro no envio da requisição.', type: 'error' },
    401: { message: 'Você precisa estar autenticado para concluir essa requisição.', type: 'error' },
    403: { message: 'Você não tem permissão para acessar esta informação.', type: 'error' },
    404: { message: 'Ocorreu um erro na comunicação com servidor.', type: 'error' },
    409: { message: 'A requisição já esta sendo executada. Aguarde...', type: 'warning' },
    415: { message: 'Tipo de requisição não suportada pelo servidor', type: 'error'},
    429: { message: 'Unknown Error', type: 'error' },
    499: { message: 'Client Closed Request', type: 'error' },
    500: { message: 'Ocorreu um erro na comunicação com o servidor.', type: 'error' },
    501: { message: 'Unknown Error', type: 'error' },
    503: { message: 'O serviço está indisponível. Tente novamente em instantes.', type: 'error' },
    504: { message: 'Tempo de resposta excedido. Tente novamente.', type: 'error' },
}

export const errors = error => {
    if (error.response) {
        if (MappingRequests[error.response.status]) {
            return MappingRequests[error.response.status];
        }
        return { message: 'Unknown Error', type: 'error' };
    } else if (error.request) {
        return MappingRequests[error.request];
    }
    return MappingRequests[error.message];
}

export default APIService;