import React from 'react';
import { Link } from 'react-router-dom';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

export const CollapsedBreadcrumbs = props => {
  return (
    <Breadcrumbs 
        maxItems={2} 
        separator={ <NavigateNextIcon fontSize="small" /> } 
        aria-label="breadcrumb"
    >
        { props.links != null && props.links.length > 0 &&
            props.links.map((link, key) => {
                return (
                  link.path ?
                    <Link key={key} style={{ textDecoration: 'none' }} color="inherit" to={link.path} >
                        <Typography color="textPrimary">{ link.name }</Typography>
                    </Link>
                  : <Typography key={key} color="textPrimary">{ link.name }</Typography>
                )
            })
        }
      <Typography color="textPrimary">{ props.active }</Typography>
    </Breadcrumbs>
  );
}

export default CollapsedBreadcrumbs;