import SignIn from '../../views/Authenticate/SignIn';

export const path = '';

export const mainPage = '/';

export const listRoutes = [
    {
        path: path + '/',
        component: SignIn,
        roles: [],
    },
];

export const goToLogin = () => window.location.replace(process.env.REACT_APP_PUBLIC_URL)

export default listRoutes;