import React from 'react';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

export const Loading = () => {
    return (
        <div style={{ flexGrow: 1 }}>
            <Grid 
                container 
                style={{ height: '50vh' }} 
                direction="row" 
                justify="center" 
                alignItems='center'
            >
                <CircularProgress />
            </Grid>
        </div>
    )
}

export default Loading;