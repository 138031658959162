import API from './API';

const path = `inscricoes`;

const InscricaoService = {
    // FILTRAR ESCOLAS PARA INSCRICAO
    filterEscolas: async filters => await API.filters('mecEscolas/listarEscolasInscricaoPorResponsavel', filters),

    // LISTAR ESCOLAS PARA INSCRICAO
    listEscolas: async () => await API.list('mecEscolas/listarEscolasInscricaoPorResponsavel'),

    // FILTRAR INSCRICOES POR RESPONSÁVEL
    filter: async filters => await API.filters(path + '/listarInscricoesPorResponsavel', filters),

    // LISTAR INSCRICOES POR RESPONSÁVEL
    list: async () => await API.list(path + '/listarInscricoesPorResponsavel'),

    // LISTAR UM
    find: async id => await API.find(path, id),

    // ADICIONAR
    add: async (id, inscricao) => await API.add((id ? path +'?id='+ id : path), inscricao),

    // EDITAR
    edit: async (id, inscricao) => await API.edit(path, id, inscricao),

    // REMOVER
    remove: async id => await API.remove(path, id),
}

export default InscricaoService;