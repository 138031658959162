import Form from '../../views/Escola/Form';
import FormMail from '../../views/Email/Form';
import Email from '../../views/Email/Email';
import FormResponsavel from '../../views/Responsavel/Form';
import {
    __ESCOLA_ADICIONAR,
    __ESCOLA_EDITAR,
    __ESCOLA_EMAIL,
    __ESCOLA_EMAIL_VISUALIZAR,
    __ESCOLA_RESPONSAVEL_EDITAR
} from '../../security/RoleConfiguration';

export const path = '/admin/escola';

export const listRoutes = [
    {
        path: path + '/adicionar',
        component: Form,
        roles: [ __ESCOLA_ADICIONAR ],
    },
    {
        path: path + '/editar/:id',
        component: Form,
        roles: [ __ESCOLA_EDITAR ],
    },
    {
        path: path + '/:id/emails',
        component: Email,
        roles: [ __ESCOLA_EMAIL ],
    },
    {
        path: path + '/:id/emails/:email',
        component: FormMail,
        roles: [ __ESCOLA_EMAIL_VISUALIZAR ],
    },
    {
        path: path + '/responsavel/editar/:id',
        component: FormResponsavel,
        roles: [ __ESCOLA_RESPONSAVEL_EDITAR ],
    },
];

export default listRoutes;