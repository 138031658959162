import FormInscricao from '../../views/Responsavel/FormInscricao';

export const path = '/responsavel';

export const listRoutes = [
    {
        path: path + '/:type',
        component: FormInscricao,
        roles: [],
    },
];

export default listRoutes;