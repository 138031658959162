import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

import Text from '../../components/Inputs/Text/Text';
import Toast from '../../components/Toast/Toast';

import { 
    isBlank, 
    isBlankHelperText,
    isNameCompoundInvalid,
    isNameCompoundHelperText,
    isCPFInvalid,
    isCPFHelperText,
} from '../../helper/ValidationHelper';
import { cpfMask } from '../../helper/MaskHelper';

export class Solicitante extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            solicitor: {
                name: '',
                cpf: '',
                cargo: '',
            },
            errors: {
                name: false,
                cpf: false,
                cargo: false,
            },
            helpers: {
                name: null,
                cpf: null,
                cargo: null,
            },
        }
        this.isValid = this.isValid.bind(this);
    }

    // INPUTS
    handleChange = e => {
        this.setState({
            solicitor: {
                ...this.state.solicitor,   
                [e.target.name]: e.target.name === 'cpf' ? cpfMask(e.target.value): e.target.value 
            },
            errors: {...this.state.errors, [e.target.name]: false },
            helpers: {...this.state.helpers, [e.target.name]: null },
        })
    }

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
    setToast = t => this.Toast = t;

    // VALIDAÇÕES
    isValid = () => {
        if ( isBlank(this.state.solicitor.name) || isNameCompoundInvalid(this.state.solicitor.name) || isBlank(this.state.solicitor.cpf) || isCPFInvalid(this.state.solicitor.cpf) || isBlank(this.state.solicitor.cargo) ) {
            this.setState({ 
                errors: {
                    name: isBlank(this.state.solicitor.name) || isNameCompoundInvalid(this.state.solicitor.name) ? true : false,
                    cpf: isBlank(this.state.solicitor.cpf) || isCPFInvalid(this.state.solicitor.cpf) ? true : false,
                    cargo: isBlank(this.state.solicitor.cargo) ? true : false,
                },
                helpers: {
                    name: isBlank(this.state.solicitor.name) ? isBlankHelperText() : isNameCompoundInvalid(this.state.solicitor.name) ? isNameCompoundHelperText() : null,
                    cpf: isBlank(this.state.solicitor.cpf) ? isBlankHelperText() : isCPFInvalid(this.state.solicitor.cpf) ? isCPFHelperText() : null,
                    cargo: isBlank(this.state.solicitor.cargo) ? isBlankHelperText() : null,
                },
            });
            return false;
        }
        return true;
    }

    render () {
        return (
            <>
                <Toast parentRef={this.setToast} />                
                <Grid item container spacing={3} direction="row" alignItems='center' justify='flex-start'>
                    <Grid item sm={12} lg={1}>
                        <Typography variant="body2" wrap="true">Dados do Solicitante:</Typography>
                    </Grid>
                    <Grid item sm={12} lg={4}>
                        <Text
                            required
                            name="name"
                            label="Nome Completo do Solicitante"
                            value={this.state.solicitor.name}
                            error={this.state.errors.name}
                            onChange={(e) => this.handleChange(e)}
                            helperText={this.state.helpers.name}
                        />
                    </Grid>
                    <Grid item sm={12} lg={2}>
                        <Text
                            required
                            maxLength='14'
                            name="cpf"
                            label="CPF"
                            value={this.state.solicitor.cpf}
                            error={this.state.errors.cpf}
                            onChange={this.handleChange}
                            helperText={this.state.helpers.cpf}
                        />
                    </Grid>
                    <Grid item sm={12} lg={3}>
                        <Text
                            required
                            name="cargo"
                            label="Cargo"
                            value={this.state.solicitor.cargo}
                            error={this.state.errors.cargo}
                            onChange={this.handleChange}
                            helperText={this.state.helpers.cargo}
                        />
                    </Grid>
                </Grid>
            </>
        )
    }
}

export default Solicitante;