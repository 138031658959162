const { useEffect } = require("react");

const useEnterKeyListener = ({ querySelectorToExecuteClick }) => {
    useEffect(() => {
        const listener = (event) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                const mouseClickEvents = ["mousedown", "click", "mouseup"];
                function simulateMouseClick(element) {
                    mouseClickEvents.forEach((mouseEventType) =>
                        element.dispatchEvent(
                            new MouseEvent(mouseEventType, {
                                view: window,
                                bubbles: true,
                                cancelable: true,
                                buttons: 1
                            })
                        )
                    );
                }
        
                var element = document.querySelector(querySelectorToExecuteClick);
                simulateMouseClick(element);
            }
        };

        document.addEventListener("keydown", listener);

        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useEnterKeyListener;