import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogActions, DialogContent, Typography } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';

export const SimpleDialog = (props) => {
  const { openDialog, children, title, closeDialog, maxWidth, fullWidth, actions } = props;

  return (
    <Dialog open={openDialog} onClose={closeDialog} maxWidth={maxWidth} fullWidth={fullWidth}>
      {title &&
        <DialogTitle>
          {title}
          {closeDialog &&
            <CloseOutlined onClick={closeDialog} style={{ float: "right", cursor: "pointer" }} />
          }
        </DialogTitle>
      }

      {children && 
        <DialogContent dividers>
          <Typography gutterBottom>
            {children}
          </Typography>
        </DialogContent>
      }
      
      {actions &&
        <DialogActions>
          {actions}
        </DialogActions>
      }
    </Dialog>
  );
}

export default SimpleDialog;