import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import FileDownload from 'js-file-download';

import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import RelatorioService from '../../services/Relatorio';
import { errors } from '../../services/API';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Toast from '../../components/Toast/Toast';
import { ButtonSecondary } from '../../components/Button/Button';
import Loading from '../../components/Loading/Loading';

import DadosResponsavel from '../../forms/Relatorio/DadosResponsavel';
import DadosEscolas from '../../forms/Relatorio/DadosEscolas';
import { ButtonExport } from '../../forms/Buttons/ButtonsExport';

import AuthorizedElement from '../../security/AuthorizedElement';
import { __RELATORIO_ESCOLA_EXPORTAR } from '../../security/RoleConfiguration';

import { locationResponsavelParse } from '../../helper/ParseStateHelper';
import { getFilenameResponse } from '../../helper/FileHelper';

export class Escola extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      id: null,
      history: {
        path: null,
        state: null,
      }
    }
  }  

  // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
  setToast = t => this.Toast = t;
  setDadosResponsavel = d => this.DadosResponsavel = d;
  setDadosEscolas = d => this.DadosEscolas = d;

  // EXPORTAR PDF
  handleExportChange = () => {
    const f = [];
    f.push({ field: 'id', value: this.state.id });
    f.push({ field: 'exportarPDF', value: true });

    this.Toast.setState({
        message: {
          message: "O PDF está sendo gerado para Exportação.",
          type: 'success',
          open: true
        }
    })

    RelatorioService.exportReportSchool(f)
      .then(res => {
        FileDownload(res.data, getFilenameResponse(res));
      })
  }

  componentDidMount() {
    this.filterPersistence(this.props.location);

    const f = locationResponsavelParse(this.props.location);
    
    this.setState({ id: f.find(f => f.field === 'id').value });

    RelatorioService.filtersReportSchool(f)
      .then(res => {
        this.setState({ loading: false });

        this.DadosResponsavel.setState({
          responsible: {
            emailResponsavel: res.data.responsavelDTO.email,
            nmCpfResponsavel: res.data.responsavelDTO.nmCpf,
            nomeResponsavel: res.data.responsavelDTO.nome,
            telefonesResponsavel: [...res.data.responsavelDTO.telefones],
          }
        });

        this.DadosEscolas.setState({ 
          schools: [...res.data.escolaInscricaoDTO],
        })
      })
      .catch(error => {
        const e = errors(error);        
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })

        this.setState({ loading: false });
      })
  }

  filterPersistence = (parseLocation) => {
    if (parseLocation.state && parseLocation.state.history) {
      this.setState({
        history: {
          path: parseLocation.state.history.path,
          state: parseLocation.state.history.state
        }
      });
    }
  }

  handleBack = () => {
    this.props.history.push({
      pathname: `${this.state.history.path}`,
      state: {
        history: {
          state: this.state.history.state
        }
      }
    });
  }

  render () {
    const pageName = 'Relatório de Participação';
    const links = [];

    return (
      <>
        <Toast parentRef={this.setToast} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Breadcrumbs links={links} active={pageName} />
          </Grid>
        </Grid>
        <Title>{pageName}</Title>
        <Grid container spacing={3}>
          <AuthorizedElement roles={[__RELATORIO_ESCOLA_EXPORTAR]}>
            <ButtonExport
              title="Exportar PDf"
              onClick={this.handleExportChange}
            />
          </AuthorizedElement>
        </Grid>

        { this.state.loading ? 
          <Loading />
        :
          <>
            <DadosResponsavel parentRef={this.setDadosResponsavel} />
            <DadosEscolas parentRef={this.setDadosEscolas} />
          </>
        }

        <Grid container spacing={3} alignItems='flex-end' style={{ marginTop: 16 }}>
          <Grid item sm={12} lg={12}>
            <ButtonSecondary
              startIcon={<ArrowBackIcon />}
              style={{ margin: 4 }}
              onClick={this.state.history.path ? () => this.handleBack() : this.props.history.goBack} 
              name={"Voltar"}
            />
          </Grid>   
        </Grid>
      </>
    )
  }
}

export default withRouter(Escola);