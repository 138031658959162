import API from './API';

const path = `inscricoes`;

const RelatorioService = {
    // FILTROS
    filters: async filters => await API.filters(path + '/gerarReciboInscricao', filters),

    // EXPORTAR
    export: async filters => await API.export(path + '/gerarReciboInscricao', filters),

    // RECIBO
    receipt: async () => await API.list(path + '/gerarReciboInscricao'),
}

export default RelatorioService;