import React, { Component } from 'react';
import FileDownload from 'js-file-download';

import Grid from '@material-ui/core/Grid';

import EscolaService from '../../services/Escola';

import Filters from '../../components/Filters/Filters';
import Text from '../../components/Inputs/Text/Text';
import SelectOption from '../../components/Inputs/Select/Select';
import Boolean from '../../components/Inputs/Boolean/Boolean';

import Endereco from '../../forms/Endereco/EnderecoFilter';
import DependenciaAdministrativa from '../../forms/DependenciaAdministrativa/DependenciaAdministrativaFilter';

import { optionsRange} from '../../variables/Enums/Range';
import { optionsOrigem, optionsStatus } from '../../variables/Enums/Escola';

import { mecCodeMask } from '../../helper/MaskHelper';
import { getFilenameResponse } from '../../helper/FileHelper';
import { removePagingAndSorting } from '../../helper/PaginationHelper';

export class EscolaFilter extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            filters: {
                nameSecretary: '',
                emailSecretary: '',
                nameResponsible: '',
                emailResponsable: '',
                nameSchool: '',
                emailSchool: '',
                inepSchool: '',
                inscrita: '',
                withResponsible: '',
                origem: '',
                status: '',
                alunosPrevistos: '',
                qntdAlunosPrevistos: '',
                alunosParticipantes: '',
                qntdAlunosParticipantes: '',
            },
            exportPlan: false,
            count: 0,
        }
    }  

    // INPUTS
    handleChange = e => {
        this.setState({
            filters: {
                ...this.state.filters,  
                [e.target.name]: e.target.name === 'inepSchool' ? mecCodeMask(e.target.value) : e.target.value 
            }
        });
    };

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES TIPO FORM
    setEndereco = form => this.Endereco = form;
    setDependenciaAdministrativa = form => this.DependenciaAdministrativa = form;

    // APLICAR PERSISTENCIA
    handleFilterPersistence = (filters) => {
        filters = removePagingAndSorting(filters);

        this.setState({
            filters: {
                nameSecretary: filters.find(f =>  f.field === 'nmSecretariaEducacao') ? filters.find(f =>  f.field === 'nmSecretariaEducacao').value : "",
                emailSecretary: filters.find(f =>  f.field === 'nmEmailSecretaria') ? filters.find(f =>  f.field === 'nmEmailSecretaria').value : "",
                nameResponsible: filters.find(f =>  f.field === 'nmResponsavel') ? filters.find(f =>  f.field === 'nmResponsavel').value : "",
                emailResponsable: filters.find(f =>  f.field === 'nmEmailResponsavel') ? filters.find(f =>  f.field === 'nmEmailResponsavel').value : "",
                nameSchool: filters.find(f =>  f.field === 'nmEscola') ? filters.find(f =>  f.field === 'nmEscola').value : "",
                emailSchool: filters.find(f =>  f.field === 'nmEmail') ? filters.find(f =>  f.field === 'nmEmail').value : "",
                inepSchool: filters.find(f =>  f.field === 'cdMecEscola') ? filters.find(f =>  f.field === 'cdMecEscola').value : "",
                inscrita: filters.find(f =>  f.field === 'inscrita') ? filters.find(f =>  f.field === 'inscrita').value : "",
                withResponsible: filters.find(f =>  f.field === 'comResponsavel') ? filters.find(f =>  f.field === 'comResponsavel').value : "",
                origem: filters.find(f =>  f.field === 'inNova') ? filters.find(f =>  f.field === 'inNova').value : "",
                status: filters.find(f =>  f.field === 'nmStatus') ? filters.find(f =>  f.field === 'nmStatus').value : "",
                alunosPrevistos: filters.find(f =>  f.field === 'operadorAlunosPrevistos') ? filters.find(f =>  f.field === 'operadorAlunosPrevistos').value : "",
                qntdAlunosPrevistos: filters.find(f =>  f.field === 'qtdAlunosPrevistos') ? filters.find(f =>  f.field === 'qtdAlunosPrevistos').value : "",
                alunosParticipantes: filters.find(f =>  f.field === 'operadorAlunosParticipantes') ? filters.find(f =>  f.field === 'operadorAlunosParticipantes').value : "",
                qntdAlunosParticipantes: filters.find(f =>  f.field === 'qtdAlunosParticipantes') ? filters.find(f =>  f.field === 'qtdAlunosParticipantes').value : "",
            },
            count: filters.reduce((total, f) => f.value !== '' ? total + 1 : total, 0),
        })

        this.DependenciaAdministrativa.setState({
            filters: {
                adminDep: filters.find(f =>  f.field === 'nmDependenciaAdministrativa') ? filters.find(f =>  f.field === 'nmDependenciaAdministrativa').value : "",
            }
        })
      
        this.Endereco.setState({
            filters: {
                UF: filters.find(f =>  f.field === 'cdUf') ? filters.find(f =>  f.field === 'cdUf').value : "",
                city: filters.find(f =>  f.field === 'cdMunicipio') ? filters.find(f =>  f.field === 'cdMunicipio').value : "",
            }
        })
    }

    // FILTROS
    handleFilterApply = () => {
        const f = [];
        const { filters, exportPlan } = this.state;
        const filtersDepAdmin = this.DependenciaAdministrativa.state.filters;
        const filtersEndereco = this.Endereco.state.filters;

        if (filters.nameSecretary) f.push({ field: 'nmSecretariaEducacao', value: filters.nameSecretary })
        if (filters.emailSecretary) f.push({ field: 'nmEmailSecretaria', value: filters.emailSecretary })
        if (filters.nameResponsible) f.push({ field: 'nmResponsavel', value: filters.nameResponsible })
        if (filters.emailResponsible) f.push({ field: 'nmEmailResponsavel', value: filters.emailResponsible })
        if (filters.nameSchool) f.push({ field: 'nmEscola', value: filters.nameSchool })
        if (filters.emailSchool) f.push({ field: 'nmEmail', value: filters.emailSchool })
        if (filters.inepSchool) f.push({ field: 'cdMecEscola', value: filters.inepSchool })
        if (filters.inscrita !== '') f.push({ field: 'inscrita', value: filters.inscrita })
        if (filters.withResponsible !== '') f.push({ field: 'comResponsavel', value: filters.withResponsible })
        if (filters.origem !== '') f.push({ field: 'inNova', value: filters.origem })
        if (filters.status) f.push({ field: 'nmStatus', value: filters.status })

        if (filters.alunosParticipantes && filters.qntdAlunosParticipantes) {
            f.push({ field: 'operadorAlunosParticipantes', value: filters.alunosParticipantes })
            f.push({ field: 'qtdAlunosParticipantes', value: filters.qntdAlunosParticipantes })
        }

        if (filters.alunosPrevistos && filters.qntdAlunosPrevistos) {
            f.push({ field: 'operadorAlunosPrevistos', value: filters.alunosPrevistos })
            f.push({ field: 'qtdAlunosPrevistos', value: filters.qntdAlunosPrevistos })
        }

        if (filtersDepAdmin.adminDep) f.push({ field: 'nmDependenciaAdministrativa', value: filtersDepAdmin.adminDep })

        if (filtersEndereco.UF) f.push({ field: 'cdUf', value: filtersEndereco.UF })
        if (filtersEndereco.city) f.push({ field: 'cdMunicipio', value: filtersEndereco.city })

        if (exportPlan) {
            f.push({ field: 'exportarPlanilha', value: exportPlan });

            EscolaService.export(f)
                .then(res => {
                    FileDownload(res.data, getFilenameResponse(res));
                })
        } else {
            this.props.handleFilterChange(f);
        };        
    }

    // LIMPAR FILTROS
    handleFilterClear = () => {
        this.setState({
            filters: {
                nameSecretary: '',
                emailSecretary: '',
                nameResponsible: '',
                emailResponsable: '',
                nameSchool: '',
                emailSchool: '',
                inepSchool: '',
                inscrita: '',
                withResponsible: '',
                origem: '',
                status: '',
                alunosPrevistos: '',
                qntdAlunosPrevistos: '',
                alunosParticipantes: '',
                qntdAlunosParticipantes: '',
            },
            exportPlan: false,
            count: 0,
        })

        this.DependenciaAdministrativa.setState({
            filters: {
                adminDep: '',
            }
        })

        this.Endereco.setState({
            filters: {
                city: '',
                UF: '',
            }
        })

        this.props.handleFilterChange([], false);
    }

    // CONTAGEM DE FILTROS ATIVOS
    handleCountFilters = () => this.state.count;

    render () {
        return (
            <Filters
                handleFilter={this.handleFilterApply}
                handleFilterClear={this.handleFilterClear}
                countFilters={this.handleCountFilters}
            >
                <Grid item container spacing={3}>
                    <Grid item sm={12} lg={5}>
                        <Text
                            label='Nome da Secretaria'
                            value={this.state.filters.nameSecretary}
                            name='nameSecretary'
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Grid>
                    <Grid item sm={12} lg={4}>
                        <Text
                            label='Email da Secretaria'
                            value={this.state.filters.emailSecretary}
                            name='emailSecretary'
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={3}>
                    <Grid item sm={12} lg={5}>
                        <Text
                            label='Nome do Responsável pela Inscrição'
                            value={this.state.filters.nameResponsible}
                            name='nameResponsible'
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Grid>
                    <Grid item sm={12} lg={4}>
                        <Text
                            label='Email do Responsável pela Inscrição'
                            value={this.state.filters.emailResponsible}
                            name='emailResponsible'
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={3}>
                    <Grid item sm={12} lg={2}>
                        <Text
                            maxLength='8'
                            label="INEP"
                            name='inepSchool'
                            value={this.state.filters.inepSchool}
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Grid>
                    <Grid item sm={12} lg={3}>
                        <Text
                            label='Nome da Escola'
                            value={this.state.filters.nameSchool}
                            name='nameSchool'
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Grid>
                    <Grid item sm={12} lg={3}>
                        <Text
                            label='Email da Escola'
                            value={this.state.filters.emailSchool}
                            name='emailSchool'
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Grid>                
                    <DependenciaAdministrativa 
                        parentRef={this.setDependenciaAdministrativa} 
                        withFederal={true}
                    />
                </Grid>
                <Grid item container spacing={3}>
                    <Grid item sm={12} lg={2}>
                        <Boolean
                            label="Inscrita"
                            name='inscrita'
                            value={this.state.filters.inscrita}
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Grid>
                    <Grid item sm={12} lg={2}>
                        <Boolean
                            label="Com Responsável pela Inscrição"
                            name='withResponsible'
                            value={this.state.filters.withResponsible}
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={3}>
                    <Endereco 
                        parentRef={this.setEndereco}
                    />
                    <Grid item sm={12} lg={2}>
                    </Grid>
                    <Grid item sm={12} lg={2}>
                        <SelectOption
                            label="Status"
                            name='status'
                            value={this.state.filters.status}
                            onChange={(e) => this.handleChange(e)}
                            options={optionsStatus}
                        />
                    </Grid>
                    <Grid item sm={12} lg={3}>
                        <SelectOption
                            label="Origem"
                            name='origem'
                            value={this.state.filters.origem}
                            onChange={(e) => this.handleChange(e)}
                            options={optionsOrigem}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={3}>
                    <Grid item sm={12} lg={2}>
                        <SelectOption
                            label="Alunos Previstos"
                            name='alunosPrevistos'
                            value={this.state.filters.alunosPrevistos}
                            onChange={(e) => this.handleChange(e)}
                            options={optionsRange}
                        />
                    </Grid>
                    <Grid item sm={12} lg={2}>
                        <Text
                            label="Quantidade"
                            name='qntdAlunosPrevistos'
                            value={this.state.filters.qntdAlunosPrevistos}
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Grid>
                    <Grid item sm={12} lg={1}>
                    </Grid>
                    <Grid item sm={12} lg={2}>
                        <SelectOption
                            label="Alunos Participantes"
                            name='alunosParticipantes'
                            value={this.state.filters.alunosParticipantes}
                            onChange={(e) => this.handleChange(e)}
                            options={optionsRange}
                        />
                    </Grid>
                    <Grid item sm={12} lg={2}>
                        <Text
                            label="Quantidade"
                            name='qntdAlunosParticipantes'
                            value={this.state.filters.qntdAlunosParticipantes}
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Grid>
                </Grid>
            </Filters>
        )
    }
}

export default EscolaFilter;