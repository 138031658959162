import React, { Component } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

export const ToastDesign = ({ open, handleClose, children, severity }) => (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} variant="filled" severity={severity}>
            {children}
        </Alert>
    </Snackbar>
)

export class Toast extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            message: {
                message: '',
                type: 'error',
                open: false,
            },
        }
    }

    // FECHAR TOAST
    handleCloseToast = e => {
        this.setState({ 
            message: {
                message: '',
                type: 'error',
                open: false,
            },
        });
    }

    render () {
        return (
            <ToastDesign
                open={this.state.message.open}
                handleClose={this.handleCloseToast}
                severity={this.state.message.type}
            >
                {this.state.message.message}
            </ToastDesign>
        )
    }
}

export default Toast;