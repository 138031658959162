import API from './API';

const path = `inscricoes`;

const RelatorioService = {
    // RELATÓRIO PARA ESCOLA
    reportSchool: async () => await API.list(path + '/gerarRelatorioFinalDeEscola'),
    filtersReportSchool:  async filters => await API.filters(path + '/gerarRelatorioFinalDeEscola', filters),
    exportReportSchool:  async filters => await API.export(path + '/gerarRelatorioFinalDeEscola', filters),

    // RELATÓRIO PARA SECRETARIA
    reportSecretary: async () => await API.list(path + '/gerarRelatorioFinalSecretaria'),
    filtersReportSecretary:  async filters => await API.filters(path + '/gerarRelatorioFinalSecretaria', filters),
    exportReportSecretary:  async filters => await API.export(path + '/gerarRelatorioFinalSecretaria', filters),

    // RELATÓRIO DE TODAS AS ESCOLAS FEDERAIS
    reportSchoolsByUF: async () => await API.list(path + '/gerarRelatorioFinalFederais'),
    filtersReportSchoolsByUF:  async filters => await API.filters(path + '/gerarRelatorioFinalFederais', filters),
    exportReportSchoolsByUF:  async filters => await API.export(path + '/gerarRelatorioFinalFederais', filters),

    // RELATÓRIO DE TODAS AS SECRETARIAS ESTADUAIS
    reportStateSecretaries: async () => await API.list(path + '/gerarRelatorioFinalSecretariasPorUf'),
    filtersReportStateSecretaries:  async filters => await API.filters(path + '/gerarRelatorioFinalSecretariasPorUf', filters),
    exportReportStateSecretaries:  async filters => await API.export(path + '/gerarRelatorioFinalSecretariasPorUf', filters),

    // RELATÓRIO DE TODAS AS SECRETARIAS
    reportAllSecretaries: async () => await API.list(path + '/gerarRelatorioFinalTodasAsSecretarias'),
    filtersReportAllSecretaries:  async filters => await API.filters(path + '/gerarRelatorioFinalTodasAsSecretarias', filters),
    exportReportAllSecretaries:  async filters => await API.export(path + '/gerarRelatorioFinalTodasAsSecretarias', filters),

    // RELATÓRIO DE TODAS AS ESCOLAS
    reportAllSchools: async () => await API.list(path + '/gerarRelatorioFinalTodasAsEscolas'),
    filtersReportAllSchools:  async filters => await API.filters(path + '/gerarRelatorioFinalTodasAsEscolas', filters),
    exportReportAllSchools:  async filters => await API.export(path + '/gerarRelatorioFinalTodasAsEscolas', filters),

    // RELATÓRIO DE DEPENDENCIAS ADMINISTRATIVAS
    reportDependenciasAdministrativas: async () => await API.list(path + '/gerarRelatorioFinalDependenciasAdministrativas'),
    filtersReportDependenciasAdministrativas:  async filters => await API.filters(path + '/gerarRelatorioFinalDependenciasAdministrativas', filters),
    exportReportDependenciasAdministrativas:  async filters => await API.export(path + '/gerarRelatorioFinalDependenciasAdministrativas', filters),

    // RELATÓRIO DE MUNICÍPIOS
    reportMunicipios: async () => await API.list(path + '/gerarRelatorioFinalMunicipios'),
    filtersReportMunicipios:  async filters => await API.filters(path + '/gerarRelatorioFinalMunicipios', filters),
    exportReportMunicipios:  async filters => await API.export(path + '/gerarRelatorioFinalMunicipios', filters),

    // RELATÓRIO DE UF'S
    reportUfs: async () => await API.list(path + '/gerarRelatorioFinalUfs'),
    filtersReportUfs:  async filters => await API.filters(path + '/gerarRelatorioFinalUfs', filters),
    exportReportUfs:  async filters => await API.export(path + '/gerarRelatorioFinalUfs', filters),
}

export default RelatorioService;