import { toDateTimeForCompare } from './DateHelper';

// CONSTANTES DE VARIAVEIS DE CONTEXTO NO BANCO DE DADOS
const EDICAO = 1;
const INICIO_INSCRICOES = 2;
const FIM_INSCRICOES = 3;
const INICIO_DOWNLOAD_PROVA = 4;
const FIM_DOWNLOAD_PROVA = 5;
const INICIO_DOWNLOAD_GABARITO = 6;
const FIM_DOWNLOAD_GABARITO = 7;
const INICIO_REGISTRO_PARTICIPACAO = 8;
const FIM_REGISTRO_PARTICIPACAO = 9;
const DATA_PROVA = 10;
const INICIO_DOWNLOAD_CARTAO = 11;
const FIM_DOWNLOAD_CARTAO = 12;
const INICIO_DOWNLOAD_MASCARA = 13;
const FIM_DOWNLOAD_MASCARA = 14;
const INICIO_DOWNLOAD_RELATORIO_INFORMATIVO = 15;
const FIM_DOWNLOAD_RELATORIO_INFORMATIVO = 16;
const INICIO_DOWNLOAD_MANUAL = 17;
const FIM_DOWNLOAD_MANUAL = 18;
const INICIO_DOWNLOAD_CARTAZ = 19;
const FIM_DOWNLOAD_CARTAZ = 20;
const INICIO_DOWNLOAD_CERTIFICADO = 21;
const FIM_DOWNLOAD_CERTIFICADO = 22;

// MÉTODO SIMPLES DE BUSCA DE VARIAVEL DE CONTEXTO
export const takeContext = (contexts, id) => {
    const c = contexts.find(c => c.cdVariavel === id)
    return c ? id === EDICAO ? c.nmValor : toDateTimeForCompare(c.nmValor) : null;
}

// OPERAÇÕES LÓGICAS BÁSICAS DE CONTEXTUALIZAÇÃO
export const isBefore = (contexts, id) => takeContext(contexts, id) > new Date();
export const isAfter = (contexts, id) => takeContext(contexts, id) < new Date();
export const isBeforeOrEqual = (contexts, id) => takeContext(contexts, id) >= new Date();
export const isAfterOrEqual = (contexts, id) => takeContext(contexts, id) <= new Date();
export const isBetween = (contexts, idDate1, idDate2) => isAfterOrEqual(contexts, idDate1) && isBeforeOrEqual(contexts, idDate2);
export const isNotBetween = (contexts, idDate1, idDate2) => isBefore(contexts, idDate1) && isAfter(contexts, idDate2);


/////////////// OS MÉTODOS ABAIXO ESPERAM A PASSAGEM DO STORE COM CONTEXTO CARREGADO NAS VIEWS QUE SERÃO CONTEXTUALIZADAS ///////////////

// MÉTODO DE BUSCA DE NÚMERO DA EDIÇÃO
export const getEdicao = contexts => takeContext(contexts, EDICAO);

// MÉTODO BOLEANO PARA VERIFICAR SE ESTÁ DENTRO DO PERÍODO DE INSCRIÇÕES
export const isPeriodoInscricoes = contexts => isBetween(contexts, INICIO_INSCRICOES, FIM_INSCRICOES);

// MÉTODO BOLEANO PARA VERIFICAR SE ESTÁ DENTRO DO PERÍODO DE DOWNLOAD DE PROVA
export const isPeriodoDownloadProva = contexts => isBetween(contexts, INICIO_DOWNLOAD_PROVA, FIM_DOWNLOAD_PROVA);

// MÉTODO BOLEANO PARA VERIFICAR SE ESTÁ DENTRO DO PERÍODO DE DOWNLOAD DE CARTÃO
export const isPeriodoDownloadCartao = contexts => isBetween(contexts, INICIO_DOWNLOAD_CARTAO, FIM_DOWNLOAD_CARTAO);

// MÉTODO BOLEANO PARA VERIFICAR SE ESTÁ DENTRO DO PERÍODO DE DOWNLOAD DE MÁSCARA
export const isPeriodoDownloadMascara = contexts => isBetween(contexts, INICIO_DOWNLOAD_MASCARA, FIM_DOWNLOAD_MASCARA);

// MÉTODO BOLEANO PARA VERIFICAR SE ESTÁ DENTRO DO PERÍODO DE DOWNLOAD DE GABARITO
export const isPeriodoDownloadGabarito = contexts => isBetween(contexts, INICIO_DOWNLOAD_GABARITO, FIM_DOWNLOAD_GABARITO);

// MÉTODO BOLEANO PARA VERIFICAR SE ESTÁ DENTRO DO PERÍODO DE DOWNLOAD DE RELATORIO INFORMATIVO DE PARTICIPAÇÃO
export const isPeriodoDownloadRelatorioInformativo = contexts => isBetween(contexts, INICIO_DOWNLOAD_RELATORIO_INFORMATIVO, FIM_DOWNLOAD_RELATORIO_INFORMATIVO);

// MÉTODO BOLEANO PARA VERIFICAR SE ESTÁ DENTRO DO PERÍODO DE DOWNLOAD DE MANUAL
export const isPeriodoDownloadManual = contexts => isBetween(contexts, INICIO_DOWNLOAD_MANUAL, FIM_DOWNLOAD_MANUAL);

// MÉTODO BOLEANO PARA VERIFICAR SE ESTÁ DENTRO DO PERÍODO DE DOWNLOAD DE CARTAZ
export const isPeriodoDownloadCartaz = contexts => isBetween(contexts, INICIO_DOWNLOAD_CARTAZ, FIM_DOWNLOAD_CARTAZ);

// MÉTODO BOLEANO PARA VERIFICAR SE ESTÁ DENTRO DO PERÍODO DE REGISTRO DE PARTICIPAÇÕES
export const isPeriodoRegistroParticipacao = contexts => isBetween(contexts, INICIO_REGISTRO_PARTICIPACAO, FIM_REGISTRO_PARTICIPACAO);

// MÉTODO BOLEANO PARA VERIFICAR SE ESTÁ DENTRO DO PERÍODO DE DOWNLOAD DE CERTIFICADO
export const isPeriodoDownloadCertificado = contexts => isBetween(contexts, INICIO_DOWNLOAD_CERTIFICADO, FIM_DOWNLOAD_CERTIFICADO);

// MÉTODO BOLEANO PARA VERIFICAR SE ESTÁ DEPOIS DO PERÍODO DE REGISTRO DE PARTICIPAÇÕES
export const isAfterPeriodoRegistroParticipacao = contexts => isAfter(contexts, FIM_REGISTRO_PARTICIPACAO);

// MÉTODO BOLEANO PARA VERIFICAR SE ESTÁ DEPOIS DA DATA DA PROVA
export const isAfterDataProva = contexts => isAfter(contexts, DATA_PROVA);