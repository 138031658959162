import React from 'react';

import Button from '@material-ui/core/Button';

export const ButtonPrimary = props => {
    const { name } = props;

    return (
        <Button
            variant="contained"
            size="small"
            color="primary"
            {...props}
        >
            {name}
        </Button>
    )
}

export const ButtonDefault = props => {
    const { name } = props;

    return (
        <Button
            variant="contained"
            size="small"
            color="default"
            {...props}
        >
            {name}
        </Button>
    )
}

export const ButtonSecondary = props => {
    const { name } = props;

    return (
        <Button
            variant="contained"
            size="small"
            color="secondary"
            {...props}
        >
            {name}
        </Button>
    )
}

export default ButtonPrimary;