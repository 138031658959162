import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

import ContextoService from '../../services/Contexto';
import { errors } from '../../services/API';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Table from '../../components/Table/Table';
import Toast from '../../components/Toast/Toast';
import { 
  verifyPagingAndSorting,
  PAGE,
  SIZE,
  ASC,
  TOTAL_ELEMENTS,
  TOTAL_PAGES,
} from '../../components/Table/Utils';

import ContextoFilter from '../../forms/Contexto/ContextoFilter';
import { ButtonAdd } from '../../forms/Buttons/ButtonsAdd';
import Dialog from '../../forms/Dialog/DialogRemove';

import { returnLabel } from '../../variables/Enums/Contexto';

import AuthorizedFunction from '../../security/AuthorizedFunction';
import AuthorizedElement from '../../security/AuthorizedElement';
import {
  __CONTEXTO_EDITAR,
  __CONTEXTO_REMOVER,
  __CONTEXTO_ADICIONAR,
} from '../../security/RoleConfiguration';

import { verifyPersistence, removePagingAndSorting } from '../../helper/PaginationHelper';

export class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: PAGE,
      size: SIZE,
      totalElements: TOTAL_ELEMENTS,
      totalPages: TOTAL_PAGES,
      order: ASC,
      orderBy: 'cdVariavel',
      defaultOrderBy: 'cdVariavel',
      contexts: [],
      filtered: false,
      filters: [],
      loading: true,
    }

    this.state = verifyPersistence(this.state, this.props.location);
  }  

  // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
  setToast = t => this.Toast = t;
  setContextoFilter = f => this.ContextoFilter = f;
  setDialog = f => this.Dialog = f;

  // LABELS
  labelEditar = id => !this.disabledEditar(id) ? "Editar" : "Você não tem permissão para fazer isso";
  labelApagar = id => !this.disabledApagar(id) ? "Apagar" : "Você não tem permissão para fazer isso";

  // DISABLES
  disabledEditar = id => !AuthorizedFunction([__CONTEXTO_EDITAR]);
  disabledApagar = id => !AuthorizedFunction([__CONTEXTO_REMOVER]);
  
  // EDIÇÃO
  handleEditClick = id => { 
    this.props.history.push({ 
      pathname: `/admin/contexto/editar/${id}`,
      state: {
        history: {
          path: `/admin/contexto/listagem`,
          state: this.state,
        }
      }
    })
  };

  // ABRIR MODAL DE CONFIRMAÇÃO DE REMOÇÃO
  openDialogRemove = id => {
    const context = this.state.contexts.find(context => context.cdVariavel === id);
    this.Dialog.setState({ 
      dialog: { 
        open: true, 
        title: `Deseja Remover a Variável ${context.nmVariavelContexto} ?`,
        text: `A remoção de variáveis sem o prévio levantanto de ações pode impactar diretamente na contextualização geral do sistema, por isso tenha cuidado ao remover uma variável.`,
        id: id,
        loading: false,
      }
    });
  }
  
  // REMOÇÃO
  handleRemoveClick = id => {
    this.Dialog.loading();

    ContextoService.remove(id)
      .then(res => {
        const newContexts = this.state.contexts.filter(context => context.cdVariavel !== id)

        this.setState({ 
          contexts: [...newContexts],
        })

        this.Dialog.close();
        this.Toast.setState({
          message: {
            message: "A Variável de Contexto foi Apagada com Sucesso.",
            type: 'success',
            open: true
          }
        })
      })
      .catch(error => {
        const e = errors(error);        
        this.Dialog.close();
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })        
      })
  }

  // CARREGAR DADOS
  loadData = () => {
    let filters = verifyPagingAndSorting(this.state);

    this.setState({ 
      filters: [...filters], 
      filtered: true, 
      loading: true, 
    });

    ContextoService.filters(filters)
      .then(res => {
        if (res.data.content) {
          this.setState({ 
            contexts: [...res.data.content],
            page: res.data.number,
            size: res.data.size,
            totalElements: res.data.totalElements, 
            totalPages: res.data.totalPages,
            loading: false,
          });
        } else {
          this.setState({ 
            contexts: [],
            page: PAGE,
            size: SIZE,
            totalElements: TOTAL_ELEMENTS,
            totalPages: TOTAL_PAGES,
            loading: false,
          });
        }
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })

        this.setState({ loading: false });
      })   
  }
  
  // FILTROS
  filterData = (filters, isActive = true) => this.setState({ filtered: isActive, filters: [...removePagingAndSorting(filters)] }, () => this.loadData());

  // PÁGINA
  handlePage = page => this.setState({ page: page }, () => this.filterData(this.state.filters));

  // LINHAS POR PÁGINA
  handleRowsPerPage = (size, page) => this.setState({ size: size, page: page }, () => this.filterData(this.state.filters));

  // ORDENAÇÃO
  handleSort = (orderBy, order) => this.setState({ order: order, orderBy: orderBy }, () => this.filterData(this.state.filters));

  // TOTAL DE PAGINAS
  handleTotalPages = () => this.state.totalPages;

  // TOTAL DE ELEMENTOS
  handleTotalElements = () => this.state.totalElements;

  // VERIFICAR PERSISTENCIA NO FORMULARIO DE FILTROS
  handleFilterPersistence = () => this.ContextoFilter ? this.ContextoFilter.state ? this.ContextoFilter.handleFilterPersistence(this.state.filters) : null : null;

  // COMPONENT DID MOUNT
  componentDidMount() { this.loadData(); }

  render () {
    const pageName = 'Listagem de Variáveis';
    const links = [
      { 
        path: '/admin/contexto', 
        name: 'Contexto' 
      }
    ];
  
    const columns = [
      { label: '#', name: 'cdVariavel', func: null, key: true },
      { label: 'Nome da Variável', name: 'nmVariavelContexto', func: null, key: false },
      { label: 'Tipo', name: 'nmTipo', func: returnLabel, key: false },
    ];
    
    const actions = [
      { 
        name: this.labelEditar,
        func: this.handleEditClick,
        icon: <EditOutlinedIcon />,
        disabled: this.disabledEditar,
      },
      { 
        name: this.labelApagar,
        func: this.openDialogRemove,
        icon: <DeleteOutlinedIcon />,
        disabled: this.disabledApagar,
        color: "secondary",
      },
    ];

    this.handleFilterPersistence();

    return (
      <>
        <Toast parentRef={this.setToast} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Breadcrumbs links={links} active={pageName} />
          </Grid>
        </Grid>
        <Title>{pageName}</Title>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ContextoFilter 
              parentRef={this.setContextoFilter}
              handleFilterChange={this.filterData}
            />
            <AuthorizedElement roles={[__CONTEXTO_ADICIONAR]}>
              <ButtonAdd 
                to='/admin/contexto/adicionar'
                title="Adicionar Variável"
              />
            </AuthorizedElement>
            <Table
              loading={this.state.loading}         
              columns={columns}
              tableData={this.state.contexts}
              actions={actions}
              page={this.state.page}
              handlePage={this.handlePage}
              handleTotalPages={this.handleTotalPages}
              handleTotalElements={this.handleTotalElements}
              rowsPerPage={this.state.size}
              handleRowsPerPage={this.handleRowsPerPage}
              order={this.state.order}
              orderBy={this.state.orderBy}
              handleSort={this.handleSort}
            />
          </Grid>
        </Grid>

        <Dialog 
          parentRef={this.setDialog}
          handleConfirm={this.handleRemoveClick}
        />
      </>
    )
  }
}

export default withRouter(List);