export const patternCity = options => {
    let array = [];

    options.forEach( option => {
        array.push({ value: option.cdMunicipioRegiao, label: option.nmMunicipio })        
    });

    return array;
}

export default patternCity;