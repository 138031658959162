import React from 'react';

import Grid from '@material-ui/core/Grid';

import DateTimePicker from '../Inputs/DateTimePicker/DateTimePicker';
import Text from '../Inputs/Text/Text';
import Boolean from '../Inputs/Boolean/Boolean';

export const ContextDispatcher = (props) => {
  return (
    <Grid item sm={12} lg={3} >
      { props.context.type === "DATA" ? <DateTimePicker {...props} />
        : props.context.type === "BOOLEAN" ? <Boolean {...props} />
        : props.context.type === "TEXTO" ? <Text {...props} />
        : props.context.type === "LONG" ? <Text {...props} />
        : null
      }
    </Grid>
  );
}

export default ContextDispatcher;