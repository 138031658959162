import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import Text from '../../components/Inputs/Text/Text';
import SelectOption from '../../components/Inputs/Select/Select';
import Toast from '../../components/Toast/Toast';

import CEPService from '../../services/CEP';

import UFService from '../../services/UF';
import { patternUF } from '../../variables/Enums/UF';

import CidadeService from '../../services/Cidade';
import { patternCity } from '../../variables/Enums/Cidade';

import { 
    isBlank, 
    isBlankHelperText, 
    isZipCodeInvalid, 
    isZipCodeHelperText 
} from '../../helper/ValidationHelper';
import { zipCodeMask } from '../../helper/MaskHelper';

export class Endereco extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            address: {
                zipCode: '',
                address: '',
                number: '',
                complement: '',
                district: '',
                city: '',
                UF: '',
            },
            errors: {
                zipCode: false,
                address: false,
                district: false,
                city: false,
                UF: false,
            },
            helpers: {
                zipCode: null,
                address: null,
                district: null,
                city: null,
                UF: null,
            },
            disabled: {
                city: true,
            },
            optionsUF: [],
            optionsCity: [],
        }
        this.isValid = this.isValid.bind(this);
    }

    // INPUTS
    handleChange = e => {
        this.setState({
            address: {
                ...this.state.address, 
                [e.target.name]: e.target.name === 'zipCode' ? zipCodeMask(e.target.value): e.target.value 
            },
            errors: {...this.state.errors, [e.target.name]: false },
            helpers: {...this.state.helpers, [e.target.name]: null }
        })
    }

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
    setToast = t => this.Toast = t;

    // VALIDAÇÕES
    isValid = () => {
        if ( isBlank(this.state.address.zipCode) || isZipCodeInvalid(this.state.address.zipCode) || isBlank(this.state.address.address) || isBlank(this.state.address.district) ||
                isBlank(this.state.address.city) || isBlank(this.state.address.UF) ) {
            this.setState({ 
                errors: {
                    zipCode: isBlank(this.state.address.zipCode) || isZipCodeInvalid(this.state.address.zipCode) ? true : false,
                    address: isBlank(this.state.address.address) ? true : false,
                    district: isBlank(this.state.address.district) ? true : false,
                    city: isBlank(this.state.address.city) ? true : false,
                    UF: isBlank(this.state.address.UF) ? true : false,
                },
                helpers: {
                    zipCode: isBlank(this.state.address.zipCode) ? isBlankHelperText() : isZipCodeInvalid(this.state.address.zipCode) ? isZipCodeHelperText() : null,
                    address: isBlank(this.state.address.address) ? isBlankHelperText() : null,
                    district: isBlank(this.state.address.district) ? isBlankHelperText() : null,
                    city: isBlank(this.state.address.city) ? isBlankHelperText() : null,
                    UF: isBlank(this.state.address.UF) ? isBlankHelperText() : null,
                },
            });
            return false;
        }
        return true;
    }

    // BUSCA POR CEP
    handleChangeCEP = e => {
        const cep = e.target.value.replace('-', '');

        if (cep.length < 8) return;

        CEPService.find(cep)
            .then(res => {
                const verifyAddress = () => res.data.logradouro || res.data.logradouro !== 'null';
                const verifyDistrict = () => res.data.bairro || res.data.bairro !== 'null';
                const verifyUF = () => res.data.uf || res.data.uf !== 'null';

                this.setState({
                    address: {
                        ...this.state.address,
                        address: verifyAddress() ? res.data.logradouro : null,
                        district: verifyDistrict() ? res.data.bairro : null,
                        UF: verifyUF() ? res.data.uf : null,
                    },
                    errors: {
                        ...this.state.errors,
                        address: verifyAddress() ? false : true,
                        district: verifyDistrict() ? false : true,
                        UF: verifyUF() ? false : true,
                    },
                    helpers: {
                        ...this.state.helpers,
                        address: verifyAddress() ? null : isBlankHelperText(),
                        district: verifyDistrict() ? null : isBlankHelperText(),
                        UF: verifyUF() ? null : isBlankHelperText(),
                    },
                })
                this.findCities(res.data.uf, res.data.municipioId);
            })
            .catch(res => {
                this.Toast.setState({
                    message: {
                        message: "Não Foi Possível Buscar o CEP.",
                        type: 'error',
                        open: true
                    }
                })
            })
    }

    // BUSCAR MUNICIPIOS POR UF
    findCities = (uf, citySelected) => {
        const f = [];

        if (!uf) {
            this.setState({ 
                address: {
                    ...this.state.address,
                    UF: '',
                    city: '',
                },
                disabled: {
                    city: true,
                },
                optionsCity: []
            })
            return
        }
        
        f.push({ field: "uf", value: uf });

        CidadeService.filters(f)
            .then(res => {
                this.setState({
                    address: {
                        ...this.state.address,
                        UF: uf,
                        city: citySelected ?? '',
                    },
                    errors: {
                        ...this.state.errors,
                        UF: uf ? false : true,
                    },
                    helpers: {
                        ...this.state.helpers,
                        UF: uf ? null : isBlankHelperText(),
                    },
                    disabled:{
                        city: false,
                    },
                    optionsCity: [...patternCity(res.data)]
                })
            })
            .catch(err => {
                this.Toast.setState({
                    message: {
                        message: "Não Foi Possível Buscar as Cidades.",
                        type: 'error',
                        open: true
                    }
                })
            })
    }

    // ENCHER MUNICIPIOS
    handleChangeUF = e => this.findCities(e.target.value, null);

    componentDidMount () {
        UFService.list()
            .then(res => {
                this.setState({
                    optionsUF: [...patternUF(res.data)]
                })
            })
            .catch(err => {
                this.Toast.setState({
                    message: {
                        message: "Não Foi Possível Buscar as UF's.",
                        type: 'error',
                        open: true
                    }
                })
            })
    }

    render () {
        return (
            <>
                <Toast parentRef={this.setToast} />
                <Grid item container spacing={3}>
                    <Grid item sm={12} lg={2}>
                        <Text
                            required
                            maxLength='9'
                            name="zipCode"
                            label="CEP"
                            value={this.state.address.zipCode}
                            error={this.state.errors.zipCode}
                            onChange={(e) => {
                                this.handleChange(e)
                                this.handleChangeCEP(e)
                            }}
                            helperText={this.state.helpers.zipCode}
                        />
                    </Grid>
                    <Grid item sm={12} lg={5}>
                        <Text
                            required
                            name="address"
                            label="Endereço"
                            value={this.state.address.address}
                            error={this.state.errors.address}
                            onChange={this.handleChange}
                            helperText={this.state.helpers.address}
                        />
                    </Grid>
                    <Grid item sm={12} lg={1}>
                        <Text
                            name="number"
                            label="Número"
                            value={this.state.address.number}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item sm={12} lg={3}>
                        <Text
                            name="complement"
                            label="Complemento"
                            value={this.state.address.complement}
                            onChange={this.handleChange}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={3}>
                    <Grid item sm={12} lg={2}>
                        <SelectOption
                            required
                            label="UF"
                            name='UF'
                            value={this.state.address.UF}
                            error={this.state.errors.UF}
                            onChange={this.handleChangeUF}
                            options={this.state.optionsUF}
                            hiddenblank="true"
                            helperText={this.state.helpers.UF}
                        />
                    </Grid>
                    <Grid item sm={12} lg={3}>
                        <SelectOption
                            required
                            label="Município"
                            name='city'
                            value={this.state.address.city}
                            error={this.state.errors.city}
                            onChange={this.handleChange}
                            options={this.state.optionsCity}
                            hiddenblank="true"
                            helperText={this.state.helpers.city}
                            disabled={this.state.disabled.city}
                    />
                    </Grid>
                    <Grid item sm={12} lg={4}>
                        <Text
                            required
                            name="district"
                            label="Bairro"
                            value={this.state.address.district}
                            error={this.state.errors.district}
                            onChange={this.handleChange}
                            helperText={this.state.helpers.district}
                        />
                    </Grid>
                </Grid>
            </>
        )
    }
}

export default Endereco;