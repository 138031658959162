import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(2, 0),
        width: '100%',
    },
}));

export const SelectOption = (props) => {
    const classes = useStyles();
    const { options, hiddenblank } = props;

    return (
        <FormControl className={classes.formControl}>
            <TextField
                select
                {...props}
            >
                {!hiddenblank && <MenuItem value=''>Todos</MenuItem>}

                {options.map((option, key) => {
                    return ( <MenuItem key={key} value={option.value}>{option.label}</MenuItem> )
                })}                
            </TextField>
        </FormControl>
    );
}

export default SelectOption;