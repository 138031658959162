import React, { Component } from 'react';
import { withRouter, useHistory } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadContext } from '../../store/actions/Contexto';
import { isAfterPeriodoRegistroParticipacao, isAfterDataProva } from '../../helper/ContextHelper';

import Grid from '@material-ui/core/Grid';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import SendIcon from '@material-ui/icons/Send';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import DescriptionIcon from '@material-ui/icons/Description';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

import EscolaService from '../../services/Escola';
import { errors } from '../../services/API';

import { patternSchools } from '../../variables/Enums/Escola';
import { patternSendToSchools } from '../../variables/Enums/Convite';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Table from '../../components/Table/Table';
import Toast from '../../components/Toast/Toast';
import { ButtonPrimary } from '../../components/Button/Button';
import { 
  verifyPagingAndSorting,
  PAGE,
  SIZE,
  ASC,
  TOTAL_ELEMENTS,
  TOTAL_PAGES,
} from '../../components/Table/Utils';

import EscolaFilter from '../../forms/Escola/EscolaFilter';
import { ButtonExport } from '../../forms/Buttons/ButtonsExport';
import { ButtonAdd } from '../../forms/Buttons/ButtonsAdd';

import { makeStyles } from '@material-ui/core/styles';

import { evasaoLimiterMask } from '../../helper/MaskHelper';
import { verifyPersistence, removePagingAndSorting } from '../../helper/PaginationHelper';

import AuthorizedFunction from '../../security/AuthorizedFunction';
import AuthorizedElement from '../../security/AuthorizedElement';
import {
  __ESCOLA_ADICIONAR,
  __ESCOLA_EXPORTAR,
  __ESCOLA_EDITAR,
  __ESCOLA_EMAIL_ENVIAR_INDIVIDUAL,
  __ESCOLA_EMAIL_ENVIAR_MASSA,
  __ESCOLA_DASHBOARD,
  __ESCOLA_EMAIL,
  __ESCOLA_RESPONSAVEL_EDITAR,
  __RELATORIO_ESCOLA_VISUALIZAR,
} from '../../security/RoleConfiguration';

export const useStyles = makeStyles( theme => ({
  body: {
    margin: theme.spacing(3, 0),
  },
}));

const ButtonSendInvitation = ({ state, schools, disabled }) => {
    const classes = useStyles();
    const history = useHistory();

    const handleSendInvitation = () => {
      history.push({
        pathname: `/admin/convite`,
        state: {
          invite: {
            to: patternSendToSchools(schools),
          },
          target: 2,
          history: { 
            path: `/admin/escola`,
            state: state,
          }
        },
      });
    }
  
    return (
      <Grid item sm={12} lg={4} className={classes.body}>
        <ButtonPrimary
          startIcon={<SendIcon />}
          onClick={handleSendInvitation}
          name="Enviar Email Convite Para as Escolas Selecionadas"
          disabled={disabled}
        />
      </Grid>
    ) 
}

export class Escola extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: PAGE,
      size: SIZE,
      totalElements: TOTAL_ELEMENTS,
      totalPages: TOTAL_PAGES,
      order: ASC,
      orderBy: 'cdMecEscola',
      defaultOrderBy: 'cdMecEscola',
      filtered: false,
      filters: [],
      schools: [],
      loading: true,
      sendToSchools: [],
    }

    this.state = verifyPersistence(this.state, this.props.location);
  }  

  // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
  setToast = t => this.Toast = t;
  setEscolaFilter = f => this.EscolaFilter = f;

  // EDIÇÃO
  handleEditClick = id => { 
    this.props.history.push({
      pathname: `/admin/escola/editar/${id}`,
      state: {
        history: { 
          path: `/admin/escola`,
          state: this.state,
        }
      }
    });
  }

  // LABELS
  labelEditar = id => !this.disabledEditar(id) ? "Editar" : "Você não tem permissão para fazer isso";
  labelResponsavel = id => !this.disabledResponsavel(id) ? "Editar Responsável pela Inscrição" : !this.habilitarEdicaoResponsavel(id) ? "Escola sem Responsável pela Inscrição vinculado" : "Você não tem permissão para fazer isso";
  labelEnviarEmailIndividual = id => !this.disabledEnviarEmailIndividual(id) ? "Enviar Convite Individual" : !this.habilitarEnvioEmailIndividual(id) ? "Escola sem email cadastrado" : "Você não tem permissão para fazer isso";
  labelEmailsEnviados = id => !this.disabledEmailsEnviados(id) ? "Ver Emails Enviados" : "Você não tem permissão para fazer isso";
  labelRelatorio = id => !this.disabledRelatorio(id) ? "Ver Relatório de Escola" : !this.isEscolaInscritaEComResponsavelParaRelatorio(id) ? this.isVinculadoSecretaria(id) ? "Escola Inscrita por Secretaria" : "Escola sem Inscrição ou sem Responsável pela Inscrição" : !this.isPeriodoRelatorio() ? "Fora do Período de Download de Relatório" : "Você não tem permissão para fazer isso";
  labelDashboard = id => !this.disabledDashboard(id) ? "Ver Dashboard" : !this.habilitarVisaoDashboard(id) ? "Escola sem Responsável pela Inscrição vinculado" : "Você não tem permissão para fazer isso";

  // VERIFICAR CONTEXTO PARA DOWNLOAD DE RELATORIO
  isPeriodoRelatorio = () => isAfterPeriodoRegistroParticipacao(this.props.contexts) && isAfterDataProva(this.props.contexts);

  // VERIFICAR SE ESCOLA ESTA INSCRITA
  isEscolaInscritaEComResponsavelParaRelatorio = id => {
    const school = this.state.schools.find(s => s.cdMecEscola === id);
    return school.idResponsavel && school.isInscrita ? true : false;
  }

  // VERIFICAR SE ESCOLA ESTA INSCRITA E VINCULADA A SECRETARIA
  isVinculadoSecretaria = id => {
    const school = this.state.schools.find(s => s.cdMecEscola === id);
    return !school.idResponsavel && school.isInscrita ? true : false;
  }

  // DISABLES
  disabledEditar = id => !AuthorizedFunction([__ESCOLA_EDITAR]);
  disabledResponsavel = id => AuthorizedFunction([__ESCOLA_RESPONSAVEL_EDITAR]) ? !this.habilitarEdicaoResponsavel(id) : !AuthorizedFunction([__ESCOLA_RESPONSAVEL_EDITAR]) 
  disabledEnviarEmailIndividual = id => AuthorizedFunction([__ESCOLA_EMAIL_ENVIAR_INDIVIDUAL]) ? !this.habilitarEnvioEmailIndividual(id) : !AuthorizedFunction([__ESCOLA_EMAIL_ENVIAR_INDIVIDUAL]);
  disabledEmailsEnviados = id => !AuthorizedFunction([__ESCOLA_EMAIL]);
  disabledRelatorio = id => AuthorizedFunction([__RELATORIO_ESCOLA_VISUALIZAR]) ? !this.habilitarRelatorio(id) : !AuthorizedFunction([__RELATORIO_ESCOLA_VISUALIZAR]);
  disabledDashboard = id => AuthorizedFunction([__ESCOLA_DASHBOARD]) ? !this.habilitarVisaoDashboard(id) : !AuthorizedFunction([__ESCOLA_DASHBOARD]);

  // EXPORTAÇÂO
  handleExportPlan = () => {
    this.EscolaFilter.setState({ filter: true, exportPlan: true, }, () => this.EscolaFilter.handleFilterApply());

    this.Toast.setState({
      message: {
        message: "A Planilha está sendo gerada para Exportação.",
        type: 'success',
        open: true
      }
    })
  }

  // EDIÇÃO RESPONSÁVEL
  handleEditResponsibleClick = id => { 
    const school = this.state.schools.find(s => s.cdMecEscola === id);
    this.props.history.push({ 
      pathname: `/admin/escola/responsavel/editar/${school.idResponsavel}`,
      state: { 
        history: { 
          path: `/admin/escola`,
          state: this.state,
        }
      }
    })
  }

  // VERIFICAR EXIBIÇÃO DO BOTÃO DE ENVIO DE CONVITE SOMENTE PARA ESCOLAS FEDERAIS
  verifySendInvitation = () => this.EscolaFilter && this.EscolaFilter.DependenciaAdministrativa.state.filters.adminDep === "FEDERAL";

  // ENVIO INDEPENDENTE DE CONVITE PARA A ESCOLA
  handleSendInvitation = id => {
    const school = this.state.schools.filter(s => s.cdMecEscola === id);
    this.props.history.push({
      pathname: `/admin/convite`,
      state: {
        invite: {
          to: patternSendToSchools(school),
        },
        target: 2,
        history: { 
          path: `/admin/escola`,
          state: this.state,
        }
      }
    });
  }

  // VERIFICAR SE ESCOLA TEM EMAIL PARA ENVIO DE CONVITE INDEPENDENTE
  habilitarEnvioEmailIndividual = id => {
    const school = this.state.schools.find(s => s.cdMecEscola === id);
    return school.nmEmail ? true : false;
  };

  // VERIFICAR SE O CAMPO PRECISA ESTAR HABILITADO OU DESABILITADO
  habilitarEdicaoResponsavel = id => {
    const school = this.state.schools.find(s => s.cdMecEscola === id);
    return school.idResponsavel ? true : false;
  };

  // VERIFICAR EXIBIÇÃO DO BOTÃO DE DASHBOARD
  habilitarVisaoDashboard = id => {
    const school = this.state.schools.find(s => s.cdMecEscola === id);
    return school.idResponsavel ? true : false;
  };

  habilitarRelatorio = id => {
    const contexts = this.props.contexts;
    const school = this.state.schools.find(s => s.cdMecEscola === id);
    return school.idResponsavel && school.isInscrita && isAfterPeriodoRegistroParticipacao(contexts) && isAfterDataProva(contexts) ? true : false;
  };

  // VER DASHBOARD COMO ESCOLA
  handleViewDashboard = id => {
    const school = this.state.schools.find(s => s.cdMecEscola === id);
    this.props.history.push({
      pathname: `/admin/inicio`,
      state: {
        responsavel: school.idResponsavel
      }
    });
  }

  // VER LISTAGEM DE EMAILS ENVIADOS
  handleViewSends = id => {
    this.props.history.push({
      pathname: `/admin/escola/${id}/emails`,
      state: {
        entity: {
          type: 'ESCOLA', 
        },
        history: { 
          path: `/admin/escola`,
          state: this.state,
        }
      }
    });
  }

  // RECEBER EMAILS MARCADOS PARA ENVIO EM MASSA
  handleSendToSchools = keys => {
    const schools = this.state.schools.filter( s => keys.find(k => k === s.cdMecEscola));
    this.setState({
      sendToSchools: [...schools]
    })
  }

  // VER RELATÓRIO DE ESCOLA
  handleViewReport = (id) => {
    const school = this.state.schools.find(s => s.cdMecEscola === id);
    this.props.history.push({
      pathname: '/admin/relatorio/escola', 
      state: { 
        responsavel: school.idResponsavel,
        history: { 
          path: `/admin/escola`,
          state: this.state,
        }
      }
    });
  }

  loadData = () => {
    let filters = verifyPagingAndSorting(this.state);

    this.setState({ 
      filters: [...filters], 
      filtered: true, 
      loading: true 
    });

    EscolaService.filters(filters)
      .then(res => {
        if (res.data.content) {
          const schools = patternSchools(res.data.content);
          this.setState({
            schools: [...schools],
            page: res.data.number,
            size: res.data.size,
            totalElements: res.data.totalElements, 
            totalPages: res.data.totalPages,
            loading: false,
          });
        } else {
          this.setState({ 
            schools: [],
            page: PAGE,
            size: SIZE,
            totalElements: TOTAL_ELEMENTS,
            totalPages: TOTAL_PAGES,
            loading: false,
          });
        }
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })

        this.setState({ loading: false });
      })    
  }

  // FILTROS
  filterData = (filters, isActive = true) => this.setState({ filtered: isActive, filters: [...removePagingAndSorting(filters)] }, () => this.loadData());

  // PÁGINA
  handlePage = page => this.setState({ page: page }, () => this.filterData(this.state.filters));

  // LINHAS POR PÁGINA
  handleRowsPerPage = (size, page) => this.setState({ size: size, page: page }, () => this.filterData(this.state.filters));

  // ORDENAÇÃO
  handleSort = (orderBy, order) => this.setState({ order: order, orderBy: orderBy }, () => this.filterData(this.state.filters));

  // TOTAL DE PAGINAS
  handleTotalPages = () => this.state.totalPages;

  // TOTAL DE ELEMENTOS
  handleTotalElements = () => this.state.totalElements;

  // VERIFICAR PERSISTENCIA NO FORMULARIO DE FILTROS
  handleFilterPersistence = () => this.EscolaFilter ? this.EscolaFilter.state ? this.EscolaFilter.handleFilterPersistence(this.state.filters) : null : null;

  componentDidMount() { this.loadData(); }

  render () {
    const pageName = 'Escolas';
    const links = [];
  
    const columns = [
      { label: '', name: 'cdMecEscola', func: null , key: false, item: 'checkbox' },
      { label: 'INEP', name: 'cdMecEscola', func: null, key: true },
      { label: 'Escola', name: 'nmMecEscola', func: null, key: false },
      { label: 'UF', name: 'nmUf', func: null, key: false, ordering: false },
      { label: 'Município', name: 'nmMunicipio', func: null, key: false, ordering: false },
      { label: 'Alunos Previstos', name: 'qtdAlunosPrevistos', func: null, key: false, ordering: false },
      { label: 'Alunos Participantes', name: 'qtdAlunosParticipantes', func: null, key: false, ordering: false },
      { label: 'Evasão', name: 'evasao', func: evasaoLimiterMask, key: false, ordering: false },
      { label: 'Secretaria', name: 'nmSecretaria', func: null, key: false, ordering: false },
    ];
    
    const actions = [
      { 
        name: this.labelEditar,
        func: this.handleEditClick, 
        icon: <EditOutlinedIcon />, 
        disabled: this.disabledEditar,
      },
      { 
        name: this.labelResponsavel, 
        func: this.handleEditResponsibleClick, 
        icon: <SupervisorAccountIcon />, 
        disabled: this.disabledResponsavel,
      },
      { 
        name: this.labelEnviarEmailIndividual,
        func: this.handleSendInvitation, 
        icon: <MailOutlineIcon />, 
        disabled: this.disabledEnviarEmailIndividual,
      },
      { 
        name: this.labelEmailsEnviados,
        func: this.handleViewSends, 
        icon: <AllInboxIcon />, 
        disabled: this.disabledEmailsEnviados,
      },
      { 
        name: this.labelRelatorio,
        func: this.handleViewReport,
        icon: <DescriptionIcon />,
        disabled: this.disabledRelatorio,
      },
      { 
        name: this.labelDashboard,
        func: this.handleViewDashboard, 
        icon: <LaptopMacIcon />, 
        disabled: this.disabledDashboard,
      },
    ];

    this.handleFilterPersistence();

    return (
      <>
        <Toast parentRef={this.setToast} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Breadcrumbs links={links} active={pageName} />
          </Grid>
        </Grid>
        <Title>{pageName}</Title>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <EscolaFilter 
              parentRef={this.setEscolaFilter}
              handleFilterChange={this.filterData}
            />
            <Grid container spacing={3}>
              <AuthorizedElement roles={[__ESCOLA_ADICIONAR]}>
                <ButtonAdd           
                  to='/admin/escola/adicionar'
                  title="Adicionar Escola"
                />
              </AuthorizedElement>
              <AuthorizedElement roles={[__ESCOLA_EXPORTAR]}>
                { this.state.filters.length > 3 ?
                  <ButtonExport 
                    title="Exportar Planilha"
                    onClick={this.handleExportPlan} 
                  />
                : null }
              </AuthorizedElement>
            </Grid>
            <Table
              columns={columns}
              tableData={this.state.schools}
              actions={actions}
              page={this.state.page}
              handlePage={this.handlePage}
              handleTotalPages={this.handleTotalPages}
              handleTotalElements={this.handleTotalElements}
              rowsPerPage={this.state.size}
              handleRowsPerPage={this.handleRowsPerPage}
              order={this.state.order}
              orderBy={this.state.orderBy}
              handleSort={this.handleSort}
              loading={this.state.loading}
              handleChecks={ this.verifySendInvitation() && AuthorizedFunction([__ESCOLA_EMAIL_ENVIAR_MASSA]) ? this.handleSendToSchools : null }
            />

            {this.verifySendInvitation() && AuthorizedFunction([__ESCOLA_EMAIL_ENVIAR_MASSA]) &&
              <Grid container spacing={3}>
                <ButtonSendInvitation 
                  state={this.state}
                  schools={this.state.sendToSchools}
                  disabled={this.state.sendToSchools.length === 0}
                />
              </Grid>
            }
          </Grid>
        </Grid>
      </>
    )
  }
}

const mapStateToProps = state => ( { contexts: state.contexts } );
const mapDispatchToProps = dispatch => ( bindActionCreators({ loadContext }, dispatch) );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Escola));