// REMOVER PARÂMETROS DE PAGINAÇÃO E ORDENAÇÃO DOS FILTROS
export const removePagingAndSorting = filters => filters.filter(f => f.field !== 'page' && f.field !== 'size' && f.field !== 'sort');

// VERIFICANDO SE EXISTE PERSISTENCIA EM FILTROS, PAGINAÇÃO E ORDENAÇÃO
export const verifyPersistence = (state, location) => {
    if (location && location.state && location.state.history) {
        let historyState = location.state.history.state;
        historyState.filters = removePagingAndSorting(historyState.filters);
        state = historyState;      
    };

    return state;
}