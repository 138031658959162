import React from 'react';
import { Link } from 'react-router-dom';

export const LinkTargetBlank = (props) => {
    const {link, className, children} = props;
    return (
        <a className={`no-decoration-link bold ${className}`}
            rel="noopener noreferrer" 
            target="_blank" 
            href={link}
        >
            {children}
        </a>
    )
}

export const LinkRegular = (props) => {
    const {to, className, children} = props;
    return (
        <Link 
            className={`no-decoration-link bold ${className}`}
            style={{ cursor:'pointer' }}
            to={to}
        >
            {children}
        </Link>
    )
}

export default LinkTargetBlank;