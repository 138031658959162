import React from 'react';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    title: {
        margin: theme.spacing(4, 0, 2),
    },
}));

export const Title = ({ children }) => {
  const classes = useStyles();
  return ( <Typography variant="h4" gutterBottom className={classes.title} >{children}</Typography> );
}

export default Title;