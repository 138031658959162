import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import SecretariaService from '../../services/Secretaria';
import { errors } from '../../services/API';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Text from '../../components/Inputs/Text/Text';
import Toast from "../../components/Toast/Toast";
import Loading from '../../components/Loading/Loading';

import Endereco from '../../forms/Endereco/Endereco';
import DependenciaAdministrativa from '../../forms/DependenciaAdministrativa/DependenciaAdministrativa';

import Telefone from '../../forms/Telefone/Telefone';
import { patternPhones } from '../../variables/Enums/Telefone';

import ButtonsForm from '../../forms/Buttons/ButtonsForm';

import { 
    isBlank, 
    isBlankHelperText, 
    isMailInvalid, 
    isMailHelperText,
    isNameSecretaryCompoundInvalid,
    isNameSecretaryCompoundHelperText
} from '../../helper/ValidationHelper';

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            secretary: {
                id: !isNaN(this.props.match.params.id) ? this.props.match.params.id : '',
                name: '',
                email: '',
            },
            errors: {
                name: false,
                email: false,
            },
            helpers: {
                name: null,
                email: null,
            },
            loadingButtonSave: false,
            loading: this.props.match.params.id ? true : false,
            history: { 
                path: null, 
                state: null
            }
        };
    }

    // INPUTS
    handleChange = e => {
        this.setState({
            secretary: {...this.state.secretary, [e.target.name]: e.target.value },
            errors: {...this.state.errors, [e.target.name]: false },
            helpers: {...this.state.helpers, [e.target.name]: null },
        });
    };

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
    setToast = t => this.Toast = t;
    setTelefone = f => this.Telefone = f;
    setEndereco = f => this.Endereco = f;
    setDependenciaAdministrativa = f => this.DependenciaAdministrativa = f;

    // VALIDAÇÕES
    isValid = () => {
        if ( isBlank(this.state.secretary.name) || isNameSecretaryCompoundInvalid(this.state.secretary.name) || isBlank(this.state.secretary.email) || isMailInvalid(this.state.secretary.email) ) {
            this.setState({ 
                errors: {
                    name: isBlank(this.state.secretary.name)  || isNameSecretaryCompoundInvalid(this.state.secretary.name) ? true : false,
                    email: isBlank(this.state.secretary.email) || isMailInvalid(this.state.secretary.email) ? true : false,
                },
                helpers: {
                    name: isBlank(this.state.secretary.name) ? isBlankHelperText() : isNameSecretaryCompoundInvalid(this.state.secretary.name) ? isNameSecretaryCompoundHelperText() : null,
                    email: isBlank(this.state.secretary.email) ? isBlankHelperText() : isMailInvalid(this.state.secretary.email) ? isMailHelperText() : null,
                },
            });
            return false;
        }
        return true;
    }

    // SALVAR
    handleSubmit = () => {
        this.setState({ loadingButtonSave: true });
        
        const formIsValid = this.isValid()
        const formEnderecoIsValid = this.Endereco.isValid();
        const formAdmDepIsValid = this.DependenciaAdministrativa.isValid();
        const formTelefoneIsValid = this.Telefone.isValidInFormToSave();

        if (!formIsValid || !formEnderecoIsValid || !formAdmDepIsValid || !formTelefoneIsValid) {
            this.setState({ loadingButtonSave: false });
            return
        }
        
        const secretary = {
            nmSecretariaEducacao: this.state.secretary.name,
            nmEmail: this.state.secretary.email,

            nmCep: this.Endereco.state.address.zipCode,
            nmEndereco: this.Endereco.state.address.address,
            nmNumero: this.Endereco.state.address.number,
            nmComplemento: this.Endereco.state.address.complement,
            nmBairro: this.Endereco.state.address.district,
            cdMunicipioRegiao: this.Endereco.state.address.city,

            nmDependenciaAdministrativa: this.DependenciaAdministrativa.state.adminDep,

            telefones: [...this.Telefone.state.phones],
        }

        let msgSuccess = '';
        let request = '';

        if (this.state.secretary.id) {
            request = SecretariaService.edit(this.state.secretary.id, secretary);
            msgSuccess = "Secretaria Atualizada com Sucesso.";
        } else {
            msgSuccess = "Secretaria Criada com Sucesso.";
            request = SecretariaService.add(secretary)
        }

        request
            .then(res => {
                this.Toast.setState({
                    message: {
                        message: msgSuccess,
                        type: "success",
                        open: true
                    }
                });

                setTimeout(() => this.handleBack(), 1000);
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
                this.setState({ loadingButtonSave: false });
            })
    }

    filterPersistence = (parseLocation) => {
        if (parseLocation.state && parseLocation.state.history) {
            this.setState({ 
                history: { 
                    path: parseLocation.state.history.path, 
                    state: parseLocation.state.history.state 
                } 
            });
        }
    }

    componentDidMount() {
        this.filterPersistence(this.props.location);

        if (this.state.secretary.id) {
            SecretariaService.find(this.state.secretary.id)
                .then(res => {
                    this.setState({
                        secretary: {
                            id: res.data.cdSecretariaEducacao,
                            name: res.data.nmSecretariaEducacao,
                            email: res.data.nmEmail,
                        },
                        loading: false
                    });

                    this.Endereco.findCities(res.data.uf.cdUf, res.data.municipio.cdMunicipioRegiao);
                    this.Endereco.setState({
                        address: {
                            zipCode: res.data.nmCep,
                            address: res.data.nmEndereco,
                            number: res.data.nmNumero,
                            complement: res.data.nmComplemento,
                            district: res.data.nmBairro,
                            city: res.data.municipio.cdMunicipioRegiao,
                            UF: res.data.uf.cdUf,
                        }
                    })                    

                    this.DependenciaAdministrativa.setState({
                        adminDep: res.data.nmDependenciaAdministrativa,
                    })

                    this.Telefone.setState({ 
                        phones: [...patternPhones(res.data.telefones)],
                    })
                })
                .catch(err => {
                    this.Toast.setState({
                        message: {
                            message: "Não Foi Possível Buscar a Secretaria para Edição.",
                            type: 'error',
                            open: true
                        }
                    })

                    this.setState({ loading: false });
                })
        }
    }

    handleBack = () => {
        this.props.history.push({
            pathname: `${this.state.history.path}`,
            state: { 
                history: {
                    state: this.state.history.state
                }
            }
        });    
    }

    render () {
        const page = this.state.secretary.id ? 'Editar Secretaria' : 'Adicionar Secretaria';
        const links = [
            {
                path: '/admin/secretaria',
                name: 'Secretarias'
            }
        ];

        return (
            <>
                <Toast parentRef={this.setToast} />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Breadcrumbs links={links} active={page} />
                    </Grid>
                </Grid>

                <Title>{page}</Title>

                { this.state.loading ? 
                    <Loading />
                :
                    <>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item container spacing={3}>
                                <Grid item sm={12} lg={5}>
                                    <Text
                                        required
                                        name="name"
                                        label="Nome da Secretaria"
                                        value={this.state.secretary.name}
                                        error={this.state.errors.name}
                                        onChange={this.handleChange}
                                        helperText={this.state.helpers.name}
                                    />
                                </Grid>
                                <Grid item sm={12} lg={4}>
                                    <Text
                                        required
                                        name="email"
                                        label="Email da Secretaria"
                                        value={this.state.secretary.email}
                                        error={this.state.errors.email}
                                        onChange={this.handleChange}
                                        helperText={this.state.helpers.email}
                                    />
                                </Grid>
                            </Grid>
                            <Endereco parentRef={this.setEndereco} />
                            <DependenciaAdministrativa parentRef={this.setDependenciaAdministrativa} withFederal={false} />
                        </Grid>
                        <Telefone parentRef={this.setTelefone} />
                        <ButtonsForm 
                            onClick={this.handleSubmit} 
                            onBack={this.state.history.path ? () => this.handleBack() : null} 
                            idFocus={`saveButton`} 
                            loading={this.state.loadingButtonSave} 
                        />
                    </>
                }
            </>
        )
    }
}

export default withRouter(Form);