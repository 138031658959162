import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import SelectOption from '../../components/Inputs/Select/Select';
import Toast from '../../components/Toast/Toast';

import UFService from '../../services/UF';

import { patternUF } from '../../variables/Enums/UF';

export class UfFilter extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            filter: false,
            filters: {
                UF: '',
            },
            optionsUF: [],
        }
    }

    // INPUTS
    handleChange = e => {
        this.setState({
            filter: true,
            filters: {...this.state.filters, [e.target.name]: e.target.value },
        })
    }

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
    setToast = t => this.Toast = t;

    componentDidMount () {
        UFService.list()
            .then(res => {
                this.setState({
                    optionsUF: [...patternUF(res.data)]
                })
            })
            .catch(err => {
                this.Toast.setState({
                    message: {
                        message: "Não Foi Possível Buscar as UF's.",
                        type: 'error',
                        open: true
                    }
                })
            })
    }

    render () {
        return (
            <>
                <Toast parentRef={this.setToast} />
                <Grid item sm={12} lg={4}>
                    <SelectOption
                        label="UF"
                        name='UF'
                        value={this.state.filters.UF}
                        onChange={(e) => this.handleChange(e)}
                        options={this.state.optionsUF}
                    />
                </Grid>
            </>
        )
    }
}

export default UfFilter;