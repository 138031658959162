
export const isNumber = str => /[^0-9]+$/.test(str);

export const isPasswordsDiffHelperText = () => "As Senhas não podem ser Diferentes";

export const isSizeInvalid = (size) => size > 10485760 ? true : false;
export const isSizeInvalidHelperText = () => "Tamanho do Arquivo excede o limite máximo de 10mb";

export const isBlank = str => str === '' || str === null;
export const isNotBlank = str => !isBlank(str);
export const isBlankHelperText = () => "Campo Obrigatório";

export const isDDDValid = str => /^\(?[1-9]{2}\)?/.test(str);
export const isDDDInvalid = str => !isDDDValid(str);
export const isDDDHelperText = () => "DDD Inválido";

export const isPhoneValid = str => /^(\d{4,5})-?(\d{4})/.test(str);
export const isPhoneInvalid = str => !isPhoneValid(str);
export const isPhoneHelperText = () => "Telefone Inválido";

export const isNameCompoundValid = str => str.match(/^[A-zÀ-ÿ0-9-']+\s([A-zÀ-ÿ0-9-']\s?)*[A-zÀ-ÿ0-9-']+$/);
export const isNameCompoundInvalid = str => !isNameCompoundValid(str);
export const isNameCompoundHelperText = () => "O Nome Completo deve conter Sobrenome";

export const isNameSecretaryCompoundValid = str => str.match(/^[A-zÀ-ÿ0-9-']+\s([A-zÀ-ÿ0-9-']\s?)*[A-zÀ-ÿ0-9-']+$/);
export const isNameSecretaryCompoundInvalid = str => !isNameSecretaryCompoundValid(str);
export const isNameSecretaryCompoundHelperText = () => "O Nome da Secretaria deve ser Composto";

export const isNameSchoolCompoundValid = str => str.match(/^[A-zÀ-ÿ0-9-']+\s([A-zÀ-ÿ0-9-']\s?)*[A-zÀ-ÿ0-9-']+$/);
export const isNameSchoolCompoundInvalid = str => !isNameSchoolCompoundValid(str);
export const isNameSchoolCompoundHelperText = () => "O Nome da Escola deve ser Composto";

export const isCPFValid = str => {
    str = str.replace(/[^\d]+/g,'');
    
    if(str === '')
        return false;
    
    if (str.length !== 11 || 
        str === "00000000000" || 
        str === "11111111111" || 
        str === "22222222222" || 
        str === "33333333333" || 
        str === "44444444444" || 
        str === "55555555555" || 
        str === "66666666666" || 
        str === "77777777777" || 
        str === "88888888888" || 
        str === "99999999999")
            return false;

    let add1 = 0;

    for (let i=0; i < 9; i++)
        add1 += parseInt(str.charAt(i)) * (10 - i);

    let rev1 = 11 - (add1 % 11);

    if (rev1 === 10 || rev1 === 11)
        rev1 = 0;

    if (rev1 !== parseInt(str.charAt(9)))
        return false;

    let add2 = 0;

    for (let i = 0; i < 10; i ++)
        add2 += parseInt(str.charAt(i)) * (11 - i);

    let rev2 = 11 - (add2 % 11);

    if (rev2 === 10 || rev2 === 11)
        rev2 = 0;

    if (rev2 !== parseInt(str.charAt(10)))
        return false;

    return true;
}
export const isCPFInvalid = str => !isCPFValid(str);
export const isCPFHelperText = () => "CPF Inválido";

export const isMailValid = str => {
    const usuario = str.substring(0, str.indexOf("@"));
    const dominio = str.substring(str.indexOf("@") + 1, str.length);

    if (usuario.length >= 1 && 
        usuario.search("@") === -1 &&
        usuario.search(" ") === -1 &&
        dominio.length >= 3 &&
        dominio.search("@") === -1 &&
        dominio.search(" ") === -1 &&
        dominio.search(".") !== -1 &&
        dominio.indexOf(".") >= 1 && 
        dominio.lastIndexOf(".") < dominio.length - 1) {
            return true;
    }

    return false;
}
export const isMailInvalid = str => !isMailValid(str);
export const isMailHelperText = () => "Email Inválido";
export const isEmailsDiffHelperText = () => "Os Emails não podem ser Diferentes";

export const isMecCodeValid = str => /^\(?[0-9]{8}\)?/.test(str);
export const isMecCodeInvalid = str => !isMecCodeValid(str);
export const isMecCodeHelperText = () => "Códico MEC Inválido";

export const isZipCodeValid = str => /^(\d{5})-?(\d{3})/.test(str);
export const isZipCodeInvalid = str => !isZipCodeValid(str);
export const isZipCodeHelperText = () => "CEP Inválido";

export const isDateTimeValid = str => /^(([0-2]?[0-9]|3[0-1])\/([0]?[1-9]|1[0-2])\/[1-2]\d{3}) (20|21|22|23|[0-1]?\d{1}):([0-5]?\d{1}):([0-5]?\d{1})$/.test(new Date(str).toLocaleString());
export const isDateTimeInvalid = str => !isDateTimeValid(str);
export const isDateTimeHelperText = () => "Formato de Data e Hora Inválido";