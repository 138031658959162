import { encrypt } from '../../helper/EncryptHelper';

export const patternSendToSchools = schools => {
    let array = [];
    schools.forEach((school) => {
        array.push({
            key: school.cdMecEscola,
            mail: school.nmEmail,
            hash: encrypt(school.cdMecEscola),
        });
    })
    return array;
}

export const patternSendToSecretary = secretaries => {
    let array = [];
    secretaries.forEach((secretary) => {
        array.push({
            key: secretary.cdSecretariaEducacao,
            mail: secretary.nmEmail,
            hash: encrypt(secretary.cdSecretariaEducacao),
        });
    })
    return array;
}