import React, { Fragment, useState } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import { useKeycloak } from '@react-keycloak/web';

import AuthorizedFunction from '../security/AuthorizedFunction';
import { configureInterceptors } from '../services/API';

import LayoutPublic from '../layouts/public/Public';
import LayoutPrivate from '../layouts/private/Private';

import authList, { path as authPath, mainPage as authMainPage } from '../variables/Links/Auth';
import menuList, { path as menuPath, mainPage as menuMainPage } from '../variables/Links/Menu';
import secretaryList from '../variables/Links/Secretaria';
import schoolList from '../variables/Links/Escola';
import fileList from '../variables/Links/Arquivo';
import contextList from '../variables/Links/Contexto';
import reportList from '../variables/Links/Relatorio';
import receiptList from '../variables/Links/Recibo';
import requestList from '../variables/Links/Pedido';
import inscricaoList from '../variables/Links/Inscricao';
import provaList from '../variables/Links/Prova';
import responsavelList from '../variables/Links/Responsavel';

import { 
    __SECRETARIA_DASHBOARD, 
    __ESCOLA_DASHBOARD 
} from '../security/RoleConfiguration';

export const Routes = () => {
    const [keycloak, initialized] = useKeycloak();
    const [darkMode, setDarkMode] = useState(false);

    const handleLogout = () => keycloak.logout();

    const Interceptor = () => {
        configureInterceptors()              
        return <Private />
    }

    const theme = createMuiTheme({
        palette: {
            type: darkMode ? 'dark' : 'light'
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Switch>
                    { initialized && keycloak.authenticated ? 
                        <LayoutPrivate 
                            handleLogout={handleLogout}
                            darkMode={darkMode}
                            setDarkMode={setDarkMode}
                        >
                            <Interceptor />
                        </LayoutPrivate>
                    :
                        <LayoutPublic>
                            <Public />
                        </LayoutPublic>
                    }
                </Switch>
            </BrowserRouter>
        </ThemeProvider>
    )
}

const renderPublicComponent = (component, roles) => !AuthorizedFunction(roles) ? ( component ) : ( <Redirect to={{ pathname: menuPath + menuMainPage }} /> )

const renderPrivateComponent = (component, roles) => AuthorizedFunction(roles) ? ( component ) : ( <Redirect to={{ pathname: authPath + authMainPage }} /> )

export const MountPrivateRoutes = (props) => {
    return (
        <>
            { props.routes.map((route, key) => 
                <Route 
                    key={key} 
                    path={route.path} 
                    exact={route.exact ?? true} 
                    render={() => renderPrivateComponent(<route.component />, route.roles)} 
                />
            )}
        </>
    )
}

export const MountPublicRoutes = (props) => {
    return (
        <>
            { props.routes.map((route, key) => 
                <Route 
                    key={key} 
                    path={route.path} 
                    exact={route.exact ?? true} 
                    render={() => renderPublicComponent(<route.component />, route.roles )} 
                />
            )}
        </>
    )
}

export const Public = (props) => {
    return (
        <>
            <MountPublicRoutes routes={authList} />
            <MountPublicRoutes routes={requestList} />
            <MountPublicRoutes routes={responsavelList} />
        </>
    );
}

export const Private = (props) => {
    return (
        <>
            { menuList.map((sub, key) => 
                <Fragment key={key}>
                    <MountPrivateRoutes key={key} routes={sub.items} /> 
                    {sub.items.map((sub, key) => 
                        <MountPrivateRoutes key={key} routes={sub.submenus} />
                    )}
                </Fragment>
            )}
            <MountPrivateRoutes routes={secretaryList} />
            <MountPrivateRoutes routes={schoolList} />
            <MountPrivateRoutes routes={fileList} />
            <MountPrivateRoutes routes={contextList} />
            <MountPrivateRoutes routes={reportList} />
            <MountPrivateRoutes routes={receiptList} />
            <MountPrivateRoutes routes={inscricaoList} />
            <MountPrivateRoutes routes={provaList} />
            
            {!AuthorizedFunction([__SECRETARIA_DASHBOARD, __ESCOLA_DASHBOARD]) &&
                <Redirect path="/" exact={true} to={{ pathname: menuPath + menuMainPage }} />
            }
        </>
    );
}

export default Routes;