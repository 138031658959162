import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import FileDownload from 'js-file-download';

import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Typography } from '@material-ui/core';

import RelatorioService from '../../services/Relatorio';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Toast from '../../components/Toast/Toast';
import Filters from '../../components/Filters/Filters';
import { ButtonSecondary } from '../../components/Button/Button';
import Loading from '../../components/Loading/Loading';

import DadosSecretarias from '../../forms/Relatorio/DadosSecretarias';
import DadosEscolas from '../../forms/Relatorio/DadosEscolas';
import UfFilter from '../../forms/Endereco/UfFilter';
import { ButtonExport } from '../../forms/Buttons/ButtonsExport';

import AuthorizedElement from '../../security/AuthorizedElement';
import { __RELATORIO_SECRETARIAS_ESTADUAIS_EXPORTAR } from '../../security/RoleConfiguration';

import { getFilenameResponse } from '../../helper/FileHelper';

export class SecretariasEstaduais extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      filters: {
        UF: false,
      }
    }
  }  

  // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
  setToast = t => this.Toast = t;
  setDadosSecretarias = d => this.DadosSecretarias = d;
  setDadosEscolas = d => this.DadosEscolas = d;
  setUfFilter = f => this.UfFilter = f;

  // DESATIVAR FILTROS
  DisableFilters = () => {
    this.UfFilter.setState({ filter: false, });
    this.setState({ filters: { UF: false, } });
  }

  // FILTROS
  ApplyFilters = () => { 
    this.setState({ loading: true });

    const f = [];
    const { filters } = this.UfFilter.state;

    if (filters.UF) f.push({ field: 'nmUf', value: filters.UF })

    RelatorioService.filtersReportStateSecretaries(f)
      .then(res => {
        this.setState({ 
          loading: false, 
          filters: { UF: true, }
        })

        this.DadosSecretarias.setState({
          secretaries: [...res.data.secretarias]
        })

        this.DadosEscolas.setState({
          schools: [...res.data.escolas]
        })
      })
      .catch(error => {
          this.setState({ loading: false, filters: { UF: false, } })
          this.DisableFilters()
      })
  }

  // EXPORTAÇÃO
  handleExportChange = () => {
    const f = [];
    const { filters } = this.UfFilter.state;

    if (filters.UF) f.push({ field: 'nmUf', value: filters.UF })

    f.push({ field: 'exportarPDF', value: true });

    this.Toast.setState({
      message: {
        message: "O PDF está sendo gerado para Exportação.",
        type: 'success',
        open: true
      }
    })

    RelatorioService.exportReportStateSecretaries(f)
      .then(res => {
        FileDownload(res.data, getFilenameResponse(res));
      })
  }

  // LIMPAR FILTROS
  handleFilterClear = () => {
    this.UfFilter.setState({
      filter: true,
      filters: {
        UF: '',
      }
    })
  }

  render () {
    const pageName = 'Secretarias Estaduais';
    const links = [
        { 
            path: null, 
            name: 'Relatórios' 
        }
    ];

    return (
      <>
        <Toast parentRef={this.setToast} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Breadcrumbs links={links} active={pageName} />
          </Grid>
        </Grid>
        <Title>{pageName}</Title>

        <Grid container spacing={3} style={{ marginBottom: 8 }}>
          <Grid item sm={12} lg={12}>
            <Typography variant="body2"><b>Utilize o Filtro abaixo para iniciar a busca.</b></Typography>
          </Grid>
        </Grid>

        <Filters
          handleFilter={this.ApplyFilters}
          handleFilterClear={this.handleFilterClear}
        >
          <Grid item container spacing={3}>
            <UfFilter 
              parentRef={this.setUfFilter}
            />
          </Grid>
        </Filters>
        
        <AuthorizedElement roles={[__RELATORIO_SECRETARIAS_ESTADUAIS_EXPORTAR]}>
          { this.state.filters.UF &&
            <Grid item container spacing={3}>
              <ButtonExport 
                title="Exportar PDF"
                onClick={this.handleExportChange}
              />
            </Grid>
          }
        </AuthorizedElement>

        { this.state.loading ? 
          <Loading />
        :
          <>
            <DadosSecretarias parentRef={this.setDadosSecretarias} marginTop={ this.state.filters.UF ? 8 : 32} />
            <DadosEscolas parentRef={this.setDadosEscolas} marginTop={32} />
          </>
        }

        <Grid container spacing={3} alignItems='flex-end' style={{ marginTop: 16 }}>  
          <Grid item sm={12} lg={12}>
            <ButtonSecondary
              startIcon={<ArrowBackIcon />}
              style={{ margin: 4 }}
              onClick={this.props.history.goBack}
              name={"Voltar"}
            />
          </Grid>   
        </Grid>
      </>
    )
  }
}

export default withRouter(SecretariasEstaduais);