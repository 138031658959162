import List from '../../views/Contexto/List';
import Form from '../../views/Contexto/Form';
import {
    __CONTEXTO_EDITAR,
    __CONTEXTO_VISUALIZAR,
    __CONTEXTO_ADICIONAR,
} from '../../security/RoleConfiguration';

export const path = '/admin/contexto';

export const listRoutes = [
    {
        path: path + '/listagem',
        component: List,
        roles: [ __CONTEXTO_VISUALIZAR ],
    },
    {
        path: path + '/adicionar',
        component: Form,
        roles: [ __CONTEXTO_ADICIONAR ],
    },
    {
        path: path + '/editar/:id',
        component: Form,
        roles: [ __CONTEXTO_EDITAR ],
    }
];

export default listRoutes;