import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import SelectOption from '../../components/Inputs/Select/Select';

import { optionsAdminDep, optionsAdminDepWithoutFederal } from '../../variables/Enums/DependenciaAdministrativa';

import { 
    isBlank, 
    isBlankHelperText 
} from '../../helper/ValidationHelper';

export class DependenciaAdministrativa extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            adminDep: '',
            errors: {
                adminDep: false,
            },
            helpers: {
                adminDep: null,
            },
            disabled: {
                adminDep: false,
            }
        }
        this.isValid = this.isValid.bind(this);
    }

    // INPUTS
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            errors: {...this.state.errors, [e.target.name]: false },
            helpers: {...this.state.helpers, [e.target.name]: null }
        })
    }

    // VALIDAÇÕES
    isValid = () => {
        if ( isBlank(this.state.adminDep) ) {
            this.setState({ 
                errors: {
                    adminDep: isBlank(this.state.adminDep) ? true : false,
                },
                helpers: {
                    adminDep: isBlank(this.state.adminDep) ? isBlankHelperText() : null,
                },
            });
            return false;
        }
        return true;
    }

    render () {
        return (
            <Grid item container spacing={3}>
                <Grid item sm={12} lg={2}>
                    <SelectOption
                        required
                        label="Dep. Administrativa"
                        name='adminDep'
                        value={this.state.adminDep}
                        error={this.state.errors.adminDep}
                        onChange={(e) => this.handleChange(e)}
                        options={this.props.withFederal ? optionsAdminDep : optionsAdminDepWithoutFederal}
                        hiddenblank="true"
                        helperText={this.state.helpers.adminDep}
                        disabled={this.state.disabled.adminDep}
                    />
                </Grid>
            </Grid>
        )
    }
}

export default DependenciaAdministrativa;