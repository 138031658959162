import React, { Component } from 'react';

class LayoutPublic extends Component {
  render() {
    return (
      <>
        {this.props.children} 
      </>
    );
  }
}

export default LayoutPublic;