import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(2, 0),
        width: '100%',
    },
}));

export const Boolean = (props) => {
    const classes = useStyles();

    return (
        <FormControl className={classes.formControl}>
            <TextField
                select
                {... props}
            >
                {!props.hiddenblank && <MenuItem value=''>Todos</MenuItem>}
                <MenuItem value='true'>Sim</MenuItem>
                <MenuItem value='false'>Não</MenuItem>
            </TextField>
        </FormControl>
    );
}

export default Boolean;