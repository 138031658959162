import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
    (theme) => (
        {
            wrapper: {
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(4),
            },
            title: {
                flex: '1 1 100%',
            },
            button: {
                margin: theme.spacing(1),
            },
            paper: {
                padding: theme.spacing(3),
            }
        }
    )
);

export default useStyles;
