import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles( 
    (theme) => (
      {
        visuallyHidden: {
          border: 0,
          clip: 'rect(0 0 0 0)',
          height: 1,
          margin: -1,
          overflow: 'hidden',
          padding: 0,
          position: 'absolute',
          top: 20,
          width: 1,
        },
        bolder: {
          fontWeight: 'bold',
        },
        spacingTable: {
          marginTop: theme.spacing(4)
        }
      }
    ) 
  );

export default useStyles;