export const patternSchools = schools => {
    let array = [];
    schools.forEach((school) => {
        array.push({
            cdMecEscola: school.cdMecEscola,
            nmMecEscola: school.nmMecEscola,
            nmEmail: school.nmEmail,
            nmUf: school.nmUf,
            nmMunicipio: school.nmMunicipio,
            qtdAlunosPrevistos: school.inscricao ? school.inscricao.qtdAlunosPrevistos ? school.inscricao.qtdAlunosPrevistos : "Não Informado" : "Não Informado",
            qtdAlunosParticipantes: school.inscricao ? school.inscricao.qtdAlunosParticipantes ? school.inscricao.qtdAlunosParticipantes : "Não Informado" : "Não Informado",
            evasao: school.inscricao ? school.inscricao.evasao ? school.inscricao.evasao : "0.0" : "0.0",
            nmSecretaria: school.nmSecretaria ?? "Sem Secretaria Vínculada",
            idResponsavel: school.idResponsavel,
            isInscrita: school.inscricao !== null ? true : false, 
        });
    })
    return array;
}

export const optionsStatus = [
    { value: 'VALIDA', label: 'Válida' },
    { value: 'EM_ANALISE', label: 'Em Análise' },
    { value: 'INVALIDA', label: 'Inválida' }
];

export const optionsOrigem = [
    { value: false, label: 'Importada da Base MEC' },
    { value: true, label: 'Cadastrada por Responsável' }
];